import React, { useState, useEffect } from 'react'
import { useShowContext, Button } from 'react-admin'

import { StatusField } from '@/admin/resources/order/order-status'
import { StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { Loader } from '@/admin/components/Loader'
import { showEditor } from './warehouse-document-editor'
import { Link } from 'react-router-dom'
import { BASE_URL } from '@/admin/components/ui/Breadcrumbs/data'
import { HttpV2 } from '@shared/apiv2/api'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

// eslint-disable-next-line complexity
function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
                const uint8Array = new Uint8Array(reader.result)
                resolve(uint8Array)
            } else {
                reject(new Error('Unable to convert Blob to Uint8Array.'))
            }
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(blob)
    })
}

const ViewFile = ({ id, fileType }: { id: number; fileType: string }) => {
    const [fileData, setFileData] = useState<Blob | null>(null)
    const [fileData2, setFileData2] = useState<Uint8Array | string>('')

    useEffect(() => {
        const getFile = async () => {
            try {
                const response = await HttpV2.get(`admin/warehouse-documents/${id}/file`, {
                    responseType: 'blob',
                })

                if (fileType === 'pdf') {
                    const data = await blobToUint8Array(response.data)
                    setFileData2(data)
                } else {
                    setFileData(response.data)
                }
            } catch (error) {
                console.error('Error fetching file:', error)
            }
        }

        getFile()
    }, [id])
    if (fileType === 'pdf') {
        return fileData2 ? (
            <>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '750px',
                        }}
                    >
                        <Viewer defaultScale={1} fileUrl={fileData2} />
                    </div>
                </Worker>
            </>
        ) : null
    } else {
        return fileData ? <img style={{ width: '100%' }} src={URL.createObjectURL(fileData)} alt="Document" /> : null
    }
}

export const WarehouseDocumentView = function () {
    const { record, isLoading, isFetching } = useShowContext()

    const cardTitle = (title: string, item: any) => {
        return (
            <>
                {title} <Button type="button" onClick={() => showEditor(item.id)} color="primary" label="Edit" />
            </>
        )
    }

    return (
        <div style={{ padding: '16px' }}>
            <Grid container spacing={2} alignContent="flex-start">
                <Grid item xs={12} md={8} lg={6}>
                    {!isLoading && !isFetching ? (
                        <DataCard
                            records={[
                                { title: 'Document', value: record?.svcDocType?.warehouseSvcType?.title },
                                { title: 'Is Main?', value: record.isMain ? 'Yes' : 'No' },
                                {
                                    title: 'Warehouse ID',
                                    value: (
                                        <Link to={BASE_URL + `/warehouses/${record?.warehouse?.id}/show`}>
                                            {record?.warehouse?.id}
                                        </Link>
                                    ),
                                },
                                {
                                    title: 'Warehouse',
                                    value: (
                                        <Link to={BASE_URL + `/warehouses/${record?.warehouse?.id}/show`}>
                                            {record?.warehouse?.entity}
                                        </Link>
                                    ),
                                },
                                {
                                    title: 'Company',
                                    value: (
                                        <Link to={BASE_URL + `/companies/${record?.warehouse?.company?.id}/show`}>
                                            {record?.warehouse?.company.entity}
                                        </Link>
                                    ),
                                },
                                {
                                    title: 'Status',
                                    value: <StatusField source="status" statusType={StatusType.document} />,
                                },
                                {
                                    title: 'Expired At',
                                    value: record.expiredAt
                                        ? dayjs(record.expiredAt).format('MM/DD/YYYY HH:mm')
                                        : '(not set)',
                                },
                                { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                            ]}
                            title={cardTitle('Warehouse Document', record)}
                        />
                    ) : (
                        <Loader />
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    {record?.id ? <ViewFile fileType={record.svcDocType.fileMask} id={record?.id} /> : null}
                </Grid>
            </Grid>
        </div>
    )
}
