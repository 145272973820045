import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { SelectInput, FileInput, ReferenceInput, required, RadioButtonGroupInput, DateInput } from 'react-admin'
import { Grid } from '@mui/material'
import lodashMemoize from 'lodash/memoize'

function getBase64(blob: any) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

export const showUploadDocument = (warehouseId: number | undefined, onSuccess?: () => void, defaultValue?: any) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <ReferenceInput
                    key={'document'}
                    label="Service Document Type"
                    source="svcDocTypeId"
                    reference="warehouse-documents/documents"
                    allowEmpty
                    alwaysOn
                    validate={required()}
                >
                    <SelectInput label="Service Document Type" optionText="name" validate={required()} />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={12}>
                <RadioButtonGroupInput
                    validate={required()}
                    label="Main"
                    source="isMain"
                    choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <FileInput key="file" label="File" source="file" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <DateInput key="expiredAt" label="Expired At" source="expiredAt" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Create Warehouse Document',
            resource: 'warehouse-documents/upload',
            recordId: undefined,
            children: form,
            defaultValue,
            onSuccess,
            model: async (data) => {
                return {
                    ...data,
                    file: await getBase64(data.file.rawFile),
                    updatedAt: new Date(),
                    warehouseId,
                }
            },
        },
    })
}
