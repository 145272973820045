import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import './App.scss'
import 'react-responsive-modal/styles.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import reportWebVitals from './reportWebVitals'

import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import AdminApp from '@/admin/AdminApp'
import { ToastContainer } from 'react-toastify'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path={'/admin/*'} element={<AdminApp />} />
            </Routes>
        </BrowserRouter>
        <ToastContainer position="bottom-center" theme="dark" hideProgressBar autoClose={2000} limit={3} />
    </React.StrictMode>,
    document.getElementById('app'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
