import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { SelectInput, TextInput } from 'react-admin'
import { Grid } from '@mui/material'

export const showTake = (
    resource: string,
    supportCategories: any,
    record: any,
    adminId: number,
    onSuccess?: () => void,
) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <SelectInput
                    key="category"
                    label="Category"
                    source="categoryId"
                    choices={supportCategories.map((item: any) => ({
                        id: item.id,
                        name: item.title,
                    }))}
                    alwaysOn
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="note" multiline />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Take Message: ' + record.name,
            resource: resource,
            recordId: record.id,
            children: form,
            onSuccess,
            model: (data) => ({ ...data, adminId }),
        },
    })
}
