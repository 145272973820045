import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, NumberInput, AutocompleteArrayInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { Grid } from '@mui/material'

export const showEditor = (resource: string, recordId?: number | string) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="entity" label="Entity" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <NumberInput source="discount" label="Discount %" />
            </Grid>
            <Grid item xs={12} md={12}>
                <AutocompleteArrayInput source="permissions" choices={[{ id: 'FULL_SEARCH', name: 'Full Search' }]} />
            </Grid>
            <Grid item xs={12} md={12}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Carrier',
            resource: resource,
            recordId,
            children: form,
            model: (data) => {
                return {
                    entity: data.entity,
                    discount: data.discount,
                    permissions: data.permissions,
                    status: data.status,
                    updatedAt: new Date(),
                }
            },
        },
    })
}
