import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, DateInput, SelectInput, required } from 'react-admin'
import { Grid } from '@mui/material'
import { calendarEventTypes, calendarMarkupPlaceType } from '@/admin/model/dictionaries'

export const showEditor = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <DateInput source="dateFrom" label="Start date" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <DateInput source="dateTo" label="End date" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="description" label="Description" />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    label="Markup type"
                    source="type"
                    choices={Object.keys(calendarEventTypes).map((r: string) => ({
                        id: r,
                        name: calendarEventTypes[r],
                    }))}
                    validate={required()}
                    alwaysOn
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    label="Place Type"
                    source="placeType"
                    choices={Object.keys(calendarMarkupPlaceType).map((r: string) => ({
                        id: r,
                        name: calendarMarkupPlaceType[r],
                    }))}
                    validate={required()}
                    alwaysOn
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="place" />
                <div>
                    {
                        'Should be specified if place_type is "COUNTRY" should be a country or should be zip if place_type is"ZIP" Example for COUNTRY: USA Example for ZIP: 60007'
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="range" />
                <div>{'Should be specified if place_type is equal "ZIP" in miles'}</div>
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="value" validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Edit Calendar price markup' : 'Create Calendar price markup',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
        },
    })
}
