import React, { FC, useContext, useReducer } from 'react'
import { cache } from 'swr'
import { AdminUser, UserAction, UserActionType, UserState, UserStore } from './user'

export const userDefaultState: UserState = {
    isLoggedIn: !!localStorage.getItem('admin_token'),
}

const UserContext = React.createContext<UserStore>({ state: userDefaultState })

export const useUserState = (): UserStore => useContext<UserStore>(UserContext)

export const userReducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case UserActionType.SET_LOGGED_STATE:
            return { ...state, isLoggedIn: true, user: action.user }
        case UserActionType.LOGOUT:
            localStorage.removeItem('admin_token')
            cache.clear()
            return { ...state, isLoggedIn: false, user: undefined }

        default:
            throw new Error('Unknown user action')
    }
}

export const updateLoggedState = (user: AdminUser, dispatch?: React.Dispatch<UserAction>): void => {
    if (dispatch) {
        dispatch({
            type: UserActionType.SET_LOGGED_STATE,
            user,
        })
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userLogout = (dispatch: React.Dispatch<UserAction>) => {
    dispatch({ type: UserActionType.LOGOUT })
}

export const UserProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, userDefaultState)
    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
}
