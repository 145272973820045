import React, { FC } from 'react'
import {
    BooleanInput,
    Button,
    CreateBase,
    FormDataConsumer,
    maxValue,
    minValue,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreateContext,
    useNotify,
    useRefresh,
} from 'react-admin'
import { Card, InputAdornment, Typography } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'
import { useNavigate } from 'react-router-dom'

export const scenarios = [
    { id: 0, name: 'Basic', short: 'BASIC' },
    { id: 1, name: 'Direct invoicing', short: 'DI' },
    { id: 2, name: 'Long Term WH', short: 'LTW' },
    { id: 3, name: 'Public booking', short: 'PUBLIC' },
]

export const CreateSheetForm: FC = () => {
    const navigate = useNavigate()
    const create = useCreateContext()
    const notify = useNotify()
    return (
        <Card sx={{ width: 300 }}>
            <Typography variant="h6" component="h6" sx={{ pt: 1, ml: 2 }}>
                New price sheet
            </Typography>
            <SimpleForm
                onSubmit={async (v, event) => {
                    try {
                        await HttpV2.post('/admin/price-sheets', { ...v, scenario: v.scenario.type })
                        notify('Price sheet was created')
                        navigate('/admin/app/price-sheets')
                    } catch (e: any) {
                        const message = Array.isArray(e.message) ? e.message[0] : e.message
                        notify(e.message, { type: 'error' })
                    }
                }}
                toolbar={
                    <Toolbar>
                        {/*<Confirm*/}
                        {/*    isOpen={confirmOpen}*/}
                        {/*    loading={confirmLoading}*/}
                        {/*    title="Pay for invoice"*/}
                        {/*    content={confirmationPhrase}*/}
                        {/*    onConfirm={async () => {*/}
                        {/*        setConfirmLoading(true)*/}
                        {/*        if (values) {*/}
                        {/*            try {*/}
                        {/*                await HttpV2.post(`/admin/invoices/${id}/payments`, values)*/}
                        {/*                await refresh()*/}
                        {/*                notify('Payment has been done')*/}
                        {/*            } catch (e: any) {*/}
                        {/*                notify(e.message, { type: 'error' })*/}
                        {/*            }*/}
                        {/*        }*/}
                        {/*        setConfirmLoading(false)*/}
                        {/*        setConfirmOpen(false)*/}
                        {/*    }}*/}
                        {/*    onClose={() => {*/}
                        {/*        setConfirmOpen(false)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Button type="submit" variant="outlined" label="Create sheet" />
                    </Toolbar>
                }
            >
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            <>
                                <SelectInput source="scenarioId" validate={required()} choices={scenarios} />
                                <BooleanInput
                                    source="useCarrierStripeFee"
                                    label="Use carrier stripe fee"
                                    defaultValue
                                    {...rest}
                                />
                                {formData.useCarrierStripeFee && (
                                    <TextInput
                                        defaultValue={0}
                                        validate={[minValue(0), maxValue(100)]}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        source="carrierStripeFeePercentage"
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                                {formData.useCarrierStripeFee && (
                                    <TextInput
                                        defaultValue={0}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        validate={minValue(0)}
                                        source="carrierStripeFeeConstant"
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                                <BooleanInput source="useServiceFee" label="Use service fee" defaultValue {...rest} />

                                {formData.useServiceFee && (
                                    <TextInput
                                        defaultValue={0}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        validate={[minValue(0), maxValue(100)]}
                                        source="serviceFeePercentage"
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                                <BooleanInput
                                    source="useWHStripeFee"
                                    label="Use warehouse stripe fee"
                                    defaultValue
                                    {...rest}
                                />
                                {formData.useWHStripeFee && (
                                    <TextInput
                                        defaultValue={0}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        source="whStripeFeePercentage"
                                        validate={[minValue(0), maxValue(100)]}
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                                {formData.useWHStripeFee && (
                                    <TextInput
                                        defaultValue={0}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        validate={minValue(0)}
                                        source="whStripeFeeConstant"
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                                <BooleanInput source="useMarkup" label="Use markup fee" defaultValue {...rest} />
                                <BooleanInput source="usePublicFee" label="Use public fee" {...rest} />
                                {formData.usePublicFee && (
                                    <TextInput
                                        defaultValue={0}
                                        type="number"
                                        parse={(val) => Number(val)}
                                        source="publicFeePercentage"
                                        validate={[minValue(0), maxValue(100)]}
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        {...rest}
                                    />
                                )}
                            </>
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Card>
    )
}
export const CreateSheet: FC = () => {
    const refresh = useRefresh()
    const notify = useNotify()

    return (
        <CreateBase resource={'price-sheets'}>
            <CreateSheetForm />
        </CreateBase>
    )
}
