export class OldAdminUINavigator {
    protected baseURL: string

    constructor() {
        const { protocol, hostname } = window.location
        const appToAdmin = hostname.replace('app', 'admin')
        this.baseURL = `https://${appToAdmin}`
    }

    goToOrder(orderId: number) {
        window.open(`${this.baseURL}/order/view?id=${orderId}`)
    }
}

export const oldAdminUINavigator = new OldAdminUINavigator()
