import React from 'react'
import { Grid } from '@mui/material';
import { DataCard } from '@/admin/components/datacard';
import dayjs from 'dayjs';
import { Loader } from '@/admin/components/Loader'
import { showAddressEditor } from './carrier-address-editor'
import { Button, useRefresh } from 'react-admin';

export interface IAddress {
    address: string
    carrierId: number
    coordinate: {
        x: number
        y: number
    }
    createdAt: string
    id: number
    type: 1 | 3 | 5
}

interface CarrierAddressProps {
    record?: any,
    addresses: any[],
    isLoading: boolean,
    isFetching: boolean
}

const CarrierAddress = ({ record, addresses, isLoading, isFetching }: CarrierAddressProps) => {
    const refresh = useRefresh()
    const typesAddressesCarrierTitle = {
        1: 'Legal Address',
        3: 'Physical Address',
        5: 'Billing Address'
    }

    const getMapURL = (y: number, x: number) => {
        return <a href={`https://www.google.com/maps/search/?api=1&query=${y},${x}`}
                  target={'_blank'}
                  rel="noopener noreferrer">Map</a>;
    }

    const title = (type: 1 | 3 | 5, id: number) => {
        const data = {
            carrierId: record.id,
            type
        }
        return <>
                {typesAddressesCarrierTitle[type]}
                <Button type="button"
                    onClick={() => showAddressEditor('carriers', typesAddressesCarrierTitle[type], refresh, id, data, )}
                    color="primary"
                    label="Edit" />
                </>
    }

    return (
        <Grid container spacing={2} alignContent="flex-start">
            {!isLoading && !isFetching ?
                addresses.map((item: IAddress, indx: number) => {

                    if (item.id) {
                        return (
                            <Grid item xs={12} md={6} lg={6}
                                  key={indx + '_address'}>
                                <DataCard
                                    records={[
                                        {title: 'Address', value: item?.address},
                                        {
                                            title: 'Google Map Url',
                                            value: item.coordinate && item.coordinate.x && item.coordinate.y ? getMapURL(item.coordinate.y, item.coordinate.x) : '(not set)'
                                        },
                                        {
                                            title: 'Created At',
                                            value: item?.createdAt ? dayjs(record?.createdAt).format('MM/DD/YYYY HH:mm') : '(not set)'
                                        }
                                    ]}
                                    title={title(item.type, item.id)}
                                />
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid item xs={12} md={6} lg={6}
                                  key={indx + '_address'}>
                                <DataCard
                                    records={[]}
                                    content={'(no set)'}
                                    title={title(item.type, item.id)}
                                />
                            </Grid>
                        )
                    }
                })
                : <Loader/>}

        </Grid>
    )
}

export default React.memo(CarrierAddress)


