import { HttpV2 } from '@shared/apiv2/api'
import { AdminUser } from '@/admin/store/user/user'

export const setToken = (token: string) => {
    if (token) {
        localStorage.setItem('admin_token', token)
        HttpV2.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
        localStorage.deleteItem('admin_token')
    }
}

export const authenticateAdmin = (email: string, password: string) => {
    return HttpV2.post<AdminUser>('/auth/admin', { email, password }).then((response) => {
        if (response.data.access_token) {
            setToken(response.data.access_token)
            response.data.access_token = undefined
        }
        return response
    })
}
