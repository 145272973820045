import React, { FC, memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import './InfoModal.scss'
import cn from 'classnames'

interface InfoModalProps {
    title?: string
    message: React.ReactNode
    pending?: boolean
    error?: boolean
    noButtons?: boolean
    onClose?: () => void
}

const InfoModal: FC<InfoModalProps> = ({ title, message, pending, noButtons, onClose, error }) => (
    <div className={cn('modal-info', { err: error })}>
        {title && <div className="modal-header">{title}</div>}
        {message && <div className="message">{message}</div>}
        {!noButtons && (
            <div className="btns">
                <Button
                    label="OK"
                    loading={pending}
                    types={['blue']}
                    onClick={() => {
                        ModalRegistry.get().close(Modals.InfoModal)
                        if (onClose) onClose()
                    }}
                />
            </div>
        )}
    </div>
)

ModalRegistry.get().register<InfoModalProps>(Modals.InfoModal, {
    id: 'InfoModalProps',
    className: 'modal-info',
    size: 'medium',
    Component: memo(InfoModal),
})

export const showInfoModal = (props: ModalPartialProps<InfoModalProps>): void =>
    ModalRegistry.get().show<InfoModalProps>(Modals.InfoModal, props)

export const transformBackendErrorToString = (message: string | any): string => {
    let errorMessage =
        typeof message === 'string'
            ? message
            : Object.hasOwn(message, 'data') && message.data
            ? message.data.errors
            : ''

    if (!errorMessage && message) {
        const messages = Object.keys(message).map((k) => message[k])
        if (messages.length) {
            errorMessage = messages.join(', ')
        }
    }
    return errorMessage
}

export const showError = (message: string | any, props: Partial<InfoModalProps> = {}) => {
    const errorMessage = transformBackendErrorToString(message)
    return showInfoModal({
        props: {
            title: 'Error',
            error: true,
            message: errorMessage,
            ...props,
        },
    })
}

export const showInfoModalAsync = (props: ModalPartialProps<InfoModalProps>): Promise<void> =>
    ModalRegistry.get().showAsync<InfoModalProps>(Modals.InfoModal, props)

export default memo(InfoModal)
