import React, { FC, memo, useCallback, useState } from 'react'
import Modal from '@/components/Modal'
import EventBus, { useEvent } from '@shared/common/eventBus'
import { ModalProps, Modals } from '@shared/types'
import { Events } from '@shared/common/events'

const GeneralModal: FC = () => {
    const [open, setOpen] = useState(false)
    const [current, setCurrent] = useState<Modals>()
    const [state, setState] = useState<ModalProps<any>>()

    const handleOpenEvent = useCallback((id: Modals, data: ModalProps<any>) => {
        setCurrent(id)
        setState(data)
        setOpen(true)
    }, [])

    const handleCloseEvent = useCallback(
        (id: Modals) => {
            if (current === id) {
                setOpen(false)
                setCurrent(undefined)
                setState(undefined)
            }
        },
        [current],
    )

    useEvent(Events.OPEN_MODAL, handleOpenEvent)
    useEvent(Events.CLOSE_MODAL, handleCloseEvent)

    return state ? (
        <Modal
            id={state.id}
            open={open}
            onChange={setOpen}
            className={state.className}
            title={state.title}
            size={state.size}
        >
            <state.Component {...state.props} />
        </Modal>
    ) : (
        <></>
    )
}

export default memo(GeneralModal)
