import React from 'react'
import {
    Datagrid,
    List,
    NumberField,
    TextField,
    DateField,
    useRecordContext,
    Button,
    DeleteWithConfirmButton,
    useRefresh,
    FunctionField,
    SelectInput,
    TextInput,
    NumberInput,
} from 'react-admin'
import { showEditor } from './editor'
import { Grid } from '@mui/material'
import { calendarEventTypes, calendarMarkupPlaceType } from '@/admin/model/dictionaries'

export const CalendarPriceMarkupList = () => {
    const refresh = useRefresh()
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('calendar-price-markup', item.id, refresh)
                }}
                label="Edit"
            />
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    perPage={25}
                    filters={[
                        <TextInput key="description" label="Description" source="description_ilike" alwaysOn />,
                        <SelectInput
                            key="markup_type"
                            label="Markup type"
                            source="type"
                            choices={Object.keys(calendarEventTypes).map((r: string) => ({
                                id: r,
                                name: calendarEventTypes[r],
                            }))}
                            alwaysOn
                        />,
                        <SelectInput
                            key="placeType"
                            label="Place Type"
                            source="placeType"
                            choices={Object.keys(calendarMarkupPlaceType).map((r: string) => ({
                                id: r,
                                name: calendarMarkupPlaceType[r],
                            }))}
                            alwaysOn
                        />,
                        <TextInput key="place" label="Place" source="place_ilike" alwaysOn />,
                        <TextInput key="range" label="Range" source="range_ilike" alwaysOn />,
                        <NumberInput key="value" label="Value" source="value" alwaysOn />,
                    ]}
                    actions={
                        <Button
                            type="button"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                showEditor('calendar-price-markup', undefined, refresh)
                            }}
                            label="Create"
                        />
                    }
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="#" sortable={false} width={50} />
                            <DateField source="dateFrom" label="Start date" />
                            <DateField source="dateTo" label="End date" />
                            <TextField source="description" sortable={false} />
                            <FunctionField
                                label="Markup type"
                                render={(r: { type: 'CONSTANT_REPEATED' | 'FLOATING_REPEATED' | 'SPECIAL_EVENT' }) => {
                                    return calendarEventTypes[r.type]
                                }}
                            />
                            <FunctionField
                                label="Place type"
                                render={(r: { placeType: 'ALL' | 'COUNTRY' | 'ZIP' }) =>
                                    calendarMarkupPlaceType[r.placeType]
                                }
                            />
                            <TextField source="place" sortable={false} />
                            <TextField source="range" />
                            <TextField source="value" sortable={false} />
                            <EditButton />
                            <DeleteWithConfirmButton
                                title="Delete Range Price Markup"
                                confirmContent="Are you sure?"
                                redirect={'/admin/app/calendar-price-markup'}
                            />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
