import React from 'react'
import { Datagrid, List, NumberField, TextField, DateField, NumberInput, TextInput } from 'react-admin'
import { Grid } from '@mui/material'
import { validateMaxValue } from '../../../parts/Validations'

export const SmsLogList = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    disableSyncWithLocation
                    storeKey="smsLog"
                    perPage={25}
                    filters={[
                        <NumberInput
                            key="id"
                            source="id"
                            label="ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <NumberInput
                            key="eventId"
                            source="eventId"
                            label="Event ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <TextInput key="eventType" source="event.eventType_ilike" label="Event Type" alwaysOn />,
                        <NumberInput
                            key="status"
                            source="status"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 1)}
                        />,
                        <NumberInput
                            key="recipientId"
                            source="recipientId"
                            label="Recipient ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <NumberInput
                            key="recipientPhoneNumber"
                            source="recipientPhoneNumber"
                            label="Recipient phone number"
                        />,
                        <TextInput key="messagebirdId" source="messagebirdId_ilike" label="Messagebird id" alwaysOn />,
                        <TextInput key="data" source="data_ilike" label="Data" alwaysOn />,
                    ]}
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="ID" width={50} />
                            <TextField source="eventId" label="Entity ID" />
                            <TextField source="event.eventType" sortable={false} />
                            <TextField source="status" />
                            <TextField source="recipientId" label="Recipient ID" />
                            <TextField source="recipientPhoneNumber" label="Recipient phone number" />
                            <TextField source="messagebirdId" label="Messagebird ID" />
                            <TextField source="messagebirdHref" label="Messagebird link" />
                            <TextField source="data" />
                            <DateField source="createdAt" showTime />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
