import { stringify } from 'query-string'
import { HttpV2 } from '@shared/apiv2/api'
import { showError } from '@/admin/parts/Modals/InfoModal'

HttpV2.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response) {
            const response = error.response
            const adminRequest =
                (response.request && response.request.responseURL.indexOf('v2/admin') >= 0) ||
                (response.request && response.request.responseURL.indexOf('auth/admin') >= 0)
            if (response.status === 401 && adminRequest) {
                window.location.replace('/admin/login')
            }
            if (response.status === 500) {
                showError(error.message)
            }
            if (response.status === 504) {
                showError('Service is not available at the moment')
            }
            if (response) {
                return Promise.reject(response.data)
            }
        }
    },
)

export default {
    getList: (resource: string, params: any) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        }
        const url = `/admin/${resource}?${stringify(query)}`

        return HttpV2(url)
            .then(({ headers, data }) => {
                const total = parseInt(headers['content-range']?.split('/')?.pop() ?? data.length, 10)
                return {
                    data,
                    total: total,
                }
            })
            .catch((error) => {
                const response = error.response
                return Promise.reject(response.data)
            })
    },

    getOne: (resource: string, params: any) =>
        HttpV2(`/admin/${resource}/${params.id}`).then(({ headers, data }) => ({
            data,
        })),

    getMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id_in: params.ids }),
        }
        const url = `/admin/${resource}?${stringify(query)}`
        return HttpV2(url).then(({ data }) => ({ data }))
    },

    getManyReference: (resource: string, params: any) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        }

        const url = `/admin/${resource}?${stringify(query)}`

        return HttpV2(url).then(({ headers, data }) => ({
            data,
            total: data.length,
        }))
    },

    create: (resource: string, params: any) =>
        HttpV2.post(`/admin/${resource}`, params.data).then(({ data }) => ({
            data: { ...params.data, id: data.id },
        })),

    update: (resource: string, params: any) =>
        HttpV2.put(`/admin/${resource}/${params.id}`, params.data).then(({ data }) => ({
            data,
        })),

    updateMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return HttpV2.put(`/admin/${resource}?${stringify(query)}`, params.data).then(({ data }) => ({
            data,
        }))
    },

    delete: (resource: string, params: any) =>
        HttpV2.delete(`/admin/${resource}/${params.id}`).then(({ data }) => ({
            data,
        })),

    deleteMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return HttpV2.delete(`/admin/${resource}?${stringify(query)}`, params.data).then(({ data }) => ({
            data,
        }))
    },
}
