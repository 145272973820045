import React, { createElement, useEffect } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import TocIcon from '@mui/icons-material/Toc'
import { useResourceDefinitions, useGetResourceLabel, useCreatePath } from 'ra-core'

const CustomMenu = (props: any) => {
    const resources = useResourceDefinitions()
    const navigate = useNavigate()
    const location = useLocation()
    const createPath = useCreatePath()
    const getResourceLabel = useGetResourceLabel()
    const [submenuOpen, setSubmenuOpen] = React.useState('')

    const handleMenuClick = (path: any) => {
        navigate(
            createPath({
                resource: path,
                type: 'list',
            }),
        )
    }

    const handleSubMenuToggle = (name: string) => {
        setSubmenuOpen(submenuOpen === name ? '' : name)
    }

    return (
        <List>
            {Object.keys(resources)
                .filter((name) => resources[name].hasList && !resources[name]?.options?.submenu)
                .map((name: any) => {
                    return !resources[name]?.options?.children ? (
                        <ListItem
                            button
                            key={name + '_menu_item'}
                            onClick={() => handleMenuClick(`/${resources[name].name}`)}
                            selected={location.pathname.startsWith(`/${resources[name].name}`)}
                        >
                            {resources[name].icon && <ListItemIcon>{createElement(resources[name].icon)}</ListItemIcon>}
                            <ListItemText primary={getResourceLabel(resources[name].name, 2)} />
                        </ListItem>
                    ) : (
                        <div key={name + '_menu_item'}>
                            <ListItem button onClick={() => handleSubMenuToggle(name)}>
                                {resources[name].icon && (
                                    <ListItemIcon>{createElement(resources[name].icon)}</ListItemIcon>
                                )}
                                <ListItemText primary={getResourceLabel(resources[name].name, 2)} />
                            </ListItem>
                            <Collapse in={submenuOpen === name} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {resources[name]?.options?.children.map((n: any, index: number) => (
                                        <ListItem
                                            key={n + '_sub_' + index}
                                            button
                                            onClick={() => handleMenuClick(`/${resources[n].name}`)}
                                            selected={location.pathname.startsWith(`/${resources[n].name}`)}
                                            style={{ backgroundColor: '#c2c2c2' }}
                                        >
                                            {resources[n].icon && (
                                                <ListItemIcon>
                                                    {createElement(n === name ? TocIcon : resources[n].icon)}
                                                </ListItemIcon>
                                            )}
                                            <ListItemText primary={getResourceLabel(resources[n].name, 2)} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </div>
                    )
                })}
        </List>
    )
}

export default CustomMenu
