import * as React from 'react';
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, required } from 'react-admin';
import {Grid} from '@mui/material'

export const showPhoneEditor = (resource: string, recordId?: number | string) => {

    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={8}>
                <TextInput source="phone.number" validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Manager Phone',
            resource: resource,
            recordId,
            children: form
        },
    })
}

