export const BASE_URL = '/admin/app'
const COMPANY_LINK = '/companies'
const HOME_LINK = COMPANY_LINK
const MANAGER_LINK = '/managers'
const CARRIERS_LINK = '/carriers'

const TOS_LINK = '/terms-of-service'

export type IBItem = {
    label: string
    link: string
    linkId?: string
    disabled?: boolean | undefined
    replace?: string
}

export const home = {
    label: 'Home',
    link: HOME_LINK,
}

const BData: { [key: string]: any } = {
    companies: [
        {
            label: 'Companies',
            link: HOME_LINK,
        },
    ],
    companies_show: [
        {
            label: 'Companies',
            link: HOME_LINK,
        },
        {
            label: 'entity',
            link: '',
            disabled: true,
        },
    ],
    terms_of_service: [
        {
            label: 'Terms of Service',
            link: TOS_LINK,
        },
    ],
    managers: [
        {
            label: 'Managers',
            link: MANAGER_LINK,
        },
    ],
    managers_show: [
        {
            label: 'Company: (ID: :xxx)',
            link: COMPANY_LINK + '/:id/show',
            linkId: 'companyId',
            replace: 'companyId',
        },
        {
            label: 'Managers',
            link: MANAGER_LINK,
            linkId: 'companyId',
        },
        {
            label: ['firstName', 'lastName'],
            link: '',
            disabled: true,
        },
    ],
    carriers: [
        {
            label: 'Carriers',
            link: CARRIERS_LINK,
        },
    ],
    'setting-type': [
        {
            label: 'Setting type',
            link: '',
            disabled: true,
        },
    ],
    carriers_show: [
        {
            label: 'Company: (ID: :xxx)',
            link: COMPANY_LINK + '/:id/show',
            linkId: 'companyId',
            replace: 'companyId',
        },
        {
            label: 'Carriers',
            link: CARRIERS_LINK,
        },
    ],
    warehouse: [
        {
            label: 'Warehouses',
            link: '',
            disabled: true,
        },
    ],
    warehouse_show: [
        {
            label: 'Warehouse',
            link: '',
            disabled: true,
        },
    ],
    warehouse_service_show: [
        {
            label: 'Warehouse Service',
            link: '',
            disabled: true,
        },
    ],
    warehouse_document_show: [
        {
            label: 'Warehouse document',
            link: '',
            disabled: true,
        },
    ],
    warehouse_service: [
        {
            label: 'Warehouse Service',
            link: '',
            disabled: true,
        },
    ],
    warehouse_document: [
        {
            label: 'Warehouse document',
            link: '',
            disabled: true,
        },
    ],
    warehouse_exports: [
        {
            label: 'Warehouse Exports',
            link: '',
            disabled: true,
        },
    ],
    support_list: [
        {
            label: 'Support Messages',
            link: '',
            disabled: true,
        },
    ],
    support_category_list: [
        {
            label: 'Support Categories',
            link: '',
            disabled: true,
        },
    ],
    'day-price-markup': [
        {
            label: 'Day Price Markup',
            link: '',
            disabled: true,
        },
    ],
    'range-price-markup': [
        {
            label: 'Range Price Markup',
            link: '',
            disabled: true,
        },
    ],
    'time-price-markup': [
        {
            label: 'Time Price Markup',
            link: '',
            disabled: true,
        },
    ],
    'calendar-price-markup': [
        {
            label: 'Calendar Price Markup',
            link: '',
            disabled: true,
        },
    ],
    'feature-flags': [
        {
            label: 'Feature flags',
            link: '',
            disabled: true,
        },
    ],
}

export default BData
