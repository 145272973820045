import React from 'react'
import { Datagrid, DateField, List, NumberField, ReferenceInput, SelectInput, TextField, TextInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'

export const WarehouseDocumentsList = () => {
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            disableSyncWithLocation
            storeKey={'WarehousesDocumentsList'}
            perPage={25}
            filters={[
                <ReferenceInput
                    key={'service'}
                    label="Service"
                    source="svcDocTypeId"
                    reference="warehouse-documents/services"
                    allowEmpty
                    alwaysOn
                >
                    <SelectInput
                        source="svcDocType.warehouseSvcType.title"
                        label="Service"
                        alwaysOn
                        optionText="name"
                    />
                </ReferenceInput>,
                <ReferenceInput
                    key={'document'}
                    label="Document"
                    source="svcDocType.id"
                    reference="warehouse-documents/documents"
                    allowEmpty
                    alwaysOn
                >
                    <SelectInput label="Document" optionText="name" />
                </ReferenceInput>,
                <TextInput key="warehouse" label="Warehouse" source="warehouse.entity_ilike" alwaysOn />,
                <TextInput key="company" label="Company" source="warehouse.company.entity_ilike" alwaysOn />,
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.document].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
                <SelectInput
                    key="expiredAt"
                    source="expiredAt_ene"
                    label="Expired At"
                    choices={[
                        {
                            id: 'empty',
                            name: 'Empty',
                        },
                        {
                            id: 'notEmpty',
                            name: 'Not Empty',
                        },
                        {
                            id: 'expired',
                            name: 'Expired',
                        },
                    ]}
                    alwaysOn
                />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" label="ID" sortable={false} />
                    <TextField source="svcDocType.warehouseSvcType.title" label="Service" width={100} />
                    <TextField source="svcDocType.name" label="Document" width={100} />
                    <TextField source="warehouse.entity" label="Warehouse" width={100} />
                    <TextField source="warehouse.company.entity" label="Company" width={100} />
                    <StatusField source="status" statusType={StatusType.document} label="Status" />
                    <DateField source="expiredAt" showTime />
                    <DateField source="createdAt" showTime />
                    <DateField source="updatedAt" showTime />
                </Datagrid>
            </>
        </List>
    )
}
