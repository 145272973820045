import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { required, TextInput } from 'react-admin'
import { Grid } from '@mui/material'

export const showEditor = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="from" label="From, mi" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="value" validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Edit Range Price Markup' : 'Create Range Price Markup',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
        },
    })
}
