import {
    Datagrid,
    DateField,
    DateInput,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    NumberInput,
    useRecordContext,
    FunctionField,
} from 'react-admin'
import React, { FC } from 'react'
import { useServiceTypes } from '@/admin/api/admin.api'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { useNavigate } from 'react-router-dom'
import { StatusField } from '@/admin/resources/order/order-status'
import { Button } from '@mui/material'

const NavigateToField: FC<any> = ({ link, children, id }) => {
    const record = useRecordContext()
    const navigate = useNavigate()
    const navigateTo = (e: any) => {
        e.stopPropagation()
        navigate(link.replace(':id', record[id]))
    }
    return (
        <Button size="small" color="primary" onClick={navigateTo}>
            {children}
        </Button>
    )
}

interface OrdersListCarrierProps {
    warehouse: any
    children?: any
}

export const OrdersListWarehouse = ({ warehouse }: OrdersListCarrierProps) => {
    const { data, error } = useServiceTypes()
    const servicesLoading = !data && !error
    return (
        <List
            disableSyncWithLocation
            storeKey="WHOrders"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            resource={'orders'}
            filters={[
                <NumberInput key="id" label="ID" source="id" alwaysOn />,
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.order].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
                <TextInput key="carrier" label="Carrier Entity" source="carrier.entity_ilike" alwaysOn />,
                <SelectInput
                    isLoading={servicesLoading}
                    key="service"
                    label="Service"
                    source="services.code_in"
                    choices={
                        servicesLoading || !data ? [] : data.map((item: any) => ({ id: item.code, name: item.title }))
                    }
                    alwaysOn
                />,
                <DateInput key="createdAt_gte" label="Date from" source="services.periodStart_dgte" alwaysOn />,
                <DateInput key="createdAt_lte" label="Date to" source="services.periodEnd_dlte" alwaysOn />,
                <DateInput key="createdAt" source="createdAt_day" alwaysOn />,
            ]}
            filter={{
                warehouseId: warehouse.id,
            }}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" label="ID" />
                    <StatusField source="status" />
                    <TextField source="carrier.entity" label="Carrier" />
                    <NavigateToField
                        label="Carrier Manager"
                        key="carrier-email"
                        link={'/admin/app/managers/:id/show'}
                        id="carrierManagerId"
                    >
                        <TextField source="carrierManager.email.email" label="Carrier Manager" sortable={false} />
                    </NavigateToField>
                    <TextField source="servicesString" sortable={false} label="Services" />
                    <NumberField source="mainService.amount" label="Pallets" sortable={false} />
                    <NumberField source="mainService.days" label="Days" sortable={false} />
                    <DateField
                        source="mainService.periodStart"
                        label="Date from"
                        sortBy="services.periodStart"
                        options={{ timeZone: 'UTC' }}
                    />
                    <DateField
                        source="mainService.periodEnd"
                        label="Date to"
                        sortBy="services.periodEnd"
                        options={{ timeZone: 'UTC' }}
                    />
                    <NumberField
                        source="carrierTotal"
                        label="Full price ($)"
                        locales="en-US"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <NumberField
                        source="warehouseTotal"
                        label="WH Total ($)"
                        locales="en-US"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <FunctionField
                        label="WH Olimp fee ($)"
                        render={(r: any) => '$' + (r.carrierTotal - r.total).toFixed(2)}
                    />
                    <FunctionField
                        label="Margin ($)"
                        render={(r: any) => '$' + (r.carrierTotal - r.warehouseTotal).toFixed(2)}
                    />
                    <NumberField
                        source="discount"
                        label="Discount ($)"
                        locales="en-US"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <NumberField source="markup.total" label="Total markup" />
                    <DateField source="createdAt" showTime />
                </Datagrid>
            </>
        </List>
    )
}
