import React from 'react'
import { Datagrid, List, TextField, useRecordContext, Button, useRefresh, TextInput, FunctionField } from 'react-admin'
import { showEditor } from './editor'
import { Grid } from '@mui/material'

export const SystemEnvironmentList = () => {
    const refresh = useRefresh()
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('system-env', item.id, refresh)
                }}
                label="Edit"
            />
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List perPage={25} filters={[<TextInput key="title" source="title_ilike" label="Type" alwaysOn />]}>
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <TextField source="title" label="Type" />
                            <FunctionField
                                label={'Value'}
                                render={(r: any) => {
                                    const s = r.value.value ? JSON.stringify(r.value.value) : ''
                                    if (s.length <= 43) {
                                        return s
                                    } else {
                                        return s.substring(0, 43) + '...'
                                    }
                                }}
                            />
                            <EditButton />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
