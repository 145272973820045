import * as React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from 'react-router-dom'
import './Breadcrumbs.scss'
import { useRecordContext } from 'react-admin'
import BData, { home, IBItem, BASE_URL } from './data'

type BasicBreadcrumbsProps = {
    name: string
    replace?: string
}
const link = (path: string, record?: any, id?: string | undefined) => {
    if (record && id && record[id]) {
        return BASE_URL + path.replace(/:id/g, record[id])
    }
    return BASE_URL + path
}

const getMenuName = (label: string, record?: any, replace?: string | undefined) => {
    let text = ''
    if (Array.isArray(label)) {
        label.forEach((item) => {
            text += record && record[item] !== undefined ? record[item] + ' ' : item
        })
    } else if (record && record[label]) {
        text = record[label]
    } else {
        text = label
    }
    if (replace && record && record[replace]) {
        return label.replace(/:xxx/g, record[replace])
    }

    return text
}

export default function BasicBreadcrumbs({ name }: BasicBreadcrumbsProps) {
    const record = useRecordContext()
    return (
        <div className="breadcrumb-block">
            <Breadcrumbs aria-label="breadcrumb">
                <Link to={link(home.link)}>{home.label}</Link>
                {BData[name].map((item: IBItem, i: number) => {
                    return (
                        <Link
                            key={i + '_bb'}
                            to={link(item.link, record, item.linkId)}
                            className={item.disabled ? 'disabled' : ''}
                        >
                            {getMenuName(item.label, record, item.replace)}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    )
}
