import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { BooleanInput } from 'react-admin'
import { Grid } from '@mui/material'
export const showConditionEditor = (
    resource: string,
    recordId: number | string,
    value: boolean,
    onSuccess: () => void,
) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <BooleanInput source="isActive" label="Is Active?" defaultValue={value} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'One day of warehousing for Local delivery is free',
            resource: 'warehouses/condition',
            recordId,
            children: form,
            onSuccess,
        },
    })
}
