import { HttpV2 } from '@shared/apiv2/api'
import { useEffect, useState } from 'react'

export const useVersions = () => {
    const [apiVersion, setApiVersion] = useState()
    const [uiVersion, setUiVersion] = useState<string>(process.env.REACT_APP_VERSION ?? '')

    useEffect(() => {
        HttpV2.get('/system/info').then((response) => {
            setApiVersion(response.data.version)
        })

        if (!uiVersion) {
            try {
                import('../../../package.json').then((pkg) => {
                    setUiVersion(pkg.default.version)
                })
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
                console.log('test')
            }
        }
    }, [])

    return { apiVersion, uiVersion }
}
