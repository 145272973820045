import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { SelectInput, TextInput, NumberInput, required } from 'react-admin'
import { Grid } from '@mui/material'

export const showCreator = (
    resource: string,
    supportCategories: any,
    recordId?: number | string,
    onSuccess?: () => void,
) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="name" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="phone" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="email" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="category"
                    label="Category"
                    source="categoryId"
                    choices={supportCategories.map((item: any) => ({
                        id: item.id,
                        name: item.title,
                    }))}
                    alwaysOn
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="message" multiline validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="note" multiline />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Create Message',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
        },
    })
}
