import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { Button, Card, CardContent, Typography, CircularProgress, Grid } from '@mui/material'
import { useVersions } from '@/admin/hooks/useVersions'
import { HttpV2 } from '@shared/apiv2/api'
import { useSFConnectivity } from '@/admin/hooks/useSFConnectivity'
import { StatusDot } from '@/admin/components/StatusDot/StatusDot'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { green, red } from '@mui/material/colors'

const StatusIndicator = ({ status }: any) => {
    const color = status ? green[500] : red[500]

    return <FiberManualRecordIcon style={{ color: color }} />
}

export const DevopsPanel = () => {
    const { uiVersion, apiVersion } = useVersions()
    const { sfConnectivity, loading: sfLoading } = useSFConnectivity()
    const notify = useNotify()
    const [loading, setLoading] = useState<any>({})

    console.log(sfConnectivity)

    const handleAction = async (actionName: string, action: () => Promise<any>) => {
        try {
            setLoading((prev: any) => ({ ...prev, [actionName]: true }))
            await action()
            notify(`${actionName} completed successfully`, { variant: 'success' })
        } catch (error: any) {
            notify(`Error in ${actionName}: ${error.message}`, { variant: 'error' })
        } finally {
            setLoading((prev: any) => ({ ...prev, [actionName]: false }))
        }
    }

    const actions = [
        {
            name: 'Sync Accounts',
            description: 'Sync Accounts',
            action: async () => {
                return HttpV2.get('/admin/devops/jobs/account?full=true')
            },
        },
        {
            name: 'Sync Contacts',
            description: 'Sync Contacts',
            action: async () => {
                return HttpV2.get('/admin/devops/jobs/contact?full=true')
            },
        },
        {
            name: 'Sync RFQs',
            description: 'Sync RFQs',
            action: async () => {
                return HttpV2.get('/admin/devops/jobs/opportunity?full=true')
            },
        },
        {
            name: 'Sync Invoices',
            description: 'Sync Invoices',
            action: async () => {
                return HttpV2.get('/admin/devops/jobs/invoice?full=true')
            },
        },
    ]

    return (
        <Card style={{ marginTop: 32 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    DevOps Control Panel
                </Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">System Information</Typography>
                        <Typography variant="body1">
                            UI Version: <b>{uiVersion}</b>
                        </Typography>
                        <Typography variant="body1">
                            API Version: <b>{apiVersion}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">SalesForce - WebApp</Typography>

                        <Typography
                            variant="body1"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            SF Connectivity: <StatusIndicator status={sfConnectivity?.healthy} />
                        </Typography>
                        {actions.map(({ name, description, action }) => (
                            <Button
                                key={name}
                                variant="contained"
                                color="primary"
                                onClick={() => handleAction(name, action)}
                                disabled={loading[name]}
                                style={{ margin: '5px' }}
                                size="small"
                            >
                                {loading[name] ? <CircularProgress size={24} /> : description}
                            </Button>
                        ))}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
