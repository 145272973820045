import * as React from 'react';
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, RadioButtonGroupInput } from 'react-admin';
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { settingTypeList, settingArea } from '@/admin/model/dictionaries'
import {Grid} from '@mui/material'

export const showEditor = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextInput source="name" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="idName" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="group" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="fieldSettings" />
            </Grid>
            <Grid item xs={12} md={4}>
                <SelectInput
                    key="type"
                    label="Type"
                    source="type"
                    choices={settingTypeList.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }))}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SelectInput
                    key="area"
                    label="Area"
                    source="area"
                    choices={[1,2,3,4,5].map((key)=> ({
                        id: key,
                        name: settingArea[key],
                    }))}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RadioButtonGroupInput validate={required()} source="required" choices={[
                    { id: true, name: 'True' },
                    { id: false, name: 'False' },
                ]} />
            </Grid>
            <Grid item xs={12} md={4}>
                <RadioButtonGroupInput validate={required()} source="showInUi" label="Show in UI" choices={[
                    { id: true, name: 'True' },
                    { id: false, name: 'False' },
                ]} />
            </Grid>
            <Grid item xs={12} md={4}>
                <RadioButtonGroupInput validate={required()} source="isCommon" label="Common setting" choices={[
                    { id: true, name: 'True' },
                    { id: false, name: 'False' },
                ]} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Edit Setting Type' : 'Create Setting Type',
            resource: resource,
            recordId,
            children: form,
            onSuccess
        },
    })
}

