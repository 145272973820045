import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { required, TextInput, TimeInput } from 'react-admin'
import { Grid } from '@mui/material'
import { dateToTime } from '@/admin/utils/dates'

export const showEditor = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TimeInput source="from" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TimeInput source="to" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="value" validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Edit Time Price Markup' : 'Create Time Price Markup',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
            model: (model) => ({
                ...model,
                from: dateToTime(model.from),
                to: dateToTime(model.to),
            }),
        },
    })
}
