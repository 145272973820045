import React from 'react'
import { Datagrid, List, NumberField, TextField, DateField, TextInput, NumberInput } from 'react-admin'
import { Grid } from '@mui/material'
import { validateMaxValue } from '../../../parts/Validations'

export const EventLogList = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    disableSyncWithLocation
                    storeKey="eventLog"
                    perPage={25}
                    filters={[
                        <NumberInput
                            key="id"
                            source="id"
                            label="ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <TextInput key="eventType" source="eventType_ilike" label="Event Type" alwaysOn />,
                        <NumberInput
                            key="entityId"
                            source="entityId"
                            label="Entity ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <NumberInput
                            key="creatorId"
                            source="creatorId"
                            label="Creator ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <TextInput key="creatorType" source="creatorType_ilike" label="Creator Type" alwaysOn />,
                    ]}
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="ID" width={50} />
                            <TextField source="eventType" />
                            <TextField source="entityId" label="Entity ID" />
                            <TextField source="creatorId" label="Creator ID" />
                            <TextField source="creatorType" />
                            <DateField source="createdAt" showTime />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
