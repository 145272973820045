import React, { FC, memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { CreateBase, EditBase, Form, SaveButton, useNotify } from 'react-admin'
import { Button } from '@mui/material'
import './EditorModal.scss'

interface EditorModalProps {
    title: string | (() => string)
    resource: string
    children: React.ReactNode
    recordId?: number | string
    pending?: boolean
    onSuccess?: () => void
    onError?: () => void
    successMessage?: string
    errorMessage?: string
    defaultValue?: any
    model?: false | ((model: any) => any)
}

const EditorModal: FC<EditorModalProps> = ({
    title,
    resource,
    recordId,
    children,
    defaultValue = {},
    onSuccess,
    onError,
    successMessage = 'Information saved',
    errorMessage = 'Something happened wrong',
    model = false,
}) => {
    const notify = useNotify()
    let mutationOptions = {}
    const onSuccessOption = () => {
        ModalRegistry.get().close(Modals.AdminEditorModal)
        notify(successMessage, { type: 'success' })
        if (onSuccess) {
            onSuccess()
        }
    }
    const onErrorOption = (error: { message: string[] }) => {
        notify(error?.message[0], { type: 'error' })
        if (onError) {
            notify(errorMessage, { type: 'error' })
            onError()
        }
    }
    mutationOptions = Object.assign(mutationOptions, { onSuccess: onSuccessOption })
    mutationOptions = Object.assign(mutationOptions, { onError: onErrorOption })
    const transform = (data: any) => ({
        ...data,
    })
    let form = (
        <CreateBase resource={resource} mutationOptions={mutationOptions} transform={model ? model : transform}>
            <Form defaultValues={defaultValue}>
                {children}
                <SaveButton />{' '}
                <Button className="ml10" onClick={() => closeModal()}>
                    {' '}
                    Cancel{' '}
                </Button>
            </Form>
        </CreateBase>
    )
    const closeModal = () => ModalRegistry.get().close(Modals.AdminEditorModal)

    if (recordId) {
        form = (
            <EditBase
                actions={false}
                resource={resource}
                id={recordId}
                mutationMode="pessimistic"
                mutationOptions={mutationOptions}
                transform={model ? model : transform}
            >
                <Form defaultValues={defaultValue}>
                    {children}
                    <SaveButton />{' '}
                    <Button className="ml10" onClick={() => closeModal()}>
                        {' '}
                        Cancel{' '}
                    </Button>
                </Form>
            </EditBase>
        )
    }

    return (
        <div className="modal-editor">
            <h2>{title}</h2>
            {form}
        </div>
    )
}

ModalRegistry.get().register<EditorModalProps>(Modals.AdminEditorModal, {
    id: 'EditorModalProps',
    className: 'modal-editor',
    size: 'medium',
    Component: memo(EditorModal),
})

export const showEditorModal = (props: ModalPartialProps<EditorModalProps>): void =>
    ModalRegistry.get().show<EditorModalProps>(Modals.AdminEditorModal, props)

export default memo(EditorModal)
