import React from 'react'
import {
    BooleanField,
    CloneButton,
    Datagrid,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin'
import { PercentField, TotalField } from '@/admin/fields/common'
import { Button, CircularProgress } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'
import { scenarios } from '@/admin/resources/price-sheet/create-sheet'

const ActivateButton = (props: any) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext<any>(props)
    const [activateLoading, setActivateLoading] = React.useState<boolean>(false)

    const sendActivate = async (id: number) => {
        setActivateLoading(true)

        try {
            await HttpV2.put(`/admin/price-sheets/${id}/activate`)
            await refresh()
            notify('Price sheet was activated. Upcoming orders will use it now')
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
        setActivateLoading(false)
    }

    return (
        <Button
            color={record.active ? 'success' : 'primary'}
            size="small"
            onClick={() => {
                if (!record.active) {
                    sendActivate(record.id)
                }
            }}
        >
            {!record.active ? !activateLoading ? 'Activate' : <CircularProgress size={25} thickness={2} /> : 'Active'}
        </Button>
    )
}

const DeleteButton = (props: any) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)
    const record = useRecordContext<any>(props)

    const remove = async (id: number) => {
        setDeleteLoading(true)
        try {
            await HttpV2.delete(`/admin/price-sheets/${id}`)
            await refresh()
            notify('Price sheet was removed', { type: 'success' })
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
        setDeleteLoading(false)
    }

    return !record.active && Number(record.totalOrders) === 0 ? (
        <Button
            color={'error'}
            size="small"
            onClick={() => {
                if (!record.active) {
                    remove(record.id)
                }
            }}
        >
            {!record.active ? !deleteLoading ? 'Delete' : <CircularProgress size={25} thickness={2} /> : 'Active'}
        </Button>
    ) : null
}

export const PriceSheetList = () => {
    return (
        <List
            perPage={25}
            filters={[
                <TextInput key="id" label="ID" source="id" />,
                <SelectInput key="scenarioId" label="Scenario" source="scenarioId" choices={scenarios} />,
            ]}
        >
            <>
                <Datagrid
                    bulkActionButtons={false}
                    rowClick="show"
                    rowStyle={(record) => ({ backgroundColor: record.active ? '#e1ffe2' : 'transparent' })}
                >
                    <NumberField source="id" label="ID" sortable={false} />
                    <NumberField source="totalOrders" label="Orders total" sortable={false} />
                    <TextField source="label" label="Scenario" sortable={false} style={{ fontWeight: 'bold' }} />
                    <BooleanField source="useServiceFee" label="Service Fee used" />
                    <PercentField source="serviceFeePercentage" label="Service fee" />
                    <BooleanField source="useCarrierStripeFee" label="Carrier Stripe Fee used" />
                    <PercentField source="carrierStripeFeePercentage" label="Carrier Stripe Fee" />
                    <TotalField source="carrierStripeFeeConstant" label="Carrier Stripe Fee Constant" />

                    <BooleanField source="useWHStripeFee" label="Warehouse Stripe Fee used" />
                    <PercentField source="whStripeFeePercentage" label="Warehouse Stripe Fee" />
                    <TotalField source="whStripeFeeConstant" label="Warehouse Stripe Fee Constant" />
                    <BooleanField source="useMarkup" label="Markup used" />
                    <BooleanField source="usePublicFee" label="Public Fee used" />
                    <PercentField source="publicFeePercentage" label="Public Fee" />
                    <ActivateButton />
                    <DeleteButton />
                    <CloneButton />
                </Datagrid>
            </>
        </List>
    )
}
