import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { SelectInput, TextInput } from 'react-admin'
import { Grid } from '@mui/material'

export const showEditor = (resource: string, record: any, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="note" multiline />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: record ? 'Edit Referral' : 'Create Referral',
            resource: resource,
            recordId: record?.id,
            children: form,
            onSuccess,
        },
    })
}
