import React, { FC } from 'react'
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    NumberField,
    PublicFieldProps,
    SelectInput,
    TextField,
    TextInput,
    NumberInput,
    useRecordContext,
    WrapperField,
} from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { BooleanStatusField, LongField } from '@/admin/fields/common'
import { StatusDot } from '@/admin/components/StatusDot/StatusDot'

export const CompanyField: FC<PublicFieldProps & { width?: number }> = () => {
    const record = useRecordContext()

    if (!record.company) {
        return <span style={{ width: 200, display: 'inline-block' }}>-</span>
    }

    return (
        <span style={{ width: 200, display: 'inline-block' }}>
            ({record.company.id}) {record.company.entity}
        </span>
    )
}

export const ManagerList = () => {
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            filters={[
                <NumberInput key="id" label="ID" source="id" type="number" parse={(v) => parseInt(v, 10)} alwaysOn />,
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
                <SelectInput
                    key="role"
                    label="Role"
                    source="role"
                    choices={statusDescriptions[StatusType.appRole].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
                <TextInput key="email" label="Email" source="email.email_ilike" alwaysOn />,
                <TextInput key="phone" label="Phone" source="phone.number_ilike" alwaysOn />,
                <TextInput key="company" label="Company" source="company.entity_ilike" alwaysOn />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" />
                    <StatusField source="status" statusType={StatusType.entity} />
                    <LongField
                        style={{ paddingLeft: 24 }}
                        source="email.email"
                        label="Email"
                        addon={(record) =>
                            record.email?.status >= 0 && (
                                <StatusDot type={record.email?.status === 5 ? 'success' : 'error'} />
                            )
                        }
                    />
                    <WrapperField label="SF Account" sortBy="sfAccountId">
                        <TextField source="account.name" label="Account" width={50} />
                    </WrapperField>
                    <TextField source="phone.number" label="Phone" sortable={false} />
                    <WrapperField label="Full name" sortBy="firstName">
                        <TextField source="firstName" label="First Name" width={100} />{' '}
                        <TextField source="lastName" label="Last Name" width={100} />
                    </WrapperField>
                    <WrapperField label="Role" sortBy="role">
                        {/*    it should show based on role value */}
                        <StatusField source="role" statusType={StatusType.appRole} />
                    </WrapperField>
                    <BooleanStatusField source="termsAccepted" label="TOS Accepted" sortable={false} />
                    <LongField source="position" label="Position" width={100} sortable={false} />
                    <TextField source="type" label="Type" sortable={false} />
                    <DateField source="lastActivityAt" label="Last Activity" showTime />
                    <DateField source="updatedAt" label="Changed at" showTime />
                </Datagrid>
            </>
        </List>
    )
}
