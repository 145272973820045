import React from 'react'
import { useShowContext } from 'react-admin'
import { Grid } from '@mui/material'
import { DataCard } from '@/admin/components/datacard'
import dayjs from 'dayjs'
import { Loader } from '@/components/Loader'
import './show.scss'

export const ShowPushNotificationLog = () => {
    const { record, isLoading, isFetching } = useShowContext()
    console.log(record?.data)
    const loading = !record && (isLoading || isFetching)
    if (loading) {
        return <Loader />
    }
    if (record?.data && typeof record?.data === 'string') {
        record.data = JSON.parse(record.data)
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={5} className="push-notification-view">
                <DataCard
                    records={[
                        { title: 'ID', value: record?.id },
                        { title: 'Event ID', value: record?.event?.eventType },
                        { title: 'Status', value: record?.status ? 'Success' : 'Failure' },
                        {
                            title: 'Recipient Email',
                            value: (
                                <a href={`mailto:${record?.recipientEmail?.email}`}>{record?.recipientEmail?.email}</a>
                            ),
                        },
                        { title: 'Recipient token', value: record?.pushToken },
                        { title: 'Push ID', value: record?.pushId },
                        { title: 'Message', value: record?.data?.message },
                        { title: 'Title', value: record?.data?.title },
                        {
                            title: 'Result',
                            value: `status: ${record?.data?.result?.status} => id: ${record?.data?.result?.id}`,
                        },
                        { title: 'Created At', value: dayjs(record?.createdAt).format('MM/DD/YYYY HH:mm:ss') },
                    ]}
                    title={record?.id}
                />
            </Grid>
        </Grid>
    )
}
