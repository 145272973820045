import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import React from 'react'
import { HttpV2 } from '@shared/apiv2/api'
import { Button, CircularProgress } from '@mui/material'

export const ManagerTosDeactivate = () => {
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext<any>()
    const [deactivateLoading, setDeactivateLoading] = React.useState<boolean>(false)

    const setActive = async (id: number, active = false) => {
        setDeactivateLoading(true)

        try {
            await HttpV2.put(`/admin/managers/${id}/tos/${active ? 'activate' : 'deactivate'}`)
            await refresh()
            notify(`Manager terms of service was ${active ? 'activated' : 'deactivated'}`)
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
        setDeactivateLoading(false)
    }

    let buttonChild: React.ReactNode = ''

    if (deactivateLoading) {
        buttonChild = <CircularProgress size={25} thickness={2} />
    }
    buttonChild = record.termsAccepted ? 'Deactivate' : 'Activate'

    return (
        <Button
            color={'primary'}
            sx={{
                color: !record.termsAccepted ? '#52a775' : '#ed6868',
                height: 50,
            }}
            size="small"
            onClick={() => {
                setActive(record.id, !record.termsAccepted)
            }}
        >
            {buttonChild}
        </Button>
    )
}
