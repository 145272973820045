import React from 'react'

export enum UserActionType {
    SET_LOGGED_STATE = 'SET_LOGGED_STATE',
    LOGOUT = 'LOGOUT',
}

export interface AdminUser {
    id: number
    role: number
    email: string
    access_token?: string
}

export type UserAction = { type: UserActionType.SET_LOGGED_STATE; user: AdminUser } | { type: UserActionType.LOGOUT }

export interface UserState {
    isLoggedIn: boolean
    user?: AdminUser
}

export interface UserStore {
    state: UserState
    dispatch?: React.Dispatch<UserAction>
}
