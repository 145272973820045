import React from 'react'
import Breadcrumbs from '@/admin/components/ui/Breadcrumbs'

interface BreadcrumbsLayoutProps {
    name: string;
    children: React.ReactNode;
}

const BreadcrumbsLayout: React.FC<BreadcrumbsLayoutProps> = ({ children, name }) => {
    return <>
        <Breadcrumbs name={name} />
        {children}
    </>
}

export default BreadcrumbsLayout
