import React, { CSSProperties, FC } from 'react'
import './datacard.scss'
import cn from 'classnames'

export const DATACARD_CLASS_NAME = 'a-data-card'

interface IDataCardRecord {
    title: React.ReactNode
    value: React.ReactNode
    subValue?: React.ReactNode
    subTitle?: React.ReactNode
}

type ConfigRecord = {
    type: 'divider' | 'styles'
    style?: any
}

interface DataCardProps {
    title?: string | React.ReactNode
    records: (IDataCardRecord | ConfigRecord)[]
    content?: React.ReactNode
    style?: CSSProperties
    className?: string
}

export const DataCard: FC<DataCardProps> = ({ title, records, content, style, className }) => {
    return (
        <div className={cn(DATACARD_CLASS_NAME, className)} style={style}>
            {title && <h3 className={`${DATACARD_CLASS_NAME}__title`}>{title}</h3>}
            {content}
            <div className={`${DATACARD_CLASS_NAME}__records`}>
                {records.map((record, index) => {
                    const rec = record as IDataCardRecord
                    const configRec = record as ConfigRecord
                    if (rec.title) {
                        return (
                            <div key={rec.title.toString() + index} className={`${DATACARD_CLASS_NAME}__record`}>
                                <div className={`${DATACARD_CLASS_NAME}__s-title`}>
                                    {rec.title}
                                    {rec.subTitle && (
                                        <div className={`${DATACARD_CLASS_NAME}__subValue`}>{rec.subTitle}</div>
                                    )}
                                </div>
                                <div className={`${DATACARD_CLASS_NAME}__value`}>
                                    {rec.value}
                                    {rec.subValue && (
                                        <div className={`${DATACARD_CLASS_NAME}__subValue`}>{rec.subValue}</div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                    if (configRec.type === 'divider') {
                        return <div key={`divider-${index}`} className={`${DATACARD_CLASS_NAME}__divider`} />
                    }
                })}
            </div>
        </div>
    )
}
