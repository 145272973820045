import React, { FC, useState} from 'react'
import { HttpV2 } from '@shared/apiv2/api'
import { useRefresh, Button, FunctionField } from 'react-admin';
import ToggleIcon from '@mui/icons-material/ToggleOn';

import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  Identifier,
} from 'react-admin'

export interface FeatureFlagsProps {
  id: Identifier,
  key: string,
  description: string,
  enabled: boolean,
}

export const FeatureFlagsList: FC = () => {
    const refresh = useRefresh();

    const toggleFlag = async (id: Identifier, resource: string, enabled: boolean) => {

        const date = new Date();
        const payload = {
            enabled: enabled,
            date: date.toISOString(),
        };

        const response = await HttpV2.put(`/admin/${resource}/${id}/toggle`, payload)
        if (!response) {
            throw new Error('Failed to toggle feature flag');
        }
        refresh()
    };

    return (
    <List
        sort={{ field: 'key', order: 'DESC' }}
        perPage={25}
        filters={[
            <TextInput key="key" label="Feature name" source="key" alwaysOn />,
            <TextInput key="description" label="Feature description" source="description" alwaysOn />,
            // <BooleanInput key="enabled" label="Toggle ON|OFF" source="enabled" alwaysOn />,
        ]}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField source="key" label="Feature name" />
            <TextField source="description" label="Feature description" />
            <FunctionField
                label="Toggle ON/OFF"
                source='enabled'
                render={(record: FeatureFlagsProps) => (
                <Button
                    onClick={() => toggleFlag(record.id, 'feature-flags', record.enabled)}
                    color= { record.enabled ? 'primary' : 'inherit' }
                    label= { record.enabled ? 'ON' : 'OFF' }
                    startIcon={<ToggleIcon />}
                />
                )}
            />
            {/* <EditButton /> */}
        </Datagrid>
    </List>
    )
}
