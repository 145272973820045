import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, regex, BooleanInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { Grid } from '@mui/material'

export const showEditor = (resource: string, recordId?: number | string) => {
    /* eslint-disable no-useless-escape */
    const regexQuery =
        '^(https?://)?(www.)?([-a-z0-9]{1,63}.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9].[a-z]{2,6}(/[-w@+.~#?&/=%]*)?$'
    const url = new RegExp(regexQuery, 'i')
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="name" label="Name" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="daysRelated" />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="code"
                    label="Code"
                    source="code"
                    choices={statusDescriptions[StatusType.services].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="isActive" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Company',
            resource: resource,
            recordId,
            children: form,
        },
    })
}
