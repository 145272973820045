import React, { FC, useEffect, useState } from 'react'
import { InputText } from '@/admin/components/InputText'
import { useFormik } from 'formik'
import { authenticateAdmin } from '@/admin/api/auth.api'
import { useUserState } from '@/admin/store/user'
import { UserActionType } from '@/admin/store/user/user'
import * as Yup from 'yup'
import logo from '@/admin/assets/images/logo.svg'
import Button from '@/admin/components/Button'
import { Navigate } from 'react-router-dom'
import { showError } from '@/admin/parts/Modals/InfoModal'
import { useVersions } from '@/admin/hooks/useVersions'
import './LoginPage.scss'

const LoginSchema = Yup.object().shape({
    type: Yup.string(),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

const CLASS_NAME = 'a-login-page'

export const LoginPage: FC = () => {
    const { uiVersion, apiVersion } = useVersions()
    const { state, dispatch } = useUserState()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async ({ email, password }) => {
            try {
                const response = await authenticateAdmin(email, password)
                formik.setSubmitting(false)
                // eslint-disable-next-line no-console
                dispatch!({ type: UserActionType.SET_LOGGED_STATE, user: response.data })
            } catch (e: any) {
                formik.setSubmitting(false)

                if (e) {
                    showError(e)
                }
            }
        },
        validationSchema: LoginSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
    })

    if (state.isLoggedIn) {
        return <Navigate to={'/admin/app'} />
    }
    return (
        <>
            <div className={CLASS_NAME}>
                <form className={`${CLASS_NAME}__form`} onSubmit={formik.handleSubmit}>
                    <img src={logo} alt="OLIMP Warehousing" width={50} />
                    <InputText
                        autoFocus
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        text
                        disabled={formik.isSubmitting}
                        textError
                        errorHide={false}
                        error={formik.errors.email}
                        label="E-Mail"
                    />
                    <InputText
                        name="password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        textError
                        errorHide={false}
                        error={formik.errors.password}
                        text
                        label="Password"
                    />
                    <Button types={['blue']} type={'submit'} loading={formik.isSubmitting}>
                        Login
                    </Button>
                </form>

                <div className="app-version">
                    <div className="app-version__item">
                        <span>ADMIN UI Version:</span>
                        <span>{uiVersion}</span>
                    </div>
                    <div className="app-version__item">
                        <span>API V2 Version:</span>
                        <span>{apiVersion}</span>
                    </div>
                </div>
            </div>
        </>
    )
}
