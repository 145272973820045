import React, { FC } from 'react'
import { useRecordContext } from 'react-admin'
import { statusData, StatusType } from '@/admin/model/status'
import { Chip, Theme } from '@mui/material'
import { SxProps } from '@mui/system'

const getLabelObject = (obj: any, path: string) => {
    const parts = path.split('.')
    let value = obj
    for (let i = 0; i < parts.length; i++) {
        value = value ? value[parts[i]] : undefined
        if (value === undefined) {
            return undefined
        }
    }
    return value
}
export const StatusField: FC<{
    source: string
    label?: string
    sx?: SxProps<Theme>
    description?: boolean
    statusType?: StatusType
}> = ({ source, description, statusType = StatusType.order, label, sx }) => {
    const record = useRecordContext()
    const { title, color, description: statusDesc } = statusData(statusType, getLabelObject(record, source))
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 11, color: 'rgba(0,0,0,0.5)' }}>{description && statusDesc}</span>
            <Chip
                label={title}
                sx={{
                    width: description ? 200 : 120,
                    height: 28,
                    fontSize: 12,
                    fontWeight: 600,
                    color: color ? color : '#9f9f9f',
                    fontFamily: 'UniNeue',
                    backgroundColor: 'white',
                    border: '1px solid rgba(0,0,0, 0.1)',
                    ...(sx || {}),
                }}
            />
        </div>
    )
}
