import {
    BulkDeleteButton,
    Button,
    Confirm,
    CreateBase,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    Edit,
    Labeled,
    List,
    NumberField,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Title,
    Toolbar,
    useEditContext,
    useNotify,
    useRefresh,
} from 'react-admin'
import React, { FC, useState } from 'react'
import { Card, Chip, Grid, InputAdornment, LinearProgress, Stack, Typography } from '@mui/material'
import { statusData, StatusType } from '@/admin/model/status'
import { SendOutButton } from '@/admin/resources/invoice/send-out'
import { HttpV2 } from '@shared/apiv2/api'
import { OrderInvoiceSmall } from '@/admin/resources/order/order-invoice'
import { DataCard } from '@/admin/components/datacard'

const moneyInputProps = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
}

export const InvoiceViewLayout = () => {
    const { record, isLoading, refetch } = useEditContext()
    const paymentResource = `invoices/${record.id}/payments`
    const { totalPaid, total, status } = record
    const { description, value, title } = statusData(StatusType.invoice, status)

    const refresh = useRefresh()
    const label = record.invoiceType === 'w' ? 'Vendor' : 'Customer'
    const leftToPay = total - totalPaid
    const statusChip = (
        <Chip
            sx={{
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0,0.1)',
                fontSize: 24,
                padding: '12px',
                height: 64,
                mb: 4,
            }}
            label={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography fontSize={18} color="#519cec">
                        {title}
                    </Typography>
                    <Typography fontSize={12}>{description ? '(' + description + ')' : ''}</Typography>
                </div>
            }
        />
    )
    const entity = record.order[record.invoiceType === 'c' ? 'carrier' : 'warehouse'].entity
    const companyEntity = record.order[record.invoiceType === 'c' ? 'carrier' : 'warehouse'].company.entity
    const targetLabel = record.invoiceType === 'c' ? 'Customer' : 'Vendor'
    return (
        <>
            <Title title={`\xa0/ ${label}`} />
            <Grid container>
                <Grid container md={6} xs={12} spacing={2} sx={{ mr: 2 }} alignContent="flex-start">
                    <Grid item xs={12}>
                        <DataCard
                            records={[]}
                            content={
                                <SimpleForm
                                    toolbar={
                                        <>
                                            <SaveButton sx={{ mr: 2 }} />
                                            <SendOutButton />
                                        </>
                                    }
                                >
                                    <a href={`/admin/app/orders/${record.order.id}/show/invoices`}>
                                        Go to order #{record.order.id}
                                    </a>
                                    <br />
                                    <DataCard
                                        records={[
                                            { title: `${targetLabel} entity: `, value: entity },
                                            { title: `${targetLabel} company entity: `, value: companyEntity },
                                            {
                                                title: `${targetLabel} invoice status: `,
                                                value: (
                                                    <OrderInvoiceSmall
                                                        orderId={record.order.id}
                                                        invoice={record}
                                                        invoiceType={record.invoiceType}
                                                    />
                                                ),
                                            },
                                        ]}
                                        style={{ width: 400, paddingBottom: 8 }}
                                    />
                                    <br />
                                    <TextInput type="number" source="id" label="Invoice ID" disabled />
                                    <NumberInput type="number" source="total" InputProps={moneyInputProps} />
                                    <DateInput source="invoiceDate" />
                                    <DateInput source="dueDate" />
                                    <TextInput source="note" multiline rows={5} sx={{ width: 400 }} />
                                </SimpleForm>
                            }
                            title={`Edit ${label} invoice`}
                        />
                    </Grid>
                </Grid>
                <Grid container md={6} xs={12} spacing={2} sx={{ mr: 2 }} alignContent="flex-start">
                    <Grid item xs={12}>
                        <DataCard
                            records={[]}
                            content={
                                <>
                                    <Stack spacing={2} direction="row">
                                        <InvoicePaymentCreate
                                            resource={paymentResource}
                                            id={record.id}
                                            total={record.total}
                                            totalPaid={record.totalPaid}
                                            invoiceType={record.invoiceType}
                                        />
                                        <div
                                            style={{
                                                width: '100%',
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                height: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {statusChip}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}
                                            >
                                                <Labeled justifyContent="center" alignItems="center" display="flex">
                                                    <NumberField
                                                        locales="en-US"
                                                        options={{ style: 'currency', currency: 'USD' }}
                                                        source="totalPaid"
                                                        label="$ Paid"
                                                        sx={{ fontSize: 18 }}
                                                    />
                                                </Labeled>
                                                <Labeled
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    display="flex"
                                                    label="$ Left"
                                                >
                                                    <Typography sx={{ fontSize: 18 }}>
                                                        ${leftToPay.toFixed(2)}
                                                    </Typography>
                                                </Labeled>
                                                <Labeled justifyContent="flex-end" alignItems="flex-end" display="flex">
                                                    <NumberField
                                                        label="$ Total"
                                                        locales="en-US"
                                                        options={{ style: 'currency', currency: 'USD' }}
                                                        source="total"
                                                        sx={{ fontSize: 18 }}
                                                    />
                                                </Labeled>
                                            </div>
                                            <LinearProgress
                                                sx={{ mt: 1.2, width: '100%' }}
                                                variant="determinate"
                                                value={(totalPaid / total) * 100}
                                            />
                                        </div>
                                    </Stack>

                                    <List title=" " pagination={false} exporter={false} resource={paymentResource}>
                                        <Datagrid
                                            bulkActionButtons={<PaymentsBulkActions resource={paymentResource} />}
                                        >
                                            <NumberField
                                                source="total"
                                                locales="en-US"
                                                options={{ style: 'currency', currency: 'USD' }}
                                            />
                                            <DateField source="paymentDate" />
                                            <DeleteButton
                                                mutationMode="optimistic"
                                                redirect={(...args: any[]) => {
                                                    refresh()
                                                    return paymentResource
                                                }}
                                            />
                                        </Datagrid>
                                    </List>
                                </>
                            }
                            title="Payments"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export const PaymentsBulkActions: FC<{ resource: string }> = ({ resource }) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const onSuccess = () => {
        notify('Payments were removed')
        refresh()
    }
    return (
        <>
            <BulkDeleteButton resource={resource} mutationMode="undoable" mutationOptions={{ onSuccess }} />
        </>
    )
}

export const InvoicePaymentCreate: FC<{
    resource: string
    id: number
    total: number
    totalPaid: number
    invoiceType: string
}> = ({ resource, id, total, totalPaid, invoiceType }) => {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [confirmationPhrase, setConfirmationPhrase] = useState<string>()
    const [values, setValues] = useState<any>()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()
    return (
        <CreateBase resource={resource}>
            <div>
                <Card sx={{ width: 300 }}>
                    <Typography variant="h6" component="h6" sx={{ pt: 1, ml: 2 }}>
                        New payment
                    </Typography>
                    <SimpleForm
                        onSubmit={(v, event) => {
                            setConfirmOpen(true)
                            setValues(v)
                            let phrase = 'Are you sure you want to pay this invoice?'
                            if (totalPaid < total && Number(v.total) + totalPaid >= total) {
                                phrase =
                                    'With this payment invoice will be fully paid and order will change status to ' +
                                    (invoiceType === 'w' ? 'CLOSED' : 'COMPLETED')
                            }
                            setConfirmationPhrase(phrase)
                        }}
                        toolbar={
                            <Toolbar>
                                <Confirm
                                    isOpen={confirmOpen}
                                    loading={confirmLoading}
                                    title="Pay for invoice"
                                    content={confirmationPhrase}
                                    onConfirm={async () => {
                                        setConfirmLoading(true)
                                        if (values) {
                                            try {
                                                await HttpV2.post(`/admin/invoices/${id}/payments`, values)
                                                await refresh()
                                                notify('Payment has been done')
                                            } catch (e: any) {
                                                notify(e.message, { type: 'error' })
                                            }
                                        }
                                        setConfirmLoading(false)
                                        setConfirmOpen(false)
                                    }}
                                    onClose={() => {
                                        setConfirmOpen(false)
                                    }}
                                />
                                <Button type="submit" variant="outlined" label="Pay" />
                            </Toolbar>
                        }
                    >
                        <TextInput type="number" source="total" required InputProps={moneyInputProps} />
                        <DateInput source="paymentDate" required defaultValue={new Date().toDateString()} />
                    </SimpleForm>
                </Card>
            </div>
        </CreateBase>
    )
}

export const InvoiceView = () => {
    return (
        <Edit redirect={false}>
            <InvoiceViewLayout />
        </Edit>
    )
}
