import React from 'react'
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    NumberInput,
    BooleanField,
} from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { LongField } from '@/admin/fields/common'

export const WarehouseList = () => {
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            disableSyncWithLocation
            storeKey={'WarehousesList'}
            perPage={25}
            filters={[
                <NumberInput key="id" label="ID" source="id" type="number" parse={(v) => parseInt(v, 10)} alwaysOn />,
                <TextInput key="entity" label="Entity" source="entity_ilike" alwaysOn />,
                <TextInput key="company.entity" label="Company" source="company.entity_ilike" alwaysOn />,
                <TextInput key="physicalAddress" label="Address" source="physicalAddress_ilike" alwaysOn />,
                <SelectInput
                    alwaysOn
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />,
                <SelectInput
                    alwaysOn
                    key="autoBooking"
                    label="Auto Booking"
                    source="autoBooking"
                    choices={[
                        {
                            id: 'FALSE',
                            name: 'FALSE',
                        },
                        {
                            id: 'TRUE',
                            name: 'TRUE',
                        },
                    ]}
                />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" label="ID" sortable={false} />
                    <TextField source="entity" label="Entity" width={100} />
                    <TextField sortBy="company.entity" source="company_entity" label="Company" width={100} />
                    <LongField
                        source="physicalAddress"
                        label="Address"
                        sortBy="physicalAddress"
                        width={450}
                        sortable={false}
                    />
                    <StatusField source="status" statusType={StatusType.entity} label="Warehouse Status" />
                    <BooleanField source="autoBooking" label="Auto Booking" width={100} />
                    <DateField source="lastPriceUpdate" label="Last updated price" showTime />
                    <DateField source="createdAt" showTime />
                    <DateField source="updatedAt" showTime />
                    <NumberField source="changedBy" label="Changed by" sortable={false} />
                </Datagrid>
            </>
        </List>
    )
}
