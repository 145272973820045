import React, { useState } from 'react'
import {
    TabbedForm,
    useShowContext,
    Button,
    Datagrid,
    BooleanField,
    TextField,
    List,
    useRecordContext,
    DeleteWithConfirmButton,
    useRefresh,
} from 'react-admin'

import { StatusField } from '@/admin/resources/order/order-status'
import { StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { Loader } from '@/admin/components/Loader'
import { showEditor } from './warehouse-serivce-editor'
import { showAddServiceDocumentModal } from './warehouse-serivce-document-editor'
import { pricingTypes, serviceTypes } from '@/admin/model/dictionaries'
import { WarehouseServiceIcon } from '@/admin/resources/warehouse/warehouse-service-icon'
import { HttpV2 } from '@shared/apiv2/api'
import { showError } from '@/admin/parts/Modals/InfoModal'

// eslint-disable-next-line complexity
export const WarehouseServiceView = function () {
    const refresh = useRefresh()
    const [iconLoading, setIconLoading] = useState(false)
    const { record, isLoading, isFetching } = useShowContext()

    const cardTitle = (title: string, item: any) => {
        return (
            <>
                {title}{' '}
                <Button
                    type="button"
                    onClick={() => showEditor('warehouse-services', item.id)}
                    color="primary"
                    label="Edit"
                />
            </>
        )
    }

    const serviceTypesObject: { [key: number]: string } = {}
    const pricingTypesObject: { [key: number]: string } = {}

    serviceTypes.forEach((item) => (serviceTypesObject[item.id] = item.name))
    pricingTypes.forEach((item) => (pricingTypesObject[item.id] = item.name))

    const DeleteButton = () => {
        const item = useRecordContext()
        return (
            <DeleteWithConfirmButton
                title="Delete document?"
                confirmContent="Are you sure?"
                redirect={`admin/app/warehouse-services/${item.id}/show/1`}
            />
        )
    }

    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showAddServiceDocumentModal('warehouse-services/documents/' + record.code, item.id, refresh)
                }}
                label="Edit"
            />
        )
    }

    const EmptyMessageList = () => <div>No documents found</div>

    const handleServiceIconUpload = async (files: FileList) => {
        const formData = new FormData()
        formData.append('file', files[0])
        setIconLoading(true)
        try {
            await HttpV2.put(`/admin/warehouse-services/${record.id}/icon`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (e) {
            showError(e)
        }
        setIconLoading(false)
        refresh()
    }

    const serviceIcon = (
        <WarehouseServiceIcon icon={record?.icon ?? ''} onUpload={handleServiceIconUpload} loading={iconLoading} />
    )
    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="Warehouse Service">
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} md={5} lg={5}>
                        {record ? (
                            <DataCard
                                records={[
                                    { title: 'Title', value: record.title },
                                    { title: 'Notes', value: record.notes },
                                    { title: 'Group', value: record.group },
                                    {
                                        title: 'Icon',
                                        value: serviceIcon,
                                    },
                                    { title: 'Type', value: serviceTypesObject[record.type] },
                                    { title: 'Price Type', value: pricingTypesObject[record.priceType] },
                                    {
                                        title: 'Status',
                                        value: <StatusField source="status" statusType={StatusType.entity} />,
                                    },
                                    { title: 'Unity', value: record.unity ? record.unity.name : '(not set)' },
                                    { title: 'Terms related', value: record.termsRelated ? 'Yes' : 'No' },
                                    { title: 'Required', value: record.required ? 'Yes' : 'No' },
                                    { title: 'Quotation request', value: record.isQr ? 'Yes' : 'No' },
                                    { title: 'Capacity (Plt)', value: record.capacity ? record.capacity : '(not set)' },
                                    { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                    { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                ]}
                                title={cardTitle('Warehouse Service', record)}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Service document requirements">
                <Button
                    type="button"
                    onClick={() =>
                        showAddServiceDocumentModal('warehouse-services/documents/' + record.code, undefined, refresh)
                    }
                    color="primary"
                    label="Add Document"
                />
                {record ? (
                    <List
                        perPage={25}
                        resource={'warehouse-services/documents'}
                        filter={{
                            warehouseSvcTypeCode: record.code,
                        }}
                        empty={<EmptyMessageList />}
                    >
                        <>
                            <Datagrid bulkActionButtons={false} rowClick={() => false}>
                                <TextField source="name" />
                                <TextField source="description" />
                                <BooleanField source="confirm" />
                                <BooleanField source="expired" />
                                <BooleanField source="required" />
                                <TextField source="description" />
                                <TextField source="fileMask" label="File extensions" />
                                <TextField source="maxFileSize" label="Max file size (Kb)" />
                                <EditButton />
                                <DeleteButton />
                            </Datagrid>
                        </>
                    </List>
                ) : null}
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
