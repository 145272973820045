import {
    Datagrid,
    DateField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
    BooleanField,
    NumberInput,
    Button as ButtonAdmin,
    useRefresh,
    DeleteWithConfirmButton,
    FunctionField,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { booleanList, settingTypeList } from '@/admin/model/dictionaries'
import { showEditor } from '@/admin/resources/carrier/carrier-setting-editor'
import NavigateToField from '@/admin/components/NavigateToField'
import { Loader } from '@/admin/components/Loader'

export const SettingsCarrier = () => {
    const refresh = useRefresh()
    const record = useRecordContext()

    const data = {
        carrierId: record?.id,
    }

    const EditButton = () => {
        const item = useRecordContext()
        return (
            <ButtonAdmin
                type="button"
                onClick={(e) => {
                    e.stopPropagation()
                    showEditor('carrier-settings', refresh, item.id, item)
                }}
                label="Edit"
            />
        )
    }

    if (!record?.id) {
        return <Loader />
    }

    return (
        <>
            <ButtonAdmin
                type="button"
                onClick={() => showEditor('carrier-settings', refresh, undefined, data)}
                label="Create Setting"
            />
            <List
                disableSyncWithLocation
                storeKey={'carrierSettingsList'}
                sort={{ field: 'createdAt', order: 'ASC' }}
                perPage={25}
                resource={'carrier-settings'}
                filters={[
                    <NumberInput key="id" label="ID" source="id" alwaysOn />,
                    <TextInput key="group" label="Group" source="settingType.group_ilike" alwaysOn />,
                    <TextInput key="id_name" label="ID Name" source="settingType.idName_ilike" alwaysOn />,
                    <TextInput key="name" label="Name" source="settingType.name_ilike" alwaysOn />,
                    <TextInput key="value" label="Value" source="value" alwaysOn />,
                    <SelectInput
                        key="required"
                        source="settingType.required"
                        choices={booleanList.map((item: any) => ({ id: item.value, name: item.name }))}
                        alwaysOn
                    />,
                    <SelectInput
                        key="status"
                        label="Status"
                        source="status"
                        choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                            id: item.value,
                            name: item.title,
                        }))}
                        alwaysOn
                    />,
                ]}
                filter={{
                    carrierId: record?.id,
                }}
            >
                <>
                    <Datagrid bulkActionButtons={false} rowClick={() => false}>
                        <NumberField source="id" label="ID" />
                        <TextField source="settingType.group" label="Group" />
                        <TextField source="settingType.idName" label="Id Name" />
                        <TextField source="settingType.name" label="Name" />
                        <FunctionField
                            label="Type"
                            source="settingType.type"
                            render={(r: any) =>
                                r?.settingType?.type
                                    ? settingTypeList
                                          .filter((item) => r.settingType.type === item.id)
                                          .map((item: any) => item.name)[0]
                                          .toUpperCase()
                                    : '(not set)'
                            }
                        />
                        <TextField source="value" />
                        <TextField source="settingType.fieldSettings" label="Field Settings" />
                        <BooleanField source="settingType.required" label="Required" />
                        <BooleanField source="settingType.isCommon" label="Common" />
                        <StatusField source="status" statusType={StatusType.entity} />
                        <DateField source="createdAt" showTime />
                        <DateField source="updatedAt" showTime />
                        <NavigateToField
                            label="Created By"
                            key="createdByAdmin"
                            link={'/admin/app/admin/:id/show'}
                            id="createdBy"
                        >
                            <TextField source="createdByAdmin.email" label="Created By" sortable={false} />
                        </NavigateToField>
                        <NavigateToField
                            label="Updated By"
                            key="createdByAdmin"
                            link={'/admin/app/admin/:id/show'}
                            id="updatedBy"
                        >
                            <TextField source="updatedByAdmin.email" label="Updated By" sortable={false} />
                        </NavigateToField>
                        <EditButton />
                        <DeleteWithConfirmButton
                            title="Delete setting type"
                            confirmContent="Are you sure?"
                            redirect={'/admin/app/carriers/1'}
                        />
                    </Datagrid>
                </>
            </List>
        </>
    )
}
