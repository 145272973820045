import React, { FC } from 'react';
import { Button } from '@mui/material'
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';

interface NavigateToFieldProps {
    label: string;
    link: string;
    children: React.ReactNode;
    id: string;
}

const NavigateToField: FC<NavigateToFieldProps> = ({ link, children, id }) => {
    const record = useRecordContext();
    const navigate = useNavigate();

    const navigateTo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(link.replace(':id', record[id]));
    };

    return (
        <Button size="small" color="primary" onClick={navigateTo}>
            {children}
        </Button>
    );
};

export default NavigateToField
