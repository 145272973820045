import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { Grid } from '@mui/material'
import JsonInput from '@/admin/components/ui/JsonInput'

export const showEditor = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <JsonInput source="value" />
            </Grid>
        </Grid>
    )
    return showEditorModal({
        props: {
            title: recordId ? 'Edit System Environment' : 'Create System Environment',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
        },
    })
}
