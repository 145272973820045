import React from 'react'
import cn from 'classnames'
import './Loader.scss'

interface ILoader {
    type?: string
}

export const Loader: React.FC<ILoader> = ({ type }) => (
    <div className={cn('loader', type)}>
        <div className="spinner" />
    </div>
)
