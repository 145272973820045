import React from 'react'
import { Datagrid, List, NumberField, TextField, useRecordContext, Button } from 'react-admin'
import { showEditor } from './editor'

export const DayPriceMarkupList = () => {
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('day-price-markup', item.id)
                }}
                label="Edit"
            />
        )
    }

    return (
        <List perPage={25} filters={[]}>
            <>
                <Datagrid bulkActionButtons={false} rowClick={() => false}>
                    <NumberField source="id" label="#" sortable={false} width={50} />
                    <TextField source="day" />
                    <NumberField source="value" />
                    <EditButton />
                </Datagrid>
            </>
        </List>
    )
}
