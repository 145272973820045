import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, DateInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { Grid } from '@mui/material'

export const showEditorLicense = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="number" label="USDOT or MC/MX#" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.document].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <DateInput source="nextReviewAt" label="Next Review At" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Carrier License',
            resource: resource + '/license',
            recordId,
            children: form,
            model: (data) => {
                return {
                    id: data.id,
                    number: data.number,
                    status: data.status,
                    nextReviewAt: data.nextReviewAt,
                }
            },
            onSuccess,
        },
    })
}
