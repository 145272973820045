import React, { useEffect, useState } from 'react'
import {
    Datagrid,
    List,
    TextField,
    useRecordContext,
    Button,
    TextInput,
    useRefresh,
    TabbedForm,
    FunctionField,
    DateField,
    SelectInput,
    useNotify,
    DeleteWithConfirmButton,
} from 'react-admin'
import { showCreator } from './create'
import { showEditor } from './edit'
import { showTake } from './take'
import { Grid } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'
import './list.scss'
import { useAdminUser } from '@/admin/api/admin.api'

export const MessagesSupportList = () => {
    const [supportCategories, setSupportCategories] = useState([])
    const notify = useNotify()
    const getSupportCategories = async () => {
        try {
            const categories = await HttpV2.get('/admin/category-support', {
                params: {
                    filter: {},
                    range: '[0, 1000]',
                },
            })
            setSupportCategories(categories.data)
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
    }
    useEffect(() => {
        getSupportCategories()
    })

    const refresh = useRefresh()
    const ActionButtons = () => {
        const item = useRecordContext()
        const user = useAdminUser(true)
        const buttons = []
        if (canTake(item) || canReopen(item, user.data.id)) {
            buttons.push(
                <Button
                    key="take"
                    type="button"
                    onClick={(e: any) => {
                        e.stopPropagation()
                        showTake('messages-support', supportCategories, item, user.data.id, refresh)
                    }}
                    label="Take"
                />,
            )
        }
        if (canClose(item, user.data.id)) {
            buttons.push(
                <Button
                    key="edit"
                    type="button"
                    onClick={(e: any) => {
                        e.stopPropagation()
                        showEditor('messages-support', supportCategories, item, refresh)
                    }}
                    label="Edit"
                />,
            )
            buttons.push(
                <DeleteWithConfirmButton
                    label="Close"
                    title="Close Support Message"
                    confirmTitle="Close Support Message"
                    confirmContent="Are you sure?"
                    redirect={'/admin/app/messages-support/1'}
                />,
            )
        }
        return <div>{buttons}</div>
    }

    /**
     * Check take able
     */
    function canTake(item: any) {
        return !(item.adminId || item.solvedAt) //если никто не взял в работу и не решил до - разрешаем брать в работу
    }

    /**
     * Check reopen able for
     */
    function canReopen(item: any, adminID: number) {
        return item.adminId === adminID && item.solvedAt //если взял в работу и завершил - разрешаем открывать повторно
    }

    /**
     * Check close able for
     */
    function canClose(item: any, adminID: number) {
        return item.adminId === adminID && !item.solvedAt //если текущий взял в работу и еще не завершил - разрешаем завершать
    }

    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="New">
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <List
                            perPage={25}
                            resource="messages-support/new/list"
                            disableSyncWithLocation
                            storeKey="messages-support/new"
                            filters={[
                                <TextInput key="id" source="id" label="Trouble ticket #" alwaysOn />,
                                <TextInput key="message" label="Message" source="message_ilike" alwaysOn />,
                                <TextInput key="name" label="Name" source="name_ilike" alwaysOn />,
                                <TextInput key="email" label="Email" source="email_ilike" alwaysOn />,
                                <TextInput key="phone" label="Phone" source="phone_ilike" alwaysOn />,
                            ]}
                            actions={
                                <Button
                                    type="button"
                                    onClick={(e: any) => {
                                        e.stopPropagation()
                                        showCreator('messages-support', supportCategories, undefined, refresh)
                                    }}
                                    label="Create"
                                />
                            }
                            className="support-message-table"
                        >
                            <>
                                <Datagrid bulkActionButtons={false} rowClick={() => false}>
                                    <FunctionField
                                        label="	Trouble ticket #"
                                        render={(record: any) => record.id.toString().padStart(6, '0')}
                                        sortable={false}
                                    />
                                    <DateField source="createdAt" />
                                    <TextField source="message" />
                                    <TextField source="name" />
                                    <TextField source="email" />
                                    <TextField source="phone" />
                                    <ActionButtons />
                                </Datagrid>
                            </>
                        </List>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Active">
                <List
                    perPage={25}
                    resource="messages-support/active/list"
                    disableSyncWithLocation
                    storeKey="messages-support/active"
                    filters={[
                        <TextInput key="id" source="id" label="Trouble ticket #" alwaysOn />,
                        <SelectInput
                            key="category"
                            label="Category"
                            source="category"
                            choices={supportCategories.map((item: any) => ({
                                id: item.id,
                                name: item.title,
                            }))}
                            alwaysOn
                        />,
                        <TextInput key="message" label="Message" source="message_ilike" alwaysOn />,
                        <TextInput key="name" label="Name" source="name_ilike" alwaysOn />,
                        <TextInput key="email" label="Email" source="email_ilike" alwaysOn />,
                        <TextInput key="phone" label="Phone" source="phone_ilike" alwaysOn />,
                    ]}
                    className="support-message-table"
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <FunctionField
                                label="	Trouble ticket #"
                                render={(record: any) => record.id.toString().padStart(6, '0')}
                                sortable={false}
                            />
                            <DateField source="createdAt" />
                            <TextField source="category.title" label="Category" />
                            <TextField source="message" />
                            <TextField source="name" />
                            <TextField source="email" />
                            <TextField source="phone" />
                            <ActionButtons />
                        </Datagrid>
                    </>
                </List>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Archive">
                <List
                    perPage={25}
                    resource="messages-support/archive/list"
                    disableSyncWithLocation
                    storeKey="messages-support/archive"
                    filters={[
                        <TextInput key="id" source="id" label="Trouble ticket #" alwaysOn />,
                        <SelectInput
                            key="category"
                            label="Category"
                            source="category"
                            choices={supportCategories.map((item: any) => ({
                                id: item.id,
                                name: item.title,
                            }))}
                            alwaysOn
                        />,
                        <TextInput key="message" label="Message" source="message_ilike" alwaysOn />,
                        <TextInput key="name" label="Name" source="name_ilike" alwaysOn />,
                        <TextInput key="email" label="Email" source="email_ilike" alwaysOn />,
                        <TextInput key="phone" label="Phone" source="phone_ilike" alwaysOn />,
                    ]}
                    className="support-message-table"
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <FunctionField
                                label="	Trouble ticket #"
                                render={(record: any) => record.id.toString().padStart(6, '0')}
                                sortable={false}
                            />
                            <DateField source="createdAt" />
                            <DateField source="solvedAt" />
                            <TextField source="category.title" label="Category" />
                            <TextField source="message" />
                            <TextField source="name" />
                            <TextField source="email" />
                            <TextField source="phone" />
                            <ActionButtons />
                        </Datagrid>
                    </>
                </List>
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
