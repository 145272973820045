import React, { FC, memo } from 'react'
import { setToken } from '@/admin/api/auth.api'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppContainer } from '@/admin/AppContainer'
import { LoginPage } from './pages/login/LoginPage'
import { UserProvider } from '@/admin/store/user'
import { Helmet } from 'react-helmet'
import GeneralModal from '@/admin/parts/GeneralModal'

const initialToken = localStorage.getItem('admin_token')

if (initialToken) {
    setToken(initialToken)
}

const AdminApp: FC = () => {
    return (
        <UserProvider>
            <Helmet title="OLIMP Admin">
                <link rel="shortcut icon" type="image/png" href="/static/admin/favicon.png?23333" />
                <link rel="apple-touch-icon" sizes="76x76" href="/static/admin/apple-touch-icon-76x76.png?3333" />
                <link rel="apple-touch-icon" sizes="120x120" href="/static/admin/apple-touch-icon-120x120.png?3333" />
                <link rel="apple-touch-icon" sizes="152x152" href="/static/admin/apple-touch-icon-152x152.png?3333" />
                <link rel="apple-touch-icon" sizes="180x180" href="/static/admin/apple-touch-icon.png?3333" />
                <link
                    id="favicon-32"
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/static/admin/favicon-32x32.png?3333"
                />
                <link
                    id="favicon-16"
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/static/admin/favicon-16x16.png?3333"
                />
            </Helmet>
            <div className="admin-app">
                <Routes>
                    <Route path="/" element={<Navigate to="/admin/app" />} />
                    <Route path="/app/*" element={<AppContainer />} />
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </div>
        </UserProvider>
    )
}

export default memo(AdminApp)
