import { showInfoModal } from '@/admin/parts/Modals/InfoModal'
import { DataCard } from '@/admin/components/datacard'
import React from 'react'
import { Grid } from '@mui/material'

export const showCalculation = (record: any) =>
    showInfoModal({
        size: 'large',
        props: {
            title: `Cost Breakdown for order #${record.id}`,
            message: (
                <div>
                    <br />
                    <Grid container alignContent="flex-start" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <DataCard
                                records={[
                                    {
                                        title: 'Services',
                                        value:
                                            '$' +
                                            record.services
                                                .reduce((curr: number, el: any) => curr + el.price, 0)
                                                .toFixed(2),
                                    },
                                    {
                                        title: 'Adjustments',
                                        value: '$' + record.pricing.adjustmentsTotal.toFixed(2),
                                    },

                                    {
                                        title: 'Min Price',
                                        value: `${record.usedMinPrice ? `Yes, $${record.minPrice.toFixed(2)}` : 'No'}`,
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    { title: 'Total', value: '$' + record.pricing.total.toFixed(2) },
                                ]}
                                title="Warehouse Service Cost"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DataCard
                                title="Marginality"
                                records={[
                                    {
                                        title: 'We transfer to warehouse',
                                        value: 'We get from carrier',
                                    },
                                    {
                                        title: '- $' + record.pricing.warehouseTotal.toFixed(2),
                                        value: '+ $' + record.pricing.fullPrice.toFixed(2),
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        title: 'OLIMP gives to Stripe',
                                        value:
                                            '$' +
                                            (record.pricing.stripeFeeTotal + record.pricing.warehouseStripeFee).toFixed(
                                                2,
                                            ),
                                    },
                                    {
                                        title: 'OLIMP gets',
                                        value: '$' + record.pricing.margin.toFixed(2),
                                    },
                                    {
                                        title: 'Margin %',
                                        value: record.pricing.marginPercentage + '%',
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DataCard
                                records={[
                                    {
                                        title: 'WH Services Markup',
                                        value: '$' + record.pricing.markupFee.toFixed(2),
                                    },
                                    {
                                        title: 'WH Adjustments Markup',
                                        value: '$' + record.pricing.adjustmentsMarkupFees.toFixed(2),
                                    },
                                    {
                                        title: 'WH Services Fee',
                                        value: '$' + record.pricing.serviceFee.toFixed(2),
                                    },
                                    {
                                        title: 'WH Adjustments Fee',
                                        value: '$' + record.pricing.adjustmentsServiceFees.toFixed(2),
                                    },
                                    {
                                        title: 'Public Booking Fee',
                                        value: '$' + record.pricing.publicFee.toFixed(2),
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        title: 'Total',
                                        value: '$' + record.pricing.margin.toFixed(2),
                                    },
                                ]}
                                title="OLIMP fees"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DataCard
                                records={[
                                    {
                                        title: 'Carrier',
                                        value: '$' + record.pricing.stripeFeeTotal.toFixed(2),
                                    },
                                    {
                                        title: 'Warehouse',
                                        value: '$' + record.pricing.warehouseStripeFee.toFixed(2),
                                    },
                                ]}
                                title="Stripe fees"
                            />
                        </Grid>
                    </Grid>
                </div>
            ),
        },
    })
