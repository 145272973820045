import React, { FC, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { statusData, StatusType } from '@/admin/model/status'
import { HttpV2 } from '@shared/apiv2/api'
import { useRefresh } from 'react-admin'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

interface OrderInvoiceSmallProps {
    invoiceType: 'w' | 'c'
    orderId: string | number
    invoice?: any
    listItem?: boolean
}

// eslint-disable-next-line complexity
export const OrderInvoiceSmall: FC<OrderInvoiceSmallProps> = ({ orderId, invoice, invoiceType, listItem }) => {
    const navigate = useNavigate()
    const [sending, setSending] = useState(false)
    const refresh = useRefresh()
    const defaultNavigate = `/admin/app/orders/${orderId}/show`
    const invoiceNavigate = invoice && `/admin/app/invoices/${invoice.id}`
    const { title: status, color, progress } = (invoice && statusData(StatusType.invoice, invoice.status)) ?? {}

    const handleSend = async () => {
        if (!invoice) {
            setSending(true)
            const data = await HttpV2.post(`/admin/orders/${orderId}/invoice/${invoiceType}`)
            setSending(false)
            refresh()
        }
        navigate(invoiceNavigate ?? defaultNavigate)
    }

    const btnStyles: any = {}

    if (listItem) {
        btnStyles.display = 'flex'
        btnStyles.alignItems = 'center'
        btnStyles.justifyContent = 'flex-start'
        btnStyles.paddingTop = '10px'
        btnStyles.flexDirection = 'row'
    }

    return (
        <Button
            variant="text"
            size="small"
            disabled={sending}
            sx={{
                height: 44,
                display: 'flex',
                flexDirection: 'column',
                paddingTop: status ? '14px' : 3,
                paddingBottom: '12px',
                fontSize: status ? '14px' : '11px',
                ...btnStyles,
            }}
            onClick={(e) => {
                e.stopPropagation()
                handleSend()
            }}
        >
            <div
                style={{
                    color: 'black',
                    fontSize: listItem ? 11 : 9,
                    margin: !listItem ? '0 auto' : '',
                    fontWeight: 600,
                    bottom: listItem ? 0 : 28,
                    width: listItem ? 84 : 'auto',
                    textAlign: 'left',
                }}
            >
                {invoiceType === 'c' ? 'Customer' : 'Vendor'}
            </div>

            {status && listItem && (
                <span
                    style={{
                        fontSize: 10,
                        fontWeight: 600,
                        color: 'black',
                        marginLeft: 8,
                        display: 'flex',
                        flex: 1,
                        marginTop: -5,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    Paid ${invoice.totalPaid.toFixed(2)} of ${invoice.total.toFixed(2)}
                    <span style={{ fontSize: 8, color: 'gray', marginTop: -4 }}>
                        (Due date {dayjs(invoice.dueDate).fromNow()})
                    </span>
                </span>
            )}
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: listItem ? 'row-reverse' : 'column',
                }}
            >
                {status && (
                    <CircularProgress
                        size={18}
                        sx={{ color: color || 'black' }}
                        variant="determinate"
                        value={progress}
                    />
                )}
                {status && (
                    <span
                        style={{
                            fontSize: listItem ? 12 : 9,
                            color: color || 'black',
                            fontWeight: 600,
                            margin: !listItem ? '0 auto' : '0 8px 0 8px',
                            bottom: listItem ? 0 : progress === 0 ? 10 : 0,
                        }}
                    >
                        {status}
                    </span>
                )}
            </div>
            {!status && 'Create'}
        </Button>
    )
}
