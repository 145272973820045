import React from 'react'
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    NumberInput,
    WrapperField,
} from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { LongField } from '@/admin/fields/common'

export const CarrierList = () => {
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            filters={[
                <NumberInput
                    key="id"
                    label="Carrier ID"
                    source="id"
                    type="number"
                    parse={(v) => parseInt(v, 10)}
                    alwaysOn
                />,
                <TextInput key="entity" label="Entity" source="entity_ilike" alwaysOn />,
                <TextInput key="address" label="Physical Address" source="addresses.address_ilike" alwaysOn />,
                <TextInput key="licenses" label="USDOT or MC/MX##" source="licenses.number_ilike" alwaysOn />,
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" label="Carrier ID" sortable={false} />
                    <TextField source="entity" label="Entity" />
                    <WrapperField label="Company" sortBy="company.entity">
                        (<TextField source="company.id" />) <TextField source="company.entity" />
                    </WrapperField>
                    <LongField source="physicalAddress.address" label="Physical Address" sortable={false} />
                    <TextField source="licenses.number" label="USDOT or MC/MX##" sortable={false} />
                    <StatusField source="status" statusType={StatusType.entity} />
                    <DateField source="createdAt" showTime />
                    <TextField source="changedBy" label="Changed by ID" sortable={false} />
                </Datagrid>
            </>
        </List>
    )
}
