import React, { FC } from 'react'
import { Button, useShowContext, SimpleForm, EditBase, TextInput, DateInput, Toolbar } from 'react-admin'
import { TiptapEditorProvider } from 'ra-input-rich-text'
import { Loader } from '@/admin/components/Loader'
import { Grid } from '@mui/material'
import './tos-view.scss'
import { OlimpRichTextInput } from '@/admin/resources/tos/rte'
import { TermsOfServiceStatus } from '@/admin/model/enums'
import { ToolbarClasses, DeleteButton, SaveButton } from 'ra-ui-materialui'
import { TosPublishButton } from '@/admin/resources/tos/tos-publish'

export const TOS_VIEW_CLASSNAME = 'a-tos-view'

export const TOSView: FC = () => {
    const { record, isLoading, isFetching } = useShowContext()
    if (!record && (isLoading || isFetching)) {
        return <Loader />
    }

    const editable = record.status === TermsOfServiceStatus.DRAFT
    return (
        <div className={`${TOS_VIEW_CLASSNAME}__container`}>
            <div className={`${TOS_VIEW_CLASSNAME}__heading`}>
                <h3 className={`${TOS_VIEW_CLASSNAME}__title`}>
                    Terms of service {record ? `#${record.id}` : ' - Draft'}
                </h3>
            </div>
            <Grid container>
                <Grid container spacing={2} sx={{ mr: 2 }} alignContent="flex-start">
                    <Grid item xs={12} md={6}>
                        <EditBase>
                            <SimpleForm
                                toolbar={
                                    <Toolbar>
                                        <div className={ToolbarClasses.defaultToolbar}>
                                            <SaveButton />
                                            {editable && <TosPublishButton />}
                                            {editable && <DeleteButton />}
                                        </div>
                                    </Toolbar>
                                }
                            >
                                <TextInput
                                    source="message"
                                    label="Message shown to users, when accepting new Terms of service"
                                    disabled={!editable}
                                    fullWidth
                                />
                                <DateInput source="startDate" disabled={!editable} />
                                <OlimpRichTextInput
                                    source="content"
                                    className={`${TOS_VIEW_CLASSNAME}__editor`}
                                    disabled={!editable}
                                />
                                <TextInput source="notes" multiline rows={5} fullWidth disabled={!editable} />
                            </SimpleForm>
                        </EditBase>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
