import React, { FC, useState } from 'react'
import { Spinner } from '@react-pdf-viewer/core'
import { HttpV2 } from '@shared/apiv2/api'
import { useNotify } from 'react-admin'
import './warehouse-exports.scss'

export const WarehouseExports: FC = () => {
    const [loading, setLoading] = useState<string>()
    const notify = useNotify()
    const download = async (type: string) => {
        try {
            setLoading(type)
            const response = await HttpV2.get(`/admin/warehouses/export${type === 'wh' ? '' : '-contacts'}`)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `warehouses-${type}.csv`)
            document.body.appendChild(link)
            link.click()
        } catch (e: any) {
            notify(e.message)
        } finally {
            setLoading('')
        }
    }
    return (
        <div className="warehouse-exports">
            <h3 className="warehouse-exports__title">CSV Exports</h3>
            <div className="available-exports">
                <div className="available-exports__item">
                    <div className="available-exports__item__title">Warehouses</div>
                    <div className="available-exports__item__description">Export all warehouses to CSV file</div>
                    <div className="available-exports__item__button">
                        <button className="btn btn--primary" disabled={loading === 'wh'} onClick={() => download('wh')}>
                            {loading !== 'wh' ? 'Export Warehouses' : <Spinner size={'14px'} />}
                        </button>
                    </div>
                </div>
                <div className="available-exports__item">
                    <div className="available-exports__item__title">Contacts</div>
                    <div className="available-exports__item__description">
                        Export all warehouses contacts to CSV file
                    </div>
                    <div className="available-exports__item__button">
                        <button
                            className="btn btn--primary"
                            disabled={loading === 'contacts'}
                            onClick={() => download('contacts')}
                        >
                            {loading !== 'contacts' ? 'Export Contacts' : <Spinner size={'14px'} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
