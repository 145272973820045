import { ServiceTypes } from '@/admin/common/consts'
import { utils } from '@shared/common/utils'
import dayjs from 'dayjs'

export const createSearchLinkUsingOrder = (order: any) => {
    const { mainService, services, warehouse } = order
    const delivery = services.find((service: any) => service.type.type === ServiceTypes.DELIVERY)
    const orderFilters = {
        address: warehouse.physicalAddress.postalCode,
        delivery: delivery?.code ?? 0,
        isDaysRelatedLogic: services.some((service: any) => service.type.type === ServiceTypes.STORAGE),
        localDelivery: !!delivery,
        mode: 'list',
        offer: order.id,
        full_search: 'true',
        number_of_days: mainService.days,
        number_of_units: mainService.amount,
        start_date: dayjs(mainService.periodStart).format('YYYY-MM-DD'),
        service: mainService.code,
        unity: mainService.type.unityId,
        radius: 250,
    }
    return `/carrier/warehouses/${utils.encodeRouteFilters(orderFilters)}`
}
