import React, { FC, memo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/admin/components/Button'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { showError } from '@/admin/parts/Modals/InfoModal'
import { showUnableDeleteAccountModal } from '@/admin/parts/Modals/UnableDeleteAccountModal'
import { HttpV2 } from '@shared/apiv2/api'
import './DeleteAccountModal.scss'

interface DeleteAccountModalProps {
    id: number | string
}

const DeleteAccountModal: FC<DeleteAccountModalProps> = ({ id }) => {
    const [loading, setLoading] = useState<boolean>()

    const navigate = useNavigate()

    const deleteAccountHandler = async () => {
        setLoading(true)
        try {
            await HttpV2.delete(`/admin/managers/${id}`)
            setLoading(false)
            ModalRegistry.get().close(Modals.DeleteAccountModal)
            navigate('/admin/app/managers')
            toast('Manager was successfully archived', {
                type: 'success',
            })
        } catch (error) {
            ModalRegistry.get().close(Modals.DeleteAccountModal)
            showUnableDeleteAccountModal({
                props: {},
            })
        }
    }

    const CLASSNAME = 'deleteAccountModal'

    return (
        <div className={`${CLASSNAME}`}>
            <p className={`${CLASSNAME}__title`}>Are you sure you want to delete this account? You cannot undo this.</p>
            <div className={`${CLASSNAME}__btns-wrapper`}>
                <Button
                    className={`${CLASSNAME}__btn-cancel`}
                    types={['inverse']}
                    label="Cancel"
                    onClick={() => ModalRegistry.get().close(Modals.DeleteAccountModal)}
                />
                <Button types={['red']} label="Delete" onClick={deleteAccountHandler} loading={loading} />
            </div>
        </div>
    )
}

ModalRegistry.get().register<DeleteAccountModalProps>(Modals.DeleteAccountModal, {
    id: 'DeleteAccountModalProps',
    className: 'modal-delete-account',
    size: 'large',
    Component: memo(DeleteAccountModal),
    title: 'Confirm Account Deletion',
})

export const showDeleteAccountModal = (props: ModalPartialProps<DeleteAccountModalProps>): void =>
    ModalRegistry.get().show<DeleteAccountModalProps>(Modals.DeleteAccountModal, props)

export default memo(DeleteAccountModal)
