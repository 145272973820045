import { HttpV2 } from '@shared/apiv2/api'
import { useEffect, useState } from 'react'

export const useSFConnectivity = () => {
    const [sfConnectivity, setSFConnectivity] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        HttpV2.get('/salesforce/health')
            .then((response) => {
                setSFConnectivity(response.data)
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return { sfConnectivity, loading }
}
