import React, { FC, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { showDeleteAccountModal } from '@/admin/parts/Modals/DeleteAccountModal'
import { showUnableDeleteAccountModal } from '@/admin/parts/Modals/UnableDeleteAccountModal'

interface DeleteAccountButtonProps {
    managerId: number | string
}

const DeleteAccountButton: FC<DeleteAccountButtonProps> = ({ managerId }) => {
    const handleDelete = () => {
        showDeleteAccountModal({
            props: {
                id: managerId,
            },
        })
    }

    return (
        <Button size="small" color="error" onClick={handleDelete}>
            Archive account
        </Button>
    )
}

export default DeleteAccountButton
