import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { DateInput, SelectInput } from 'react-admin'

import { Grid } from '@mui/material'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import dayjs from 'dayjs'

export const showEditor = (recordId: string, onSuccess?: () => void, defaultValue?: any) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.document].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <DateInput key="expiredAt" label="Expired At" source="expiredAt" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Status Document',
            resource: 'warehouse-documents',
            recordId,
            children: form,
            defaultValue,
            onSuccess,
            model: (data: any) => {
                return {
                    ...data,
                    updatedAt: new Date(),
                }
            },
        },
    })
}
