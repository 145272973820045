import React from 'react'
import {
    TabbedForm,
    Datagrid,
    useShowContext,
    NumberField,
    TextField,
    WrapperField,
    DateField,
    List,
    Button,
    SelectInput,
    useRefresh,
} from 'react-admin'

import { StatusField } from '@/admin/resources/order/order-status'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { LongField } from '@/admin/fields/common'
import { Loader } from '@/admin/components/Loader'
import { showEditor } from './carrier-editor'
import { showEditorLicense } from './carrierLicense-editor'
import CarrierAddress, { IAddress } from './carrier-address'
import { OrdersListCarrier } from './carrier-orders'
import { SettingsCarrier } from './carrier-settings'

export const CarrierView = () => {
    const refresh = useRefresh()
    const { record, isLoading, isFetching } = useShowContext()

    type Tmodals = 'carrier' | 'license'

    const modals = {
        carrier: showEditor,
        license: showEditorLicense,
    }

    const cardTitle = (title: string, item: any, type: Tmodals) => {
        const modal = modals[type]
        return (
            <>
                {title}{' '}
                <Button
                    type="button"
                    onClick={() => modal('carriers', type === 'carrier' ? item.id : item.licenses?.id, refresh)}
                    color="primary"
                    label="Edit"
                />
            </>
        )
    }

    const ManagersEmpty = () => <div>No Managers found</div>

    const carrierAddress = [{ type: 3 }, { type: 1 }, { type: 5 }]

    record?.addresses.forEach((item: IAddress) => {
        switch (item.type) {
            case 1:
                carrierAddress[1] = item
                break
            case 5:
                carrierAddress[2] = item
                break
            case 3:
                carrierAddress[0] = item
                break
        }
    })

    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="Carrier">
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} md={6} lg={6}>
                        {!isLoading && !isFetching ? (
                            <DataCard
                                records={[
                                    { title: 'Entity', value: record.entity },
                                    { title: 'Discount %', value: record.discount },
                                    { title: 'Permission flags', value: record.taxId },
                                    {
                                        title: 'Status',
                                        value: <StatusField source="status" statusType={StatusType.entity} />,
                                    },
                                    { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                    { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                    { title: 'Changed by ID', value: record.changedBy },
                                ]}
                                title={cardTitle('Carrier', record, 'carrier')}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {!isLoading && !isFetching ? (
                            <DataCard
                                records={[
                                    { title: 'USDOT or MC/MX#', value: record?.licenses?.number },
                                    {
                                        title: 'Status',
                                        value: (
                                            <StatusField source="licenses.status" statusType={StatusType.document} />
                                        ),
                                    },
                                    {
                                        title: 'Next Review At',
                                        value: record?.licenses?.nextReviewAt
                                            ? dayjs(record?.licenses?.nextReviewAt).format('MM/DD/YYYY HH:mm')
                                            : '(not set)',
                                    },
                                    {
                                        title: 'Created At',
                                        value: dayjs(record?.licenses?.createdAt).format('MM/DD/YYYY HH:mm'),
                                    },
                                ]}
                                title={cardTitle('License', record, 'license')}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <DataCard
                            title={'Managers'}
                            records={[]}
                            content={
                                <List
                                    sort={{ field: 'createdAt', order: 'DESC' }}
                                    perPage={25}
                                    resource={'managers'}
                                    filter={{ permissions: { carrierId: record?.id } }}
                                    filters={[
                                        <SelectInput
                                            key="status"
                                            label="Status"
                                            source="status"
                                            choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                                                id: item.value,
                                                name: item.title,
                                            }))}
                                            alwaysOn
                                        />,
                                    ]}
                                >
                                    <Datagrid bulkActionButtons={false} rowClick="show" empty={<ManagersEmpty />}>
                                        <NumberField source="id" width={50} />
                                        <LongField source="email.email" label="Email" width={150} />
                                        <TextField source="phone.number" label="Phone" sortable={false} />
                                        <WrapperField label="Full name" sortBy="firstName">
                                            <TextField source="firstName" label="First Name" width={100} />
                                            &nbsp;
                                            <TextField source="lastName" label="Last Name" width={100} />
                                        </WrapperField>
                                        <LongField source="position" label="Position" width={100} sortable={false} />
                                        <TextField source="type" label="Type" sortable={false} />
                                        <DateField source="updatedAt" label="Changed at" showTime />
                                        <StatusField source="status" statusType={StatusType.entity} />
                                    </Datagrid>
                                </List>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Addresses">
                <CarrierAddress
                    record={record}
                    addresses={carrierAddress}
                    isFetching={isFetching}
                    isLoading={isLoading}
                />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Settings">
                <SettingsCarrier />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Orders">
                {record && record.id ? <OrdersListCarrier carrier={record} /> : null}
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
