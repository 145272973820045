import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, BooleanInput, NumberInput, required } from 'react-admin'
import { Grid } from '@mui/material'

export const showAddServiceDocumentModal = (resource: string, recordId?: number | string, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="name" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="description" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="fileMask" label="File extensions" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="maxFileSize" label="Max file size (Kb)" validate={required()} />
            </Grid>
            <Grid item xs={12} md={4}>
                <BooleanInput source="confirm" validate={required()} />
            </Grid>
            <Grid item xs={12} md={4}>
                <BooleanInput source="expired" validate={required()} />
            </Grid>
            <Grid item xs={12} md={4}>
                <BooleanInput source="required" validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Create document requirements' : 'Edit document requirements',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
        },
    })
}
