import {
    AlignmentButtons,
    ClearButtons,
    DefaultEditorOptions,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInput,
    RichTextInputToolbar,
    useTiptapEditor,
} from 'ra-input-rich-text'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Remove from '@mui/icons-material/Remove'
import { ToggleButton } from '@mui/material'
import React, { FC } from 'react'
import { Loader } from '@/admin/components/Loader'

const HorizontalRuleButton = () => {
    const editor = useTiptapEditor()

    return (
        <ToggleButton
            aria-label="Add an horizontal rule"
            title="Add an horizontal rule"
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            selected={editor && editor.isActive('horizontalRule')}
            value={'Val'}
        >
            <Remove fontSize="inherit" />
        </ToggleButton>
    )
}

export const OlimpRichTextInput: FC<any> = ({ size, ...props }) => {
    return (
        <RichTextInput
            editorOptions={OlimpEditorOptions}
            toolbar={
                <RichTextInputToolbar>
                    <LevelSelect size={size} />
                    <FormatButtons size={size} />
                    <AlignmentButtons size={size} />
                    <ListButtons size={size} />
                    <LinkButtons size={size} />
                    <QuoteButtons size={size} />
                    <ClearButtons size={size} />
                    <HorizontalRuleButton />
                </RichTextInputToolbar>
            }
            label="Body"
            source="body"
            {...props}
        />
    )
}

export const OlimpEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [...DefaultEditorOptions.extensions, HorizontalRule],
}
