import {
    Datagrid,
    DateField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
    BooleanField,
    FunctionField,
    DeleteWithConfirmButton,
    Button,
    useRefresh,
} from 'react-admin'
import React from 'react'
import { Grid } from '@mui/material'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { settingTypeList, booleanList, settingArea } from '@/admin/model/dictionaries'
import { showEditor } from './carrier-setting-type-editor'
import NavigateToField from '@/admin/components/NavigateToField'

type areaT = 1 | 2 | 3 | 4 | 5

const renderArea = (r: { area: areaT }) => (r.area && settingArea[r.area] ? settingArea[r.area] : '')

const EditButton = () => {
    const refresh = useRefresh()
    const item = useRecordContext()
    return (
        <Button
            type="button"
            onClick={(e: any) => {
                e.stopPropagation()
                showEditor('setting-type', item.id, refresh)
            }}
            label="Edit"
        />
    )
}

export const SettingsCarrier = () => {
    const refresh = useRefresh()
    return (
        <Grid container>
            <Button
                type="button"
                onClick={() => showEditor('setting-type', undefined, refresh)}
                label="Create Setting Type"
            />
            <List
                disableSyncWithLocation
                storeKey="settingType"
                sort={{ field: 'createdAt', order: 'ASC' }}
                perPage={25}
                resource={'setting-type'}
                filters={[
                    <TextInput key="id" label="ID" source="id" alwaysOn />,
                    <TextInput key="group" label="Group" source="group" alwaysOn />,
                    <TextInput key="idName" label="ID Name" source="idName_ilike" alwaysOn />,
                    <SelectInput
                        key="type"
                        source="type"
                        choices={settingTypeList.map((item: any) => ({ id: item.id, name: item.name }))}
                        alwaysOn
                    />,
                    <SelectInput
                        key="required"
                        source="required"
                        choices={booleanList.map((item: any) => ({ id: item.value, name: item.name }))}
                        alwaysOn
                    />,
                    <SelectInput
                        key="status"
                        label="Status"
                        source="status"
                        choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                            id: item.value,
                            name: item.title,
                        }))}
                        alwaysOn
                    />,
                ]}
            >
                <>
                    <Datagrid bulkActionButtons={false} rowClick={() => false}>
                        <NumberField source="id" label="ID" />
                        <TextField source="group" />
                        <TextField source="name" />
                        <TextField source="idName" />
                        <FunctionField label="Area" render={renderArea} />
                        <TextField source="fieldSettings" />
                        <BooleanField source="required" />
                        <BooleanField source="isCommon" />
                        <StatusField source="status" statusType={StatusType.entity} />
                        <DateField source="createdAt" showTime />
                        <DateField source="updatedAt" showTime />
                        <NavigateToField
                            label="Created By"
                            key="createdByAdmin"
                            link={'/admin/app/admin/:id/show'}
                            id="carrierManagerId"
                        >
                            <TextField source="createdByAdmin.email" label="Created By" sortable={false} />
                        </NavigateToField>
                        <NavigateToField
                            label="Updated By"
                            key="createdByAdmin"
                            link={'/admin/app/admin/:id/show'}
                            id="carrierManagerId"
                        >
                            <TextField source="updatedByAdmin.email" label="Updated By" sortable={false} />
                        </NavigateToField>
                        <EditButton />
                        <DeleteWithConfirmButton
                            title="Delete setting type"
                            confirmContent="Are you sure?"
                            redirect={'/admin/app/carriers/1'}
                        />
                    </Datagrid>
                </>
            </List>
        </Grid>
    )
}
