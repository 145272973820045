import React from 'react'
import {
    TabbedShowLayout,
    Datagrid,
    useShowContext,
    NumberField,
    TextField,
    WrapperField,
    DateField,
    List,
    Button,
    SelectInput,
    BooleanField,
    FunctionField,
    EditBase,
    Form,
    SaveButton,
    TimeInput,
    BooleanInput,
    useRefresh,
    useNotify,
    ReferenceInput,
    TextInput,
} from 'react-admin'

import { StatusField } from '@/admin/resources/order/order-status'
import { statusData, statusDescriptions, StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { LongField } from '@/admin/fields/common'
import { Loader } from '@/admin/components/Loader'
import WarehouseAddress, { IAddress } from './warehouse-address'
import { OrdersListWarehouse } from './warehouse-orders'
import { showEditor } from './warehouse-editor'
import { showConditionEditor } from './warehouse-condition-edit'
import { showEditorLicense } from './warehouse-license-editor'
import { showUploadDocument } from './warehouse-document-upload'

// eslint-disable-next-line complexity
export const WarehouseView = function () {
    const { record, isLoading, isFetching } = useShowContext()
    const refresh = useRefresh()

    type Tmodals = 'license' | 'warehouse'

    const modals = {
        warehouse: showEditor,
        license: showEditorLicense,
    }

    const cardTitle = (title: string, item: any, type: Tmodals) => {
        const modal = modals[type]
        return (
            <>
                {title}{' '}
                <Button
                    type="button"
                    onClick={() => modal('warehouses', type === 'warehouse' ? item.id : item.licenses?.id, refresh)}
                    color="primary"
                    label="Edit"
                />
            </>
        )
    }

    const ManagersEmpty = () => <div>No Managers found</div>

    const carrierAddress = [{ type: 3 }, { type: 1 }, { type: 5 }]

    if (record?.addresses) {
        record.addresses.forEach((item: IAddress) => {
            switch (item.type) {
                case 1:
                    carrierAddress[1] = item
                    break
                case 5:
                    carrierAddress[2] = item
                    break
                case 3:
                    carrierAddress[0] = item
                    break
            }
        })
    }
    const getNameType = (value: number, statusType: StatusType) => {
        const { title } = statusData(statusType, value)
        return title
    }

    // function to convert string to date in "hh:mm" format
    const timeToString = (time: any) => {
        const hours = time.getHours().toString().padStart(2, '0')
        const minutes = time.getMinutes().toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }

    const notify = useNotify()
    let mutationOptions = {}
    const successMessage = 'Information saved'
    const onSuccessOption = () => {
        notify(successMessage, { type: 'success' })
    }
    const onErrorOption = (error: { message: string[] }) => {
        notify(error?.message[0], { type: 'error' })
    }
    mutationOptions = Object.assign(mutationOptions, { onSuccess: onSuccessOption })

    const validateCheckboxes = (values: any) => {
        const errors: any = {
            monday: {},
            tuesday: {},
            wednesday: {},
            thursday: {},
            friday: {},
            saturday: {},
            sunday: {},
        }
        if (
            !values.monday.active &&
            !values.tuesday.active &&
            !values.wednesday.active &&
            !values.thursday.active &&
            !values.friday.active &&
            !values.saturday.active &&
            !values.sunday.active
        ) {
            errors.monday.active = 'At least one day must be selected'
            errors.tuesday.active = 'At least one day must be selected'
            errors.wednesday.active = 'At least one day must be selected'
            errors.thursday.active = 'At least one day must be selected'
            errors.friday.active = 'At least one day must be selected'
            errors.saturday.active = 'At least one day must be selected'
            errors.sunday.active = 'At least one day must be selected'
        }
        return errors
    }

    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Warehouse">
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} md={6} lg={6}>
                        {!isLoading && !isFetching ? (
                            <DataCard
                                records={[
                                    { title: 'Entity', value: record.entity },
                                    {
                                        title: 'Status',
                                        value: <StatusField source="status" statusType={StatusType.entity} />,
                                    },
                                    { title: 'Routing Number', value: record.routingNumber },
                                    {
                                        title: 'Account Number',
                                        value: 'xxxxxxxx' + (record.lastAccountIdNumbers ?? 'xxxx'),
                                    },
                                    { title: 'Discount %', value: record.discount },
                                    { title: 'Area(pallets)', value: record.area },
                                    { title: 'Refrigerator Area(pallets)', value: record.refrigeratorArea },
                                    {
                                        title: 'Area(sq.feet)',
                                        value: record.areaSqFeet ? record.areaSqFeet : '(not set)',
                                    },
                                    {
                                        title: 'Refrigerator Area(sq.feet)',
                                        value: record.refrigeratorAreaSqFeet
                                            ? record.refrigeratorAreaSqFeet
                                            : '(not set)',
                                    },
                                    {
                                        title: 'Unloading docks',
                                        value: record.gatesCount,
                                    },
                                    {
                                        title: 'Min Order Price',
                                        value: record.minOrderPrice ? record.minOrderPrice : '(not set)',
                                    },
                                    {
                                        title: 'Auto booking',
                                        value: record.autoBooking ? 'Yes' : 'No',
                                    },
                                    {
                                        title: 'Comment',
                                        value: record.comment,
                                    },
                                    { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                    { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                ]}
                                title={cardTitle('Warehouse', record, 'warehouse')}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container xs={12} md={12} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                {!isLoading && !isFetching ? (
                                    <DataCard
                                        records={[
                                            { title: 'USDOT or MC/MX#', value: record?.licenses?.number },
                                            {
                                                title: 'Status',
                                                value: (
                                                    <StatusField
                                                        source="licenses.status"
                                                        statusType={StatusType.document}
                                                    />
                                                ),
                                            },
                                            {
                                                title: 'Next Review At',
                                                value: record?.licenses?.nextReviewAt
                                                    ? dayjs(record?.licenses?.nextReviewAt).format('MM/DD/YYYY HH:mm')
                                                    : '(not set)',
                                            },
                                            {
                                                title: 'Created At',
                                                value: record?.licenses?.createdAt
                                                    ? dayjs(record?.licenses?.createdAt).format('MM/DD/YYYY HH:mm')
                                                    : '(not set)',
                                            },
                                            {
                                                title: 'Reviewed At',
                                                value: record?.licenses?.reviewedAt
                                                    ? dayjs(record?.licenses?.reviewedAt).format('MM/DD/YYYY HH:mm')
                                                    : '(not set)',
                                            },
                                        ]}
                                        title={cardTitle('License', record, 'license')}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {!isLoading && !isFetching ? (
                                    <DataCard
                                        records={[
                                            {
                                                title: 'Average',
                                                value: record?.rating?.average.toFixed(2),
                                            },
                                            {
                                                title: 'Response',
                                                value: record?.rating?.response.toFixed(2),
                                            },
                                            {
                                                title: 'Friendliness',
                                                value: record?.rating?.location.toFixed(2),
                                            },
                                            {
                                                title: 'Location',
                                                value: record?.rating?.friendliness.toFixed(2),
                                            },
                                            {
                                                title: 'Price',
                                                value: record?.rating?.price.toFixed(2),
                                            },
                                            {
                                                title: 'Professionalism',
                                                value: record?.rating?.professionalism.toFixed(2),
                                            },
                                            {
                                                title: 'Rating Count',
                                                value: record?.rating?.ratingCount,
                                            },
                                        ]}
                                        title={'Rating'}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <h2>Conditions</h2>
                                {!isLoading && !isFetching && record.condition.length === 0 ? (
                                    <DataCard
                                        key={'condition_' + 0}
                                        records={[
                                            {
                                                title: 'No data',
                                                value: '',
                                            },
                                        ]}
                                        title={''}
                                    />
                                ) : (
                                    ''
                                )}
                                {!isLoading && !isFetching ? (
                                    record.condition.map(
                                        (item: {
                                            code: string
                                            isActive: any
                                            conditionType: {
                                                title:
                                                    | boolean
                                                    | React.ReactChild
                                                    | React.ReactFragment
                                                    | React.ReactPortal
                                                    | null
                                                    | undefined
                                            }
                                        }) => (
                                            <DataCard
                                                key={'condition_' + item.code}
                                                records={[
                                                    {
                                                        title: 'Is Active',
                                                        value: item.isActive ? 'Yes' : 'No',
                                                    },
                                                ]}
                                                title={
                                                    <>
                                                        {item.conditionType.title}{' '}
                                                        <Button
                                                            type="button"
                                                            onClick={() =>
                                                                showConditionEditor(
                                                                    'warehouses',
                                                                    record.id,
                                                                    item.isActive,
                                                                    refresh,
                                                                )
                                                            }
                                                            color="primary"
                                                            label="Edit"
                                                        />
                                                    </>
                                                }
                                            />
                                        ),
                                    )
                                ) : (
                                    <Loader />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <DataCard
                            title={'Managers'}
                            records={[]}
                            content={
                                <List
                                    disableSyncWithLocation
                                    storeKey="WHManagers"
                                    sort={{ field: 'createdAt', order: 'DESC' }}
                                    perPage={25}
                                    resource={'managers'}
                                    filter={{ permissions: { warehouseId: record?.id } }}
                                    filters={[
                                        <SelectInput
                                            key="status"
                                            label="Status"
                                            source="status"
                                            choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                                                id: item.value,
                                                name: item.title,
                                            }))}
                                            alwaysOn
                                        />,
                                    ]}
                                >
                                    <Datagrid bulkActionButtons={false} rowClick="show" empty={<ManagersEmpty />}>
                                        <NumberField source="id" width={50} />
                                        <LongField source="email.email" label="Email" width={150} />
                                        <TextField source="phone.number" label="Phone" sortable={false} />
                                        <WrapperField label="Full name" sortBy="firstName">
                                            <TextField source="firstName" label="First Name" width={100} />
                                            &nbsp;
                                            <TextField source="lastName" label="Last Name" width={100} />
                                        </WrapperField>
                                        <LongField source="position" label="Position" width={100} sortable={false} />
                                        <TextField source="type" label="Type" sortable={false} />
                                        <DateField source="updatedAt" label="Changed at" showTime />
                                        <StatusField source="status" statusType={StatusType.entity} />
                                    </Datagrid>
                                </List>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Addresses">
                <WarehouseAddress
                    record={record}
                    addresses={carrierAddress}
                    isFetching={isFetching}
                    isLoading={isLoading}
                />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Orders">
                {record && record.id ? <OrdersListWarehouse warehouse={record} /> : null}
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Documents">
                <List
                    actions={
                        <Button
                            type="button"
                            onClick={() => showUploadDocument(record?.id, refresh)}
                            color="primary"
                            label="Upload Document"
                        />
                    }
                    disableSyncWithLocation
                    storeKey="warehouses/documents"
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    perPage={25}
                    resource={'warehouses/documents'}
                    filter={{ warehouseId: record?.id }}
                    filters={[
                        <ReferenceInput
                            key={'service'}
                            label="Service"
                            source="svcDocTypeId"
                            reference="warehouse-documents/services"
                            allowEmpty
                            alwaysOn
                        >
                            <SelectInput
                                source="svcDocType.warehouseSvcType.title"
                                label="Service"
                                alwaysOn
                                optionText="name"
                            />
                        </ReferenceInput>,
                        <ReferenceInput
                            key={'document'}
                            label="Document"
                            source="svcDocType.id"
                            reference="warehouse-documents/documents"
                            allowEmpty
                            alwaysOn
                        >
                            <SelectInput label="Document" optionText="name" />
                        </ReferenceInput>,
                        <TextInput key="warehouse" label="Warehouse" source="warehouse.entity_ilike" alwaysOn />,
                        <TextInput key="company" label="Company" source="warehouse.company.entity_ilike" alwaysOn />,
                        <SelectInput
                            key="status"
                            label="Status"
                            source="status"
                            choices={statusDescriptions[StatusType.document].map((item: any) => ({
                                id: item.value,
                                name: item.title,
                            }))}
                            alwaysOn
                        />,
                        <SelectInput
                            key="expiredAt"
                            source="expiredAt_ene"
                            label="Expired At"
                            choices={[
                                {
                                    id: 'empty',
                                    name: 'Empty',
                                },
                                {
                                    id: 'notEmpty',
                                    name: 'Not Empty',
                                },
                                {
                                    id: 'expired',
                                    name: 'Expired',
                                },
                            ]}
                            alwaysOn
                        />,
                    ]}
                >
                    <Datagrid
                        bulkActionButtons={false}
                        rowClick={(id, resource, r) => `/admin/app/warehouse-documents/${r.id}/show`}
                        empty={<ManagersEmpty />}
                    >
                        <TextField source="svcDocType.warehouseSvcType.title" label="Service" sortable={false} />
                        <TextField source="svcDocType.name" label="Document" sortable={false} />
                        <TextField source="warehouse.entity" label="Warehouse" sortable={false} />
                        <TextField source="warehouse.company.entity" label="Company" sortable={false} />
                        <StatusField source="status" statusType={StatusType.document} />
                        <DateField source="expiredAt" label="Expired At" showTime />
                        <DateField source="createdAt" label="Created At" showTime />
                        <DateField source="updatedAt" label="Updated At" showTime />
                    </Datagrid>
                </List>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Services">
                {record && record?.id ? (
                    <List
                        disableSyncWithLocation
                        storeKey="warehouses/services"
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        perPage={25}
                        resource={`warehouses/${record?.id}/service`}
                        filters={[
                            <SelectInput
                                key="status"
                                label="Status"
                                source="status"
                                choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                                    id: item.value,
                                    name: item.title,
                                }))}
                                alwaysOn
                            />,
                        ]}
                    >
                        <Datagrid bulkActionButtons={false} rowClick="show" empty={<ManagersEmpty />}>
                            <FunctionField label="Icon" render={(r: any) => (r.icon ? r.icon : '(not set)')} />
                            <FunctionField
                                label="Unity"
                                render={(r: any) =>
                                    r.unity
                                        ? r.unity
                                              .split(' ')
                                              .map((i: { toUpperCase: () => any }[]) => i[0].toUpperCase())
                                              .join('')
                                        : '(not set)'
                                }
                            />
                            <TextField source="group" sortable={false} />
                            <TextField source="service" label="Service" sortable={false} />
                            <FunctionField
                                label="Service Type"
                                render={(r: any) =>
                                    r.type
                                        ? getNameType(r.type, StatusType.servicesType)
                                              .split(' ')
                                              .map((i: any) => i[0].toUpperCase())
                                              .join('')
                                        : '(not set)'
                                }
                            />
                            <FunctionField
                                label="Price Type"
                                render={(r: any) =>
                                    r.price_type
                                        ? getNameType(r.price_type, StatusType.priceType)
                                              .split(' ')
                                              .map((i: any) => i[0].toUpperCase())
                                              .join('')
                                        : '(not set)'
                                }
                            />
                            <StatusField source="status" statusType={StatusType.document} />
                            <BooleanField source="active" label="Active" sortable={false} />
                            <FunctionField
                                label="Rate 0-7"
                                render={(r: any) => (r.rate1 ? '$' + r.rate1.toFixed(2) : '0.00')}
                            />
                            <FunctionField
                                label="Rate 8-28"
                                render={(r: any) => (r.rate2 ? '$' + r.rate2.toFixed(2) : '0.00')}
                            />
                            <FunctionField
                                label="Rate 29+d"
                                render={(r: any) => (r.rate3 ? '$' + r.rate3.toFixed(2) : '0.00')}
                            />
                            <FunctionField
                                label="Rate 0-7(Sq feet)"
                                render={(r: any) => (r.rate1sqfeet ? '$' + r.rate1sqfeet.toFixed(2) : '0.00')}
                            />
                            <FunctionField
                                label="Rate 8-28(Sq feet)"
                                render={(r: any) => (r.rate2sqfeet ? '$' + r.rate2sqfeet.toFixed(2) : '0.00')}
                            />
                            <FunctionField
                                label="Rate 29+d(Sq feet)"
                                render={(r: any) => (r.rate3sqfeet ? '$' + r.rate3sqfeet.toFixed(2) : '0.00')}
                            />
                            <FunctionField label="Need doc" render={(r: any) => (r.rdoc === '0' ? 'No' : 'Yes')} />
                            <DateField source="updated_at" label="Updated At" showTime />
                        </Datagrid>
                    </List>
                ) : null}
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Work time">
                <EditBase
                    actions={false}
                    resource={'warehouses/working-time'}
                    mutationMode="pessimistic"
                    mutationOptions={mutationOptions}
                    transform={(body) => {
                        interface WorkTime {
                            [weekday: string]: {
                                active: boolean
                                weekday: string
                                to: string
                                from: string
                            }
                        }

                        const workTime: WorkTime = {}

                        Object.keys(body).forEach((day) => {
                            if (day !== 'id' && body[day].active === true) {
                                workTime[body[day].weekday] = Object.assign({}, body[day], {
                                    to: timeToString(new Date(body[day].to)),
                                    from: timeToString(new Date(body[day].from)),
                                })
                            }
                        })

                        return workTime
                    }}
                >
                    <Form validate={validateCheckboxes}>
                        <Grid container spacing={2} alignContent="flex-start">
                            <Grid item xs={12} md={6}>
                                <div>Monday</div>
                                <TimeInput source="monday.from" label="from" />
                                <TimeInput source="monday.to" label="to" />
                                <BooleanInput source="monday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Tuesday</div>
                                <TimeInput source="tuesday.from" label="from" />
                                <TimeInput source="tuesday.to" label="to" />
                                <BooleanInput source="tuesday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Wednesday</div>
                                <TimeInput source="wednesday.from" label="from" />
                                <TimeInput source="wednesday.to" label="to" />
                                <BooleanInput source="wednesday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Thursday</div>
                                <TimeInput source="thursday.from" label="from" />
                                <TimeInput source="thursday.to" label="to" />
                                <BooleanInput source="thursday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Friday</div>
                                <TimeInput source="friday.from" label="from" />
                                <TimeInput source="friday.to" label="to" />
                                <BooleanInput source="friday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Saturday</div>
                                <TimeInput source="saturday.from" label="from" />
                                <TimeInput source="saturday.to" label="to" />
                                <BooleanInput source="saturday.active" label="It's a workday" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>Sunday</div>
                                <TimeInput source="sunday.from" label="from" />
                                <TimeInput source="sunday.to" label="to" />
                                <BooleanInput source="sunday.active" label="It's a workday" />
                            </Grid>
                        </Grid>
                        <SaveButton />
                    </Form>
                </EditBase>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    )
}
