import {
    BooleanField,
    CloneButton,
    Datagrid,
    DateField,
    FunctionField,
    List,
    NumberField,
    TextField,
} from 'react-admin'
import React from 'react'
import { StatusField } from '@/admin/resources/order/order-status'
import { StatusType } from '@/admin/model/status'

export const TOSList = () => {
    return (
        <List sort={{ field: 'createdAt', order: 'DESC' }} perPage={25}>
            <Datagrid bulkActionButtons={false} rowClick="show">
                <NumberField source="id" label="ID" sortable={false} />
                <StatusField source="status" sx={{ width: 120 }} statusType={StatusType.tos} />
                <TextField source="notes" />
                <TextField source="message" />
                <NumberField source="totalAccepted" />
                <DateField source="startDate" />
                <FunctionField
                    label="Last Updated By"
                    sortBy="updatedByAdmin.email"
                    render={(record: any) =>
                        record.updatedByAdmin ? `(#${record.updatedByAdmin.id}) ${record.updatedByAdmin.email}` : ''
                    }
                />
                <DateField source="updatedAt" showTime />

                <FunctionField
                    label="Created By"
                    sortBy="updatedByAdmin.email"
                    render={(record: any) =>
                        record.createdByAdmin
                            ? `(#${record.createdByAdmin.id}) ${record.createdByAdmin.email}`
                            : 'SYSTEM'
                    }
                />
                <DateField source="createdAt" showTime />
                <CloneButton />
            </Datagrid>
        </List>
    )
}
