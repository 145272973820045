import React from 'react'
import { useRecordContext } from 'react-admin'
import dayjs from 'dayjs'

export const ShadowManagerExpand = () => {
    const record = useRecordContext()
    return (
        <div className="shadow-manager-expand">
            {record.logs.map((rec: any) => (
                <div key={rec.id} className="shadow-manager-expand__log">
                    <span className="shadow-manager-expand__log__date">
                        {dayjs(rec.createdAt).format('MM/DD/YYYY hh:m A')}
                        {' - '}
                    </span>
                    {rec.log}
                </div>
            ))}
        </div>
    )
}
