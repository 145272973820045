export const booleanList = [
    { key: 'true', name: 'True', value: 'TRUE' },
    { key: 'false', name: 'False', value: 'FALSE' },
]

export const settingTypeList = [
    { key: 'numeric', id: 1, name: 'Numeric', value: 'NUMERIC' },
    { key: 'string', id: 2, name: 'String', value: 'STRING' },
    { key: 'bool', id: 3, name: 'Bool', value: 'BOOL' },
    { key: 'file', id: 4, name: 'File', value: 'FILE' },
]

export const settingArea: { [key: number]: string } = {
    1: 'Booking form',
    2: 'Warehouse order page',
    3: 'Warehouse settings page',
    4: 'Carrier settings page',
    5: 'Carrier order page',
}

export const serviceTypes = [
    { id: 0, name: 'SPECIAL' },
    { id: 1, name: 'STORAGE' },
    { id: 2, name: 'DELIVERY' },
    { id: 3, name: 'FEATURE' },
    { id: 4, name: 'SUB-SERVICE REQUIRED' },
    { id: 5, name: 'PRICE MODIFICATOR' },
    { id: 6, name: 'SUB-SERVICE OPTIONAL' },
    { id: 7, name: 'PICKUP' },
    { id: 8, name: 'DELIVERY STOP' },
]

const NO_PRICE = 0
const PER_DAY_PER_PALLET = 1
const ONE_TIME_PER_PALLET = 2
const ONE_TIME_FOR_ALL = 3
const PER_DAY_PER_UNIT = 4
const ONE_TIME_PER_UNIT = 5
const ONE_TIME_PER_STOP = 6

export const pricingTypes = [
    { id: NO_PRICE, name: 'free' },
    { id: PER_DAY_PER_PALLET, name: 'per pallet per day' },
    { id: ONE_TIME_PER_PALLET, name: 'per pallet' },
    { id: ONE_TIME_FOR_ALL, name: 'flat rate' },
    { id: PER_DAY_PER_UNIT, name: 'per unit per day' },
    { id: ONE_TIME_PER_UNIT, name: 'per unit' },
    { id: ONE_TIME_PER_STOP, name: 'per stop' },
]

export const calendarEventTypes: { [key: string]: any } = {
    CONSTANT_REPEATED: 'Constant repeated event',
    FLOATING_REPEATED: 'Floating repeated event',
    SPECIAL_EVENT: 'Special event',
}

export const calendarMarkupPlaceType: { [key: string]: any } = {
    ALL: 'Everywhere',
    COUNTRY: 'Country',
    ZIP: 'ZIP',
}

export const adminTypes: { [key in '0' | '20' | '10']: string } = {
    20: 'User',
    10: 'Root',
    0: 'Manager',
}
