import React, { CSSProperties, FC } from 'react'
import { NumberField, NumberFieldProps, PublicFieldProps, sanitizeFieldRestProps, useRecordContext } from 'react-admin'
import { get } from 'lodash'
import { Typography } from '@mui/material'
import { StatusDot } from '@/admin/components/StatusDot/StatusDot'

export const TotalField: FC<{ source: string; label?: string }> = ({ source, label }) => {
    return <NumberField source={source} locales="en-US" options={{ style: 'currency', currency: 'USD' }} />
}

export const LongField: FC<
    PublicFieldProps & {
        style?: CSSProperties
        width?: number
        transform?: (record: any) => string
        addon?: (record: any) => React.ReactNode
    }
> = ({ source, width, transform, addon, style }) => {
    const record = useRecordContext()

    const value = transform ? transform(record) : source && get(record, source)
    return (
        <span className="long-field" title={value} style={{ ...(style || {}), width: width ?? 150 }}>
            {addon && addon(record)}
            {value}
        </span>
    )
}

export const BooleanStatusField: FC<PublicFieldProps> = ({ source }) => {
    const record = useRecordContext()

    const val = source && get(record, source)
    return (
        <div className="boolean-status-field">
            <StatusDot type={val ? 'success' : 'error'} />
        </div>
    )
}

export const PercentField: FC<NumberFieldProps> = ({
    source,
    options,
    locales,
    className,
    textAlign = 'right',
    ...rest
}) => {
    const record = useRecordContext()

    const percentVal = source && get(record, source)
    const value = Number(percentVal ?? 0) / 100
    return (
        <Typography variant="body2" component="span" className={className} {...sanitizeFieldRestProps(rest)}>
            {percentVal ? value.toLocaleString(locales, options) : ''}
        </Typography>
    )
}

PercentField.defaultProps = {
    textAlign: 'right',
    locales: 'en-US',
    options: { style: 'percent', maximumFractionDigits: 2 },
}
