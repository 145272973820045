import React, { FC } from 'react'
import { Button, useShowContext } from 'react-admin'
import { Loader } from '@/admin/components/Loader'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { statusData, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { PaymentField } from '@/admin/resources/order/order-payment'
import { OrderInvoiceSmall } from '@/admin/resources/order/order-invoice'
import { DataCard } from '@/admin/components/datacard'
import { oldAdminUINavigator } from '@/admin/api/old.admin'
import { showCalculation } from '@/admin/resources/order/order-calculation'
import { Link, useNavigate } from 'react-router-dom'
import consts from '@/admin/common/consts'
import { scenarios } from '@/admin/resources/price-sheet/create-sheet'
import { createSearchLinkUsingOrder } from '@/admin/resources/order/utils'
import './order-view.scss'

const serviceLocalArrivalTime = (serviceDate: Date, from: string, to: string, code: number) => {
    const timeFrom = dayjs(from, 'HH:mm')
    const timeFromMin = timeFrom.get('m')
    const timeFromHour = timeFrom.get('h')
    const timeTo = dayjs(to, 'HH:mm')
    const timeToMin = timeTo.get('m')
    const timeToHour = timeTo.get('h')
    const tzInfo = (consts.timeZones as any)[code]
    const dateOriginalFrom = dayjs.tz(serviceDate, tzInfo.name)
    const dateOriginalFromWithTime = dateOriginalFrom.clone().set('h', timeFromHour).set('m', timeFromMin).local()
    const dateOriginalToWithTime = dateOriginalFrom.clone().set('h', timeToHour).set('m', timeToMin).local()

    return `${dateOriginalFromWithTime.format('MM/DD/YYYY HH:mm')} - ${dateOriginalToWithTime.format('HH:mm')}`
}

export const OV_CLASS_NAME = 'a-order-view'

// eslint-disable-next-line complexity
export const OrderView: FC = () => {
    const navigate = useNavigate()
    const { record, isLoading, isFetching } = useShowContext()
    if (!record && (isLoading || isFetching)) {
        return <Loader />
    }
    const invoiceWH = record?.invoices?.find((rec: any) => rec.invoiceType === 'w')
    const invoiceCustomer = record?.invoices?.find((rec: any) => rec.invoiceType === 'c')

    const navigateToPricing = (e: any) => {
        e.stopPropagation()
        navigate(`/admin/app/price-sheets?filter={"id":"${record.pricingSheetId}"}`)
    }
    const summaryRecords: any[] = [
        {
            title: 'Arrival',
            value: `${dayjs(record.mainService.periodStart).utc().format('MM/DD/YYYY')} ${dayjs(
                record.arrivalFrom,
                'HH:mm',
            ).format('HH:mm')} - ${dayjs(record.arrivalTo, 'HH:mm').format('HH:mm')} ${
                (consts.timeZones as any)[record.warehouse.timezoneCode].description
            }`,
        },
        {
            title: 'Your time',
            value: `${serviceLocalArrivalTime(
                record.mainService.periodStart,
                record.arrivalFrom,
                record.arrivalTo,
                record.warehouse.timezoneCode,
            )}`,
        },
        {
            title: 'Markup',
            value: record.markup ? `${record.markup.total}` : '-',
        },
        {
            title: 'Carrier pays',
            value: `$${record.carrierTotal.toFixed(2)}`,
        },
        {
            title: 'Warehouse gets',
            value: `$${record.warehouseTotal.toFixed(2)}`,
        },
        {
            title: 'OLIMP Fees (service fees + wh stripe fee)',
            value: `$${record.serviceFee.toFixed(2)}`,
        },
        {
            title: 'Other Fees (markup)',
            value: `$${(record.carrierTotal - record.serviceFee - record.warehouseTotal).toFixed(2)}`,
        },
        {
            title: 'Discount',
            value: `$${record.discount.toFixed(2)}`,
        },
        {
            title: 'Min Price Used',
            value: `${record.usedMinPrice ? `Yes, $${record.minPrice.toFixed(2)}` : 'No'}`,
        },
        {
            type: 'divider',
        },
    ]
    const scenario = scenarios.find((s) => s.id === record.scenario)

    const similarOrderLink = createSearchLinkUsingOrder(record)

    if (scenario && record.pricing) {
        summaryRecords.push({
            title: ' ',
            value: (
                <>
                    <Button
                        onClick={navigateToPricing}
                        color="primary"
                        label={`${scenario.name} (#${record.pricingSheetId})`}
                    />
                    <br />
                    <Button onClick={() => showCalculation(record)} color="primary" label="Cost Breakdown" />
                </>
            ),
        })
    }
    return (
        <div className={`${OV_CLASS_NAME}__container`}>
            <div className={`${OV_CLASS_NAME}__heading`}>
                <h3 className={`${OV_CLASS_NAME}__title`}>Order #{record.id}</h3>
                <Button
                    color="secondary"
                    onClick={(e) => {
                        e.preventDefault()
                        oldAdminUINavigator.goToOrder(record.id)
                    }}
                    label="Open in old Admin Panel"
                />
            </div>
            <Grid container>
                <Grid container md={8} xs={12} spacing={2} sx={{ mr: 2 }} alignContent="flex-start">
                    <Grid item xs={12} md={6}>
                        <DataCard
                            records={[
                                { title: 'Name', value: record.warehouse.entity },
                                { title: 'Company', value: record.warehouse.company?.entity ?? '' },
                                { title: 'Aadress', value: record.warehouse.physicalAddress.address },
                            ]}
                            title={`Warehouse #${record.warehouse.id}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataCard
                            records={[
                                { title: 'Name', value: record.carrier.entity },
                                { title: 'Company', value: record.carrier.company?.entity ?? '' },
                                {
                                    title: 'Manager',
                                    value:
                                        (record.carrierManager?.firstName ?? '') +
                                        (record.carrierManager?.lastName ?? ''),
                                },
                                { title: 'Email', value: record.carrierManager?.email?.email ?? '' },
                            ]}
                            title={`Carrier #${record.carrier.id}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataCard
                            records={record.services
                                .map((s: any) => ({ title: s.asString, value: `$${s.price.toFixed(2)}` }))
                                .concat([
                                    {
                                        title: 'Min Price',
                                        value: `${record.usedMinPrice ? `Yes, $${record.minPrice.toFixed(2)}` : 'No'}`,
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        title: 'Total',
                                        value: `$${record.total.toFixed(2)}`,
                                    },
                                    {
                                        title: 'OLIMP Fee from WH Services / Adjustments',
                                        value: `- $${record.serviceFee.toFixed(2)}`,
                                    },
                                ])}
                            title="Services"
                        />
                    </Grid>

                    {record.adjustments?.length > 0 && (
                        <Grid item xs={12}>
                            <DataCard
                                records={record.adjustments.map((s: any) => {
                                    const status = statusData(StatusType.verification, s.status)
                                    return {
                                        title: (
                                            <div>
                                                <div
                                                    style={{
                                                        color: status.color || 'black',
                                                        fontWeight: 600,
                                                        width: 250,
                                                    }}
                                                >
                                                    {status.title}
                                                </div>
                                                {s.asString}{' '}
                                            </div>
                                        ),
                                        value: (
                                            <span
                                                style={{
                                                    display: 'block',
                                                    textDecoration: s.status === 1 ? 'line-through' : undefined,
                                                }}
                                            >
                                                ${s.price.toFixed(2)}
                                                {s.serviceFee > 0 && (
                                                    <span>
                                                        <br />
                                                        Service fee - ${s.serviceFee.toFixed(2)} (WH)
                                                    </span>
                                                )}
                                                {s.markupFee > 0 && (
                                                    <span>
                                                        <br />
                                                        Markup + ${s.markupFee.toFixed(2)} (Carrier)
                                                    </span>
                                                )}
                                                {s.stripeFee > 0 && (
                                                    <span>
                                                        <br />
                                                        Stripe + ${s.stripeFee.toFixed(2)} (Carrier)
                                                    </span>
                                                )}
                                            </span>
                                        ),
                                    }
                                })}
                                title="Adjustments"
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <DataCard
                            records={[
                                {
                                    title: 'Copy Order Search Criteria',
                                    value: (
                                        <Link to={similarOrderLink} target="_blank">
                                            Find Recommendations
                                        </Link>
                                    ),
                                },
                            ]}
                            title="Search for a WH recommendation"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataCard
                            records={[
                                { title: 'Load #', value: record.loadNumber },
                                { title: 'Container #', value: record.containerNumber },
                                { title: 'Comment', value: record.comment },
                                { title: 'Updated at', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Created at', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                            ]}
                            title="Other information"
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} md={4} spacing={2}>
                    <Grid item xs={12}>
                        <DataCard
                            content={
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        marginBottom: 12,
                                    }}
                                >
                                    <div>
                                        <StatusField source="status" sx={{ mb: 1 }} description />
                                        <PaymentField source="paymentStrategy" sx={{ width: 120 }} />
                                    </div>
                                </div>
                            }
                            records={summaryRecords}
                            title="Summary"
                        />
                    </Grid>
                    {record.paymentStrategy === 2 && (
                        <Grid item xs={12}>
                            <DataCard
                                records={[]}
                                title="Invoices"
                                content={
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <OrderInvoiceSmall
                                                invoiceType="c"
                                                orderId={record.id}
                                                invoice={invoiceCustomer}
                                                listItem
                                            />
                                            <OrderInvoiceSmall
                                                invoiceType="w"
                                                orderId={record.id}
                                                invoice={invoiceWH}
                                                listItem
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DataCard
                            records={
                                record.statusHistory && record.statusHistory.length > 0
                                    ? record.statusHistory.map((st: any) => {
                                          const created = dayjs(st.createdAt)
                                          const by = st.manager?.email?.email ?? 'System'
                                          return {
                                              title: statusData(StatusType.order, st.status).title,
                                              value: created.format('MM/DD/YYYY HH:mm'),
                                              subValue: `(${created.fromNow()})`,
                                              subTitle: `By ${by}`,
                                          }
                                      })
                                    : [{ title: 'No status changes yet' }]
                            }
                            title="Status history"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
