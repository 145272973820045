import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { Grid } from '@mui/material'
import DeleteAccountButton from '@/admin/parts/DeleteAccountButton/DeleteAccountButton'

export const showEditor = (resource: string, recordId?: number | string) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="firstName" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="lastName" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="position" />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="type"
                    label="Type"
                    source="type"
                    choices={[
                        {
                            id: 'admin',
                            name: 'Admin',
                        },
                        {
                            id: 'manager',
                            name: 'Manager',
                        },
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6}>
            {recordId ? (
                <DeleteAccountButton managerId={recordId} />
            ) : null}
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Manager',
            resource: resource,
            recordId,
            children: form,
        },
    })
}
