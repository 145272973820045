import React from 'react'
import { Labeled, useInput } from 'react-admin'
import ReactJson from 'react-json-view'

const JsonInput: React.FC<any> = (props) => {
    const { field } = useInput(props)
    const handleChange = (data: any) => {
        try {
            // update the json value
            field.onChange(data.updated_src)
        } catch (err) {
            // You might want to handle JSON parsing errors here
        }
    }
    return (
        <Labeled label={props.label || props.source}>
            <ReactJson
                src={field.value}
                onEdit={handleChange}
                onDelete={handleChange}
                onAdd={handleChange}
                theme="monokai"
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
            />
        </Labeled>
    )
}

export default JsonInput
