import React from 'react'
import {
    Datagrid,
    List,
    TextInput,
    FunctionField,
    DateField,
    SelectInput,
    NumberInput,
    NumberField,
    DeleteWithConfirmButton,
    useRecordContext,
    Button,
    TextField,
    BooleanField,
} from 'react-admin'
import { showEditor } from './edit'
import { Grid } from '@mui/material'
import './list.scss'
import { adminTypes } from '@/admin/model/dictionaries'

type AdminType = {
    role: keyof typeof adminTypes
}

export const roleChoices = Object.keys(adminTypes).map((key) => ({
    id: key as '0' | '20' | '10', // convert key to '0' | '20' | '10'
    name: adminTypes[key as keyof typeof adminTypes] as string, // use a type assertion to tell TypeScript that the value is a string
}))

export const ReferralList = () => {
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('referral-system', item)
                }}
                label="Edit"
            />
        )
    }
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    perPage={25}
                    disableSyncWithLocation
                    storeKey="referral-system"
                    filters={[
                        <TextInput key="name" label="Name" source="name_ilike" alwaysOn />,
                        <NumberInput key="percentage" source="percentage" label="Percentage" alwaysOn />,
                        <NumberInput key="days" source="days" label="Active Days" alwaysOn />,
                        <NumberInput key="orders" source="orders" label="Active Orders" alwaysOn />,
                        <SelectInput
                            key="isActive"
                            source="isActive"
                            label="Active"
                            choices={[
                                { id: true, name: 'Yes' },
                                { id: false, name: 'No' },
                            ]}
                            alwaysOn
                        />,
                    ]}
                    className="support-message-table"
                    actions={
                        <Button
                            type="button"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                showEditor('referral-system', undefined)
                            }}
                            label="CREATE"
                        />
                    }
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="Referral ID" width={50} sortable={false} />
                            <TextField source="code" />
                            <TextField source="name" sortable={false} />
                            <FunctionField
                                render={(r: any) => {
                                    return r.percentage + '%'
                                }}
                                source="role"
                                label="Percentage"
                            />
                            <TextField source="days" label="Active Days" sortable={false} />
                            <FunctionField
                                render={(r: any) => {
                                    return r.orders ? r.orders + ' orders' : '-'
                                }}
                                source="role"
                                label="Active Orders"
                            />
                            <DateField source="dateFrom" label="From" sortable={false} />
                            <DateField source="dateTo" label="To" sortable={false} />
                            <BooleanField source="isActive" sortable={false} />
                            <EditButton />
                            <DeleteWithConfirmButton
                                label="Delete"
                                title="Delete Admin"
                                confirmTitle="Delete Admin"
                                confirmContent="Are you sure?"
                                redirect={'/admin/app/admin-system'}
                            />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
