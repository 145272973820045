import React from 'react'
import {
    Datagrid,
    List,
    TextInput,
    FunctionField,
    DateField,
    SelectInput,
    NumberInput,
    NumberField,
    DeleteWithConfirmButton,
    useRecordContext,
    Button,
    useRefresh,
} from 'react-admin'
import { showEditor } from './edit'
import { Grid } from '@mui/material'
import './list.scss'
import { adminTypes } from '@/admin/model/dictionaries'
import { validateMaxValue } from '../../../parts/Validations'

type AdminType = {
    role: keyof typeof adminTypes
}

export const roleChoices = Object.keys(adminTypes).map((key) => ({
    id: key as '0' | '20' | '10', // convert key to '0' | '20' | '10'
    name: adminTypes[key as keyof typeof adminTypes] as string, // use a type assertion to tell TypeScript that the value is a string
}))

export const AdminList = () => {
    const EditButton = () => {
        const item = useRecordContext()
        const refresh = useRefresh()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('admin-system', item, refresh)
                }}
                label="Edit"
            />
        )
    }
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    perPage={25}
                    disableSyncWithLocation
                    storeKey="admin-system"
                    filters={[
                        <NumberInput
                            key="id"
                            source="id"
                            label="Admin ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <TextInput key="email" label="Email" source="email_ilike" alwaysOn />,
                        <SelectInput key="role" source="role" choices={roleChoices} alwaysOn />,
                    ]}
                    className="support-message-table"
                    actions={
                        <Button
                            type="button"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                console.log('ddd')
                                showEditor('admin-system', undefined)
                            }}
                            label="CREATE"
                        />
                    }
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="Admin ID" width={50} />
                            <FunctionField
                                render={(r: any) => {
                                    return <a href={`mailto:${r.email}`}>{r.email}</a>
                                }}
                                source="email"
                                label="Email"
                            />
                            <FunctionField
                                render={(r: AdminType) => {
                                    return adminTypes[r.role]
                                }}
                                source="role"
                                label="Role"
                            />
                            <DateField source="lastSupportVisit" showTime />
                            <EditButton />
                            <DeleteWithConfirmButton
                                label="Delete"
                                title="Delete Admin"
                                confirmTitle="Delete Admin"
                                confirmContent="Are you sure?"
                                redirect={'/admin/app/admin-system'}
                            />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
