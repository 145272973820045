import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, required } from 'react-admin'

import { Grid } from '@mui/material'

export const showAddressEditor = (
    resource: string,
    type: string,
    onSuccess: () => void,
    recordId?: number | string,
    defaultValue?: any,
) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="address" label="Address" validate={required()} fullWidth />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit ' + type,
            resource: 'warehouses/address',
            recordId,
            children: form,
            defaultValue,
            onSuccess,
            model: (data) => ({
                address: data.address,
                warehouseId: data.warehouseId,
                type: data.type,
            }),
        },
    })
}
