import React, { FC } from 'react'
import { Button, Input } from '@mui/material'
import { Loader } from '@/components/Loader'

interface WarehouseServiceIconProps {
    icon?: string
    onUpload: (files: FileList) => void
    loading?: boolean
}
export const WarehouseServiceIcon: FC<WarehouseServiceIconProps> = ({ icon, onUpload, loading }) => {
    if (loading) {
        return <Loader type="small" />
    }
    return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            {icon && <img src={icon} height={24} alt="service-icon" style={{ marginRight: 12 }} />}
            <Button variant="text" component="label">
                Upload
                <input
                    type="file"
                    hidden
                    onChange={(e) => {
                        const files = e.target.files
                        if (files) {
                            onUpload(files)
                        }
                    }}
                />
            </Button>
        </span>
    )
}
