import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput } from 'react-admin'
import { Grid } from '@mui/material'
import { useController } from 'react-hook-form'

export const showEditor = (resource: string, recordId?: number | string) => {
    const Title = () => {
        const record = useController({ name: 'day', defaultValue: '' })
        return <h2>{`Day: ${record.field.value}`}</h2>
    }
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <Title />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="value" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: '',
            resource: resource,
            recordId,
            children: form,
        },
    })
}
