import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, NumberInput, BooleanInput, ReferenceInput, required } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { Grid } from '@mui/material'
import { pricingTypes, serviceTypes } from '@/admin/model/dictionaries'

export const showEditor = (resource: string, recordId?: number | string, onSuccess = () => {}) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="title" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="group" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput key="type" source="type" choices={serviceTypes} validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput key="priceType" source="priceType" choices={pricingTypes} validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ReferenceInput label="Group" source="unityId" reference="warehouse-services/unity" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="termsRelated" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="required" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="isQr" label="Quotation request" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="capacity" label="Capacity (Plt)" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="notes" multiline />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: recordId ? 'Edit Warehouse Service' : 'Create Warehouse Service',
            resource: resource,
            recordId,
            children: form,
            onSuccess,
            model: (data: any) => ({
                ...data,
                updatedAt: new Date(),
            }),
        },
    })
}
