import { Button, Confirm, useEditContext, useNotify } from 'react-admin'
import { Invoice } from '@/admin/model/invoice'
import React, { useState } from 'react'
import { HttpV2 } from '@shared/apiv2/api'

export const SendOutButton = () => {
    const notify = useNotify()
    const { record, isLoading, refetch } = useEditContext<Invoice>()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const handleClick = () => setConfirmOpen(true)
    const handleDialogClose = () => setConfirmOpen(false)
    const handleConfirm = async () => {
        //dosmtha sync
        setConfirmLoading(true)
        try {
            await HttpV2.post(`/admin/invoices/${record!.id}/send`)
            refetch!()
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
        setConfirmLoading(false)

        setConfirmOpen(false)
    }

    return (
        <>
            <Button
                label={record?.status === 0 ? 'Send out invoice' : 'Re-send invoice'}
                onClick={handleClick}
                variant="contained"
            />
            <Confirm
                isOpen={confirmOpen}
                loading={isLoading || confirmLoading}
                title="Send out invoice"
                content="Are you sure you want to send this invoice to QuickBox?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    )
}
