import {
    Datagrid,
    DateField,
    DateInput,
    SelectArrayInput,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
    BooleanField,
} from 'react-admin'
import React, { FC, useState } from 'react'
import { useServiceTypes } from '@/admin/api/admin.api'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { useNavigate } from 'react-router-dom'
import { StatusField } from '@/admin/resources/order/order-status'
import { PaymentField } from '@/admin/resources/order/order-payment'
import { OrderInvoiceSmall } from '@/admin/resources/order/order-invoice'
import { LongField, PercentField } from '@/admin/fields/common'
import { Button } from '@mui/material'
import { scenarios } from '@/admin/resources/price-sheet/create-sheet'

const InvoicesListField: FC<any> = () => {
    const [shown, setShown] = useState()
    const record = useRecordContext()
    const navigate = useNavigate()
    if (record.paymentStrategy !== 2) {
        return null
    }

    const whInvoice = record.invoices.find((i: any) => i.invoiceType === 'w')
    const carrierInvoice = record.invoices.find((i: any) => i.invoiceType === 'c')

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginLeft: -32, marginRight: 20 }}>
            <OrderInvoiceSmall invoiceType="w" orderId={record.id} invoice={whInvoice} />
            <OrderInvoiceSmall invoiceType="c" orderId={record.id} invoice={carrierInvoice} />
        </div>
    )
}

const OrderPricingField: FC<any> = () => {
    const record = useRecordContext()
    const navigate = useNavigate()
    const navigateToPricing = (e: any) => {
        e.stopPropagation()
        navigate(`/admin/app/price-sheets?filter={"id":"${record.pricingSheetId}"}`)
    }
    const scenario = scenarios.find((s) => s.id === record.scenario)

    if (!scenario) {
        return <span>Old</span>
    }
    return (
        <Button size="small" color="primary" onClick={navigateToPricing} style={{ fontSize: 12 }}>
            {`${scenario.short} (${record.pricingSheetId})`}
        </Button>
    )
}

export const OrdersList = () => {
    const { data, error } = useServiceTypes()
    const servicesLoading = !data && !error
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            filters={[
                <TextInput key="id" label="ID" source="id" />,
                <TextInput key="carrier" label="Carrier Entity" source="carrier.entity_ilike" />,
                <TextInput key="warehouse" label="Warehouse Entity" source="warehouse.entity_ilike" />,
                <TextInput key="carrier-email" label="Carrier Email" source="carrierManager.email.email_ilike" />,
                <SelectArrayInput
                    isLoading={servicesLoading}
                    key="service"
                    label="Service"
                    source="services.code_in"
                    choices={
                        servicesLoading || !data ? [] : data.map((item: any) => ({ id: item.code, name: item.title }))
                    }
                />,
                <SelectArrayInput
                    key="status"
                    label="Status"
                    source="status_in"
                    choices={statusDescriptions[StatusType.order].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />,
                <SelectInput
                    key="payment"
                    label="Payment"
                    source="paymentStrategy"
                    choices={[
                        { id: 1, name: 'Card' },
                        { id: 2, name: 'Direct Invoicing' },
                    ]}
                />,
                <DateInput key="mainService.startfrom" label="Arrival from" source="services.periodStart_gte" />,
                <DateInput key="mainService.departfrom" label="Departure from" source="services.periodEnd_gte" />,
                <DateInput key="mainService.startto" label="Arrival to" source="services.periodStart_lte" />,
                <DateInput key="mainService.departto" label="Departure to" source="services.periodEnd_lte" />,
                <DateInput key="mainService.createdfrom" label="Created since" source="createdAt_gte" />,
                <DateInput key="mainService.createdto" label="Created until" source="createdAt_lte" />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" />
                    <StatusField source="status" sx={{ width: 75 }} />
                    <TextField source="carrier.entity" label="Carrier" />
                    <TextField source="warehouse.entity" label="Warehouse" />
                    <OrderPricingField source="pricingSheetId" label="Pricing" />
                    <LongField
                        transform={(record) =>
                            `${record.warehouse.physicalAddress.postalCode}, ${record.warehouse.physicalAddress.state}`
                        }
                        width={75}
                        source="warehouse.physicalAddress.address"
                        label="WH Address"
                        sortBy="warehouse.addresses.address"
                    />
                    <LongField source="carrierManager.email.email" label="Carrier email" width={80} />
                    <PaymentField source="paymentStrategy" label="Payment" />
                    <TextField
                        source="servicesString"
                        sortable={false}
                        label="Services"
                        sx={{ width: 120, display: 'block' }}
                    />
                    <NumberField source="mainService.amount" label="Pallets" sortBy="services.amount" />
                    <NumberField source="mainService.days" label="Days" sortBy="services.days" />
                    <DateField
                        source="mainService.periodStart"
                        label="Arrival"
                        sortBy="services.periodStart"
                        options={{ timeZone: 'UTC' }}
                    />
                    <DateField
                        source="mainService.periodEnd"
                        label="Departure"
                        sortBy="services.periodEnd"
                        options={{ timeZone: 'UTC' }}
                    />
                    <BooleanField source="publicBooking" label="Public" sx={{ width: 20, display: 'block' }} />
                    <InvoicesListField label="Invoices" />

                    <NumberField
                        source="pricing.margin"
                        label="Revenue"
                        locales="en-US"
                        sortable={false}
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <PercentField source="pricing.marginPercentage" sortable={false} label="Margin %" />
                    <NumberField
                        source="carrierTotal"
                        locales="en-US"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <NumberField
                        source="warehouseTotal"
                        locales="en-US"
                        options={{ style: 'currency', currency: 'USD' }}
                    />
                    <DateField source="createdAt" showTime />
                </Datagrid>
            </>
        </List>
    )
}
