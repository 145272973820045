import { TermsOfServiceStatus } from '@/admin/model/enums'
import { OrderStatusCode } from '@shared/types/order'
import { pricingTypes, serviceTypes } from './dictionaries'

export interface StatusData {
    title: string
    value?: number
    description?: string
    color?: string
}

export enum StatusType {
    // default statuses
    verification,
    // invoice statuses
    invoice,
    order = 2,
    entity,
    tos,
    document,
    services,
    servicesType,
    priceType,
    appRole,
}

export const statusDescriptions = {
    [StatusType.entity]: [
        { title: 'Inactive', value: 1, description: 'Inactive', color: '#ed6868' },
        { title: 'Archived', value: 3, description: 'Archived', color: '#9caec5' },
        { title: 'Active', value: 5, description: 'Active', color: '#52a775' },
        { title: 'Rejected', value: 6, description: 'Rejected', color: '#ed6868' },
        { title: 'Banned', value: 7, description: 'Banned', color: '#ed6868' },
    ],
    [StatusType.appRole]: [
        { title: 'Not set', value: 0, description: 'Not set', color: '#ed6868' },
        { title: 'RFQ Portal', value: 2, description: 'Broker', color: '#29b7f6' },
        { title: 'Load Board', value: 1, description: 'Warehouse', color: '#52a775' },
    ],
    [StatusType.verification]: [
        { title: 'Rejected', value: 1, description: 'Rejected', color: '#ed6868' },
        { title: 'Pending', value: 3, description: 'Pending / not confirmed', color: '#9caec5' },
        { title: 'Confirmed', value: 5, description: 'Confirmed', color: '#52a775' },
    ],
    [StatusType.order]: [
        {
            title: 'Draft',
            value: OrderStatusCode.DRAFT,
            description: 'Order created, but not enough information for pending status yet',
        },
        {
            title: 'Pending',
            value: OrderStatusCode.PENDING,
            description: 'Order waiting for warehouse confirmation',
            color: '#ee8300',
        },
        { title: 'Confirmed', value: OrderStatusCode.CONFIRMED, description: 'Order is confirmed', color: '#9caec5' },
        { title: 'Booked', value: OrderStatusCode.BOOKED, description: 'Order is booked', color: '#52a775' },
        { title: 'Dropped', value: OrderStatusCode.DROPPED, description: 'Order is dropped', color: '#52a775' },
        { title: 'Finished', value: OrderStatusCode.FINISHED, description: 'Order is finished', color: '#52a775' },
        { title: 'Completed', value: OrderStatusCode.COMPLETED, description: 'Order is completed', color: '#52a775' },
        { title: 'Closed', value: OrderStatusCode.CLOSED, description: 'Order is closed', color: '#9caec5' },
        {
            title: 'Canceled without fee',
            value: OrderStatusCode.CANCELED_WITHOUT_FEE,
            description: 'Order is canceled',
            color: '#ed6868',
        },
        {
            title: 'Canceled with fee',
            value: OrderStatusCode.CANCELED_WITH_FEE,
            description: 'Order is canceled',
            color: '#ed6868',
        },
        {
            title: 'Canceled by WH',
            value: OrderStatusCode.CANCELED_BY_WH,
            description: 'Order is canceled',
            color: '#ed6868',
        },
        {
            title: 'Deleted Draft',
            value: OrderStatusCode.DELETED_DRAFT,
            description: 'Draft order is deleted',
        },
        { title: 'Expired', value: OrderStatusCode.EXPIRED, description: 'Order is expired', color: '#ed6868' },
        { title: 'LTW Pending', value: OrderStatusCode.LTW_PENDING, description: 'LTW is pending', color: '#ee8300' },
        {
            title: 'LTW Canceled',
            value: OrderStatusCode.LTW_CANCELED,
            description: 'LTW is canceled',
            color: '#ed6868',
        },
    ],
    [StatusType.tos]: [
        {
            title: 'Draft',
            value: TermsOfServiceStatus.DRAFT,
            description: 'Terms of service is created, but not published yet',
            color: '#9caec5',
        },
        {
            title: 'Published',
            value: TermsOfServiceStatus.PUBLISHED,
            description: 'Terms of service is published',
            color: '#52a775',
        },
        {
            title: 'Archived',
            value: TermsOfServiceStatus.ARCHIVED,
            description: 'Terms of service is archived',
            color: '#9caec5',
        },
    ],
    [StatusType.invoice]: [
        {
            title: 'Prepared',
            value: 0,
            description: 'Invoice is prepared and ready to be sent',
            color: '#9caec5',
            progress: 25,
        },
        {
            title: 'Sent',
            value: 1,
            description: 'Invoice was created and sent to accounting',
            color: '#519cec',
            progress: 50,
        },
        { title: 'Paid', value: 2, description: 'Invoice has been paid', color: '#7cce66', progress: 100 },
        { title: 'Under Paid', value: 3, description: 'Invoice is not fully paid yet', color: '#e09c3c', progress: 75 },
        { title: 'Over Paid', value: 4, description: 'Invoice is over paid', color: '#52a775', progress: 100 },
        { title: 'Canceled', value: 5, description: 'Invoice has been canceled', color: '#ed6868', progress: 100 },
    ],
    [StatusType.document]: [
        { title: 'Unconfirmed', name: 'UNCONFIRMED', value: 1, color: '#ed6868' },
        { title: 'On verification', name: 'ON_VERIFICATION', value: 3, color: '#e09c3c' },
        { title: 'Confirmed', name: 'CONFIRMED', value: 5, color: '#7cce66' },
        { title: 'Archived', name: 'ARCHIVED', value: 7, color: '#9caec5' },
    ],
    [StatusType.services]: [
        { title: 'Warehousing', name: 'WAREHOUSING', value: 0, color: '#7cce66' },
        { title: 'Cross docking', name: 'CROSS_DOCKING', value: 1, color: '#7cce66' },
        { title: 'Loads repair', name: 'LOADS_REPAIR', value: 2, color: '#7cce66' },
        { title: 'Distribution', name: 'DISTRIBUTION', value: 4, color: '#7cce66' },
        { title: 'Transloading', name: 'TRANSLOADING', value: 5, color: '#7cce66' },
    ],
    [StatusType.servicesType]: serviceTypes.map((i) => ({
        title: i.name,
        name: i.name,
        value: i.id,
        color: '#7cce66',
    })),
    [StatusType.priceType]: pricingTypes.map((i) => ({ title: i.name, name: i.name, value: i.id, color: '#7cce66' })),
}

export const statusData = (type: StatusType, status?: number): StatusData => {
    const data = statusDescriptions[type]
    if (data?.length > 0) {
        for (const d of data) {
            if (d.value === status) return d
        }
    }
    return { title: 'Unknown', value: undefined }
}
