import React, { FC } from 'react'
import { Button, useShowContext, SimpleForm, CreateBase, TextInput, DateInput } from 'react-admin'
import { Loader } from '@/admin/components/Loader'
import { Grid } from '@mui/material'
import { OlimpRichTextInput } from '@/admin/resources/tos/rte'
import { TOS_VIEW_CLASSNAME } from '@/admin/resources/tos/tos-view'
import './tos-view.scss'

export const TOSCreate: FC = () => {
    // const { record, isLoading, isFetching } = useShowContext()
    // if (!record && (isLoading || isFetching)) {
    //     return <Loader />
    // }
    return (
        <div className={`${TOS_VIEW_CLASSNAME}__container`}>
            <div className={`${TOS_VIEW_CLASSNAME}__heading`}>
                <h3 className={`${TOS_VIEW_CLASSNAME}__title`}>Terms of service draft</h3>
            </div>
            <Grid container>
                <Grid container md={8} xs={12} spacing={2} sx={{ mr: 2 }} alignContent="flex-start">
                    <Grid item xs={12} md={12}>
                        <CreateBase>
                            <SimpleForm>
                                <TextInput source="message" />
                                <DateInput source="startDate" />
                                <OlimpRichTextInput source="content" className={`${TOS_VIEW_CLASSNAME}__editor`} />
                                <TextInput source="notes" multiline rows={5} fullWidth />
                            </SimpleForm>
                        </CreateBase>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
