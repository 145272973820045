import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import React from 'react'
import { HttpV2 } from '@shared/apiv2/api'
import { Button, CircularProgress } from '@mui/material'

export const DeactivateShadowManagerButton = (props: any) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext<any>(props)
    const [deactivateLoading, setDeactivateLoading] = React.useState<boolean>(false)

    const sendDeactivate = async (id: number) => {
        setDeactivateLoading(true)

        try {
            await HttpV2.put(`/admin/shadow-manager/${id}/deactivate`)
            await refresh()
            notify('Shadow manager deactivated')
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
        setDeactivateLoading(false)
    }

    return (
        <Button
            color={record.isActive ? 'success' : 'primary'}
            size="small"
            disabled={!record.isActive}
            onClick={() => {
                if (record.isActive) {
                    sendDeactivate(record.id)
                }
            }}
        >
            {!record.isActive ? (
                !deactivateLoading ? (
                    'Not active'
                ) : (
                    <CircularProgress size={25} thickness={2} />
                )
            ) : (
                'Deactivate'
            )}
        </Button>
    )
}
