import React, { useState, useEffect } from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, RadioButtonGroupInput, ReferenceInput, NumberInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { settingTypeList } from '@/admin/model/dictionaries'
import { Grid } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'

interface FormProps {
    defaultValue: any
    // Other props
}

const Form: React.FC<FormProps> = ({ defaultValue }) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(null)
    const [selectedType, setSelectedType] = useState<number>(0)

    const getValueField = (type: number) => {
        switch (type) {
            case 1:
                return <NumberInput key="valueInput" source="value" validate={required()} />
            case 2:
                return <TextInput key="valueInput" source="value" validate={required()} />
            case 3:
                return (
                    <RadioButtonGroupInput
                        key="valueInput"
                        validate={required()}
                        source="value"
                        choices={[
                            { id: true, name: 'True' },
                            { id: false, name: 'False' },
                        ]}
                    />
                )
        }
    }

    const selectType = async (id: number) => {
        const settingType = await HttpV2.get('admin/setting-type/' + id)
        setSelectedType(settingType.data.type)
    }

    useEffect(() => {
        if (defaultValue.settingTypeId && selectedType === 0) {
            setSelectedType(defaultValue.settingType.type)
        }
    }, [selectedType])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <ReferenceInput label="Group" source="group" reference="setting-type/groups" allowEmpty>
                    <SelectInput onChange={(event) => setSelectedValue(event.target.value)} optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={6}>
                <ReferenceInput
                    label="Name"
                    source="settingTypeId"
                    reference="setting-type"
                    allowEmpty
                    filter={{ group: selectedValue }}
                >
                    <SelectInput
                        optionText="name"
                        validate={required()}
                        onChange={(event) => {
                            selectType(event.target.value)
                        }}
                    />
                </ReferenceInput>
            </Grid>
            {selectedType ? (
                <Grid item xs={12} md={6}>
                    {getValueField(selectedType)}
                    <div>
                        Type:{' '}
                        {settingTypeList.filter((item) => selectedType === item.id).map((item: any) => item.name)[0]}
                    </div>
                </Grid>
            ) : null}
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RadioButtonGroupInput
                    validate={required()}
                    source="required"
                    choices={[
                        { id: true, name: 'True' },
                        { id: false, name: 'False' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextInput source="fieldSettings" multiline />
            </Grid>
        </Grid>
    )
}

export const showEditor = (resource: string, onSuccess: () => void, recordId?: number | string, defaultValue?: any) => {
    return showEditorModal({
        props: {
            title: recordId ? 'Edit Setting Type' : 'Create Setting Type',
            resource: resource,
            recordId,
            children: <Form defaultValue={defaultValue} />,
            defaultValue,
            onSuccess,
            model: (data) => {
                return {
                    id: data.id,
                    carrierId: data.carrierId,
                    settingTypeId: data.settingTypeId,
                    required: data.required,
                    status: data.status,
                    fieldSettings: data.fieldSettings,
                    value: data.value.toString(),
                    updatedAt: new Date(),
                }
            },
        },
    })
}
