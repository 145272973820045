import React from 'react'
import {
    Button,
    Datagrid,
    DateField,
    List,
    NumberField,
    ReferenceManyField,
    SelectInput,
    TabbedForm,
    TextField,
    useShowContext,
    WrapperField,
} from 'react-admin'
import { StatusField } from '@/admin/resources/order/order-status'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { LongField } from '@/admin/fields/common'
import { Loader } from '@/admin/components/Loader'
import { showEditor } from './company-editor'

const companyTitle = (item: any) => {
    return (
        <>
            {item.entity}{' '}
            <Button
                type="button"
                onClick={() => showEditor('companies', item.id)}
                color="primary"
                label="Edit Company"
            />
        </>
    )
}

export const CompanyView = () => {
    const { record, isLoading, isFetching } = useShowContext()
    const loading = !record && (isLoading || isFetching)

    const CarriersEmpty = () => <div>No Carriers found</div>
    const WarehousesEmpty = () => <div>No Warehouses found</div>
    const ManagersEmpty = () => <div>No Managers found</div>

    if (loading) {
        return <Loader />
    }

    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="Company">
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} md={12} lg={3}>
                        <DataCard
                            records={[
                                { title: 'Company', value: record.entity },
                                { title: 'Group Name', value: record.groupName },
                                { title: 'TIN', value: record.taxId },
                                { title: 'Site', value: record.site },
                                { title: 'Came From', value: record.cameFrom },
                                {
                                    title: 'Status',
                                    value: <StatusField source="status" statusType={StatusType.entity} />,
                                },
                                { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Changed by ID', value: record.changedBy },
                            ]}
                            title={companyTitle(record)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={9}>
                        <DataCard
                            title={'Managers'}
                            records={[]}
                            content={
                                <ReferenceManyField reference="managers" target="companyId" label={false}>
                                    <List
                                        sort={{ field: 'createdAt', order: 'DESC' }}
                                        storeKey="managersCompany"
                                        perPage={25}
                                        filter={{ companyId: record?.id }}
                                        filters={[
                                            <SelectInput
                                                key="status"
                                                label="Status"
                                                source="status"
                                                choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                                                    id: item.value,
                                                    name: item.title,
                                                }))}
                                                alwaysOn
                                            />,
                                        ]}
                                    >
                                        <Datagrid bulkActionButtons={false} rowClick="show" empty={<ManagersEmpty />}>
                                            <NumberField source="id" width={50} />
                                            <LongField source="email.email" label="Email" width={150} />
                                            <TextField source="phone.number" label="Phone" sortable={false} />
                                            <WrapperField label="Full name" sortBy="firstName">
                                                <TextField source="firstName" label="First Name" width={100} />
                                                &nbsp;
                                                <TextField source="lastName" label="Last Name" width={100} />
                                            </WrapperField>
                                            <LongField
                                                source="position"
                                                label="Position"
                                                width={100}
                                                sortable={false}
                                            />
                                            <TextField source="type" label="Type" sortable={false} />
                                            <DateField source="updatedAt" label="Changed at" showTime />
                                            <StatusField source="status" statusType={StatusType.entity} />
                                        </Datagrid>
                                    </List>
                                </ReferenceManyField>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Carriers and Warehouses">
                <Grid container alignContent="flex-start">
                    <Grid item xs={12} md={12}>
                        <DataCard
                            title={'Carriers'}
                            records={[]}
                            content={
                                <ReferenceManyField reference="carriers" target="companyId" label={false}>
                                    <Datagrid bulkActionButtons={false} empty={<CarriersEmpty />} rowClick="show">
                                        <NumberField source="id" label="Carrier ID" sortable={false} />
                                        <TextField source="entity" label="Carrier Name" />
                                        <LongField
                                            source="physicalAddress.address"
                                            label="Physical Address"
                                            sortable={false}
                                        />
                                        <TextField source="licenses.number" label="USDOT or MC/MX##" sortable={false} />
                                        <StatusField source="status" statusType={StatusType.entity} />
                                    </Datagrid>
                                </ReferenceManyField>
                            }
                        />
                        <div style={{ marginTop: '10px' }}></div>
                        <DataCard
                            title={'Warehouses'}
                            records={[]}
                            content={
                                <ReferenceManyField reference="warehouses" target="companyId" label={false}>
                                    <Datagrid bulkActionButtons={false} empty={<WarehousesEmpty />} rowClick="show">
                                        <NumberField source="id" label="ID" sortable={false} />
                                        <TextField source="entity" label="Warehouse Name" width={100} />
                                        <LongField
                                            source="physicalAddress"
                                            label="Physical Address"
                                            sortBy="physicalAddress"
                                            width={450}
                                            sortable={false}
                                        />
                                        <TextField source="licenses.number" label="USDOT or MC/MX##" sortable={false} />
                                        <StatusField
                                            source="status"
                                            statusType={StatusType.entity}
                                            label="Warehouse Status"
                                        />
                                    </Datagrid>
                                </ReferenceManyField>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
