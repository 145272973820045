import React, { useEffect, useState } from 'react'
import {
    Datagrid,
    List,
    NumberField,
    TextField,
    DateField,
    NumberInput,
    TextInput,
    FunctionField,
    useNotify,
    SelectInput,
} from 'react-admin'
import { Grid } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'
import { validateMaxValue } from '../../../parts/Validations'

export const PushNotificationLog = () => {
    const [eventTypes, setEventTypes] = useState([])
    const notify = useNotify()
    const getEventTypes = async () => {
        try {
            const res = await HttpV2.get('/admin/push-notification-system/event-types', {
                params: {
                    filter: {},
                    range: '[0, 1000]',
                },
            })
            setEventTypes(res.data)
        } catch (e: any) {
            notify(e.message, { type: 'error' })
        }
    }
    useEffect(() => {
        getEventTypes()
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    disableSyncWithLocation
                    storeKey="smsLog"
                    perPage={25}
                    filters={[
                        <NumberInput
                            key="id"
                            source="id"
                            label="ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <NumberInput
                            key="eventId"
                            source="eventId"
                            label="Event ID"
                            alwaysOn
                            validate={(value) => validateMaxValue(value, 2147483647)}
                        />,
                        <SelectInput
                            key="event.eventType"
                            label="Event Type"
                            source="event.eventType"
                            choices={eventTypes.map((item: any) => ({
                                id: item.name,
                                name: item.name,
                            }))}
                            alwaysOn
                        />,
                        <SelectInput
                            key="status"
                            label="Status"
                            source="status"
                            choices={[
                                {
                                    id: 1,
                                    name: 'Success',
                                },
                                {
                                    id: 0,
                                    name: 'Failure',
                                },
                            ]}
                            alwaysOn
                        />,
                        <TextInput
                            key="recipientId"
                            source="recipientEmail.email_ilike"
                            label="Recipient Email"
                            alwaysOn
                        />,
                        <TextInput key="pushToken" source="pushToken" label="Recipient token" alwaysOn />,
                        <TextInput key="pushId" source="pushId" label="Push id" alwaysOn />,
                    ]}
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="ID" width={50} />
                            <TextField source="eventId" label="Entity ID" />
                            <TextField source="event.eventType" sortable={false} />
                            <FunctionField
                                label="Status"
                                render={(r: any) => {
                                    return r?.status ? 'Success' : 'Failure'
                                }}
                            />
                            <FunctionField
                                label="Recipient Email"
                                render={(r: any) => {
                                    return <a href={`mailto:${r.recipientEmail.email}`}>{r.recipientEmail.email}</a>
                                }}
                            />
                            <TextField source="pushToken" label="Recipient token" />
                            <TextField source="pushId" label="Push id" />
                            <DateField source="createdAt" showTime />

                            <FunctionField
                                render={(r: any) => {
                                    return <a href={`/admin/app/push-notification-system/${r.id}/show`}>SHOW</a>
                                }}
                            />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
