import React from 'react'
import { AppBar, Layout } from 'react-admin'
import { MenuItem, ListItemText, ListItemIcon } from '@mui/material'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import { HttpV2 } from '@shared/apiv2/api'
import { useNavigate } from 'react-router-dom'
import CustomMenu from './components/Menu/customMenu'

const AppUserMenu = React.forwardRef((props: any, ref) => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem('admin_token')
        delete HttpV2.defaults.headers.common.Authorization
        navigate('/admin/login')
    }
    return (
        <MenuItem ref={ref} {...props} onClick={logout}>
            {' '}
            <ListItemIcon>
                {' '}
                <ExitIcon style={{ color: 'white' }} />{' '}
            </ListItemIcon>{' '}
            <ListItemText>Logout</ListItemText>{' '}
        </MenuItem>
    )
})
export const AppTopBar = () => {
    return <AppBar userMenu={<AppUserMenu />} />
}

export const AppLayoutRender = (props: any) => {
    return <Layout {...props} appBar={AppTopBar} menu={CustomMenu} />
}

export const AppLayout = React.memo(AppLayoutRender)
