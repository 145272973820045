import React from 'react'
import {
    Datagrid,
    List,
    NumberField,
    TextField,
    useRecordContext,
    Button,
    DeleteWithConfirmButton,
    useRefresh,
} from 'react-admin'
import { showEditor } from './editor'
import { Grid } from '@mui/material'

export const RangePriceMarkupList = () => {
    const refresh = useRefresh()
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('range-price-markup', item.id, refresh)
                }}
                label="Edit"
            />
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <List
                    perPage={25}
                    filters={[]}
                    actions={
                        <Button
                            type="button"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                showEditor('range-price-markup', undefined, refresh)
                            }}
                            label="Create"
                        />
                    }
                >
                    <>
                        <Datagrid bulkActionButtons={false} rowClick={() => false}>
                            <NumberField source="id" label="#" sortable={false} width={50} />
                            <TextField source="from" label="From, mi" />
                            <TextField source="value" />
                            <EditButton />
                            <DeleteWithConfirmButton
                                title="Delete Range Price Markup"
                                confirmContent="Are you sure?"
                                redirect={'/admin/app/range-price-markup'}
                            />
                        </Datagrid>
                    </>
                </List>
            </Grid>
        </Grid>
    )
}
