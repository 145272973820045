import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { AutocompleteInput, DateInput, minValue, ReferenceInput, required, SelectInput, TextInput } from 'react-admin'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'

export const showShadowManagerEditor = (
    managerId: number,
    resource: string,
    onSuccess: () => void,
    recordId?: number | string,
) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <ReferenceInput source="managerId" reference="managers" filter={{ id: managerId }}>
                    <SelectInput
                        optionText={(record) => `${record.firstName} ${record.lastName}`}
                        disabled
                        defaultValue={managerId}
                    />
                </ReferenceInput>
                <ReferenceInput source="warehouseId" reference="warehouses">
                    <AutocompleteInput
                        label="Search Warehouse by ID or Name"
                        validate={required()}
                        optionText={(record) => `#${record.id} - ${record.entity}`}
                        filterToQuery={(searchText) => {
                            if (searchText && !isNaN(Number(searchText))) {
                                return { id: Number(searchText) }
                            }
                            return { entity_ilike: searchText }
                        }}
                    />
                </ReferenceInput>

                <TextInput source="reason" validate={required()} minRows={3} multiline fullWidth />

                <DateInput
                    source="expiresAt"
                    defaultValue={dayjs().add(1, 'day').format('YYYY-MM-DD')}
                    validate={[required(), minValue(dayjs().add(1, 'day').format('YYYY-MM-DD'))]}
                />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Warehouse Shadow Manager',
            resource: resource,
            recordId,
            children: form,
            successMessage: 'Shadow Manager has been created and activated',
            onSuccess,
        },
    })
}
