import React, { FC, memo, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/admin/components/Button'
import './UnableDeleteAccount.scss'

interface UnableDeleteAccountModalProps {}

const UnableDeleteAccountModal: FC<UnableDeleteAccountModalProps> = () => {
    const CLASSNAME = 'UnabledeleteAccountModal'

    return (
        <div className={`${CLASSNAME}`}>
            <p className={`${CLASSNAME}__title`}>
                There are open orders on this account. You cannot archive this account until these orders are closed.
            </p>
            <div className={`${CLASSNAME}__btns-wrapper`}>
                <Button
                    className={`${CLASSNAME}__btn-okay`}
                    types={['blue']}
                    label="Okay"
                    onClick={() => ModalRegistry.get().close(Modals.UnableDeleteAccountModal)}
                />
            </div>
        </div>
    )
}

ModalRegistry.get().register<UnableDeleteAccountModalProps>(Modals.UnableDeleteAccountModal, {
    id: 'UnableDeleteAccountModalProps',
    className: 'modal-unable-delete-account',
    size: 'large',
    Component: memo(UnableDeleteAccountModal),
    title: 'Unable to delete account',
})

export const showUnableDeleteAccountModal = (props: ModalPartialProps<UnableDeleteAccountModalProps>): void =>
    ModalRegistry.get().show<UnableDeleteAccountModalProps>(Modals.UnableDeleteAccountModal, props)

export default memo(UnableDeleteAccountModal)
