import React, { FC } from 'react'
import { useRecordContext } from 'react-admin'
import { Chip, Theme } from '@mui/material'
import { SxProps } from '@mui/system'

export const PaymentField: FC<{ source: string; label?: string; sx?: SxProps<Theme> }> = ({ source, sx }) => {
    const record = useRecordContext()
    const value = record && record[source]
    return (
        <Chip
            label={value === 1 ? 'Card' : 'Direct Invoicing'}
            sx={{
                width: 75,
                height: 28,
                fontSize: 12,
                fontWeight: 600,
                color: value === 1 ? '#416283' : '#519cec',
                fontFamily: 'UniNeue',
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0, 0.1)',
                ...(sx || {}),
            }}
        />
    )
}
