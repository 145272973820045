import React from 'react'
import {
    Button,
    Datagrid,
    DateField,
    FilterLiveSearch,
    FunctionField,
    NumberField,
    ReferenceManyField,
    TabbedForm,
    TextField,
    useRefresh,
    useShowContext,
    WrapperField,
} from 'react-admin'
import { StatusField } from '@/admin/resources/order/order-status'
import { StatusType } from '@/admin/model/status'
import { DataCard } from '@/admin/components/datacard'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { BooleanStatusField, LongField } from '@/admin/fields/common'
import { Loader } from '@/admin/components/Loader'
import { showEditor } from './manager-editor'
import { showEmailEditor } from './manager-email-editor'
import { showPhoneEditor } from './manager-phone-editor'
import LinkToEntity from '@/admin/components/ui/LinkToEntity'
import { showShadowManagerEditor } from '@/admin/resources/manager/shadow-manager-editor'
import { ShadowManagerExpand } from '@/admin/resources/manager/shadow-manager-expand'
import { DeactivateShadowManagerButton } from '@/admin/resources/manager/shadow-manager-deactivate'
import { ManagerTosDeactivate } from '@/admin/resources/manager/manager-tos-deactivate'
import './manager-view.scss'

export const ManagerView = () => {
    const refresh = useRefresh()
    const { record, isLoading, isFetching } = useShowContext()

    type Tmodals = 'manager' | 'email' | 'phone'

    const modals = {
        manager: showEditor,
        email: showEmailEditor,
        phone: showPhoneEditor,
    }

    const cardTitle = (title: string, item: any, type: Tmodals) => {
        const modal = modals[type]
        return (
            <>
                {title} <Button type="button" onClick={() => modal('managers', item.id)} color="primary" label="Edit" />
            </>
        )
    }

    if (!record && (isLoading || isFetching)) {
        return <Loader />
    }
    const CarriersEmpty = () => <div>No Carriers found</div>
    const WarehousesEmpty = () => <div>No Warehouses found</div>

    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="Manager">
                <Grid container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} md={6}>
                        <DataCard
                            records={[
                                {
                                    title: 'Account',
                                    value: (
                                        <>
                                            {!record.account ? (
                                                <p>Not linked with SF</p>
                                            ) : (
                                                <>
                                                    <h4>{record.account.name}</h4>
                                                    <small>
                                                        SF ID: <b>{record.account.id}</b>
                                                    </small>
                                                </>
                                            )}
                                        </>
                                    ),
                                },
                                { title: 'SF Contact ID', value: <small>{record.sfContactId}</small> },
                                { title: 'First Name', value: record.firstName },
                                { title: 'Last Name', value: record.lastName },
                                { title: 'Position', value: record.position },
                                {
                                    title: 'Role',
                                    value: <StatusField source="role" statusType={StatusType.appRole} />,
                                },
                                {
                                    title: 'Status',
                                    value: <StatusField source="status" statusType={StatusType.entity} />,
                                },
                                { title: 'Type', value: record.type },
                                { title: 'Created At', value: dayjs(record.createdAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Updated At', value: dayjs(record.updatedAt).format('MM/DD/YYYY HH:mm') },
                                { title: 'Changed by ID', value: record.changedBy },
                            ]}
                            title={cardTitle('Manager', record, 'manager')}
                        />

                        <DataCard
                            style={{ marginTop: 12 }}
                            className="tos-datacard"
                            records={[
                                {
                                    title: (
                                        <>
                                            <ManagerTosDeactivate />
                                        </>
                                    ),
                                    value: (
                                        <Grid justifyContent="center" alignItems="center" display="flex">
                                            <BooleanStatusField source="termsAccepted" />
                                            <span style={{ marginLeft: 12 }}>
                                                {record.termsAccepted ? 'Accepted' : 'Not accepted'}
                                            </span>
                                        </Grid>
                                    ),
                                },
                                {
                                    title: 'Signing History',
                                    value: (
                                        <div className="tos-accept-history">
                                            {record.terms &&
                                                record.terms.map((t: any) => (
                                                    <span key={t.id}>
                                                        {dayjs(t.acceptedAt).format('DD.MM.YYYY HH:mm')}
                                                    </span>
                                                ))}
                                        </div>
                                    ),
                                },
                            ]}
                            title={'Terms Of Service'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataCard
                            records={[
                                { title: 'Number', value: record.phone?.number },
                                {
                                    title: 'Created At',
                                    value: dayjs(record.phone?.createdAt).format('MM/DD/YYYY HH:mm'),
                                },
                            ]}
                            title={cardTitle('Phone', record, 'phone')}
                        />
                        <div style={{ marginTop: '10px' }}></div>
                        <DataCard
                            records={[
                                { title: 'Email', value: <LongField source="email.email" fullWidth width={300} /> },
                                {
                                    title: 'Status',
                                    value: <StatusField source={'email.status'} statusType={StatusType.verification} />,
                                },
                                {
                                    title: 'Created At',
                                    value: dayjs(record.email?.createdAt).format('MM/DD/YYYY HH:mm'),
                                },
                                {
                                    title: 'Updated At',
                                    value: dayjs(record.email?.updatedAt).format('MM/DD/YYYY HH:mm'),
                                },
                                { title: 'Changed by ID', value: record.email?.changedById },
                            ]}
                            title={cardTitle('Email', record, 'email')}
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Carriers and Warehouses">
                <Grid container alignContent="flex-start">
                    <Grid item xs={12} md={12}>
                        <DataCard
                            title={'Carriers'}
                            records={[]}
                            content={
                                <ReferenceManyField
                                    reference={'carriers'}
                                    target={'managerPermissions.managerId'}
                                    label={false}
                                >
                                    <FilterLiveSearch source="entity_ilike" />
                                    <Datagrid bulkActionButtons={false} rowClick="show" empty={<CarriersEmpty />}>
                                        <NumberField source="id" label="Carrier ID" />
                                        <TextField source="entity" label="Entity" />
                                        <WrapperField label="Account" sortBy="account.name">
                                            (<TextField source="account.id" />) <TextField source="account.name" />
                                        </WrapperField>
                                        <LongField
                                            source="physicalAddress.address"
                                            label="Physical Address"
                                            sortable={false}
                                        />
                                        <TextField source="licenses.number" label="USDOT or MC/MX##" sortable={false} />
                                        <StatusField source="role" statusType={StatusType.appRole} />
                                        <StatusField source="status" statusType={StatusType.entity} />
                                        <DateField source="createdAt" showTime />
                                        <TextField source="changedBy" label="Changed by ID" sortable={false} />
                                    </Datagrid>
                                </ReferenceManyField>
                            }
                        />
                        <div style={{ marginTop: '10px' }}></div>
                        <DataCard
                            title={'Warehouses'}
                            records={[]}
                            content={
                                <ReferenceManyField
                                    reference={'warehouses'}
                                    target={'managerPermissions.managerId'}
                                    label={false}
                                >
                                    <FilterLiveSearch source="entity_ilike" />
                                    <Datagrid bulkActionButtons={false} rowClick="show" empty={<WarehousesEmpty />}>
                                        <NumberField source="id" label="Warehouse ID" />
                                        <TextField source="entity" label="Entity" width={100} />
                                        <TextField
                                            sortBy="company.entity"
                                            source="company_entity"
                                            label="Company"
                                            width={100}
                                        />
                                        <LongField
                                            source="physicalAddress"
                                            label="Address"
                                            sortBy="physicalAddress"
                                            width={450}
                                            sortable={false}
                                        />
                                        <StatusField
                                            source="status"
                                            statusType={StatusType.entity}
                                            label="Warehouse Status"
                                        />
                                        <TextField source="autoBooking" label="Auto Booking" width={100} />
                                        <DateField source="lastPriceUpdate" label="Last updated price" showTime />
                                        <DateField source="createdAt" showTime />
                                        <DateField source="updatedAt" showTime />
                                        <NumberField source="changedBy" label="Changed by" sortable={false} />
                                    </Datagrid>
                                </ReferenceManyField>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Shadow Manager">
                <Grid container alignContent="flex-start">
                    <Grid item xs={12} md={12}>
                        <div style={{ marginTop: '10px' }}></div>
                        <DataCard
                            title={
                                <>
                                    Shadow Manager{' '}
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            showShadowManagerEditor(record.id, 'shadow-manager', refresh, undefined)
                                        }
                                        color="primary"
                                        label="Add"
                                    />
                                </>
                            }
                            records={[]}
                            content={
                                <ReferenceManyField
                                    reference="shadow-manager"
                                    target="manager_id"
                                    label="Shadow Managers"
                                >
                                    <Datagrid
                                        bulkActionButtons={false}
                                        isRowExpandable={(row) => row.logs.length > 0}
                                        expand={<ShadowManagerExpand />}
                                        isRowSelectable={() => false}
                                    >
                                        <TextField source="id" label="ID" />
                                        <FunctionField
                                            label="Warehouse"
                                            sortBy="warehouse.entity"
                                            render={(rec: any) => `(#${rec.warehouse.id}) ${rec.warehouse.entity}`}
                                        />
                                        <TextField source="reason" label="Reason" />
                                        <DateField source="expiresAt" label="Expires" />
                                        <DateField source="updatedAt" label="Updated" />
                                        <DateField source="createdAt" label="Created" />
                                        <FunctionField
                                            label="Created by"
                                            sortBy="createdByAdmin.firstName"
                                            render={(rec: any) =>
                                                `(#${rec.createdByAdmin.id}) ${rec.createdByAdmin.email}`
                                            }
                                        />
                                        <DeactivateShadowManagerButton />
                                    </Datagrid>
                                </ReferenceManyField>
                            }
                        />
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
        </TabbedForm>
    )
}
