import React from 'react'
import { Datagrid, DateField, List, NumberField, SelectInput, TextField, TextInput, NumberInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'

export const CompanyList = () => {
    return (
        <List
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            filters={[
                <NumberInput key="id" label="Company ID" source="id" type="number" parse={v => parseInt(v, 10)} alwaysOn />,

                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                    alwaysOn
                />,
                <TextInput key="entity" label="Company" source="entity_ilike" alwaysOn />,
            ]}
        >
            <>
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <NumberField source="id" label="Company ID" sortable={false} />
                    <StatusField source="status" statusType={StatusType.entity} />
                    <TextField source="entity" label="Company" />
                    <TextField source="warehouses" label="Warehouses" sortable={false} />
                    <TextField source="carriers" label="Carriers" sortable={false} />
                    <DateField source="createdAt" showTime />
                    <TextField source="changedBy" label="Changed by ID" sortable={false} />
                </Datagrid>
            </>
        </List>
    )
}
