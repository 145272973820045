import React from 'react'
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    SelectInput,
    TextField,
    TextInput,
    NumberInput,
    BooleanField,
    FunctionField,
    Button,
    useRefresh,
} from 'react-admin'
import { Grid } from '@mui/material'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import { pricingTypes, serviceTypes } from '@/admin/model/dictionaries'
import { StatusField } from '@/admin/resources/order/order-status'
import { showEditor } from './warehouse-serivce-editor'
import { useNavigate } from 'react-router-dom'

export const WarehouseServiceList = () => {
    const refresh = useRefresh()
    const navigate = useNavigate()
    return (
        <Grid container>
            <Button
                type="button"
                onClick={() => showEditor('warehouse-services', undefined, refresh)}
                color="primary"
                label="Create Warehouse service"
            />
            <Button
                type="button"
                onClick={() => navigate('/admin/app/service-unity')}
                color="primary"
                label="Service Unities"
            />
            <List
                sort={{ field: 'createdAt', order: 'DESC' }}
                disableSyncWithLocation
                storeKey={'WarehousesServicesList'}
                perPage={25}
                filters={[
                    <TextInput key="title" label="Title" source="title_ilike" alwaysOn />,
                    <NumberInput key="group" label="Group" source="group" alwaysOn />,
                    <SelectInput key="type" source="type" choices={serviceTypes} alwaysOn />,
                    <SelectInput key="priceType" source="priceType" choices={pricingTypes} alwaysOn />,
                    <SelectInput
                        key="status"
                        label="Status"
                        source="status"
                        choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                            id: item.value,
                            name: item.title,
                        }))}
                        alwaysOn
                    />,
                    <SelectInput
                        key="required"
                        source="required"
                        choices={[
                            {
                                id: 'FALSE',
                                name: 'FALSE',
                            },
                            {
                                id: 'TRUE',
                                name: 'TRUE',
                            },
                        ]}
                        alwaysOn
                    />,
                    <SelectInput
                        key="termsRelated"
                        source="termsRelated"
                        label="Terms related"
                        choices={[
                            {
                                id: 'FALSE',
                                name: 'FALSE',
                            },
                            {
                                id: 'TRUE',
                                name: 'TRUE',
                            },
                        ]}
                        alwaysOn
                    />,
                    <SelectInput
                        key="isQr"
                        source="isQr"
                        label="Quotation request"
                        choices={[
                            {
                                id: 'FALSE',
                                name: 'FALSE',
                            },
                            {
                                id: 'TRUE',
                                name: 'TRUE',
                            },
                        ]}
                        alwaysOn
                    />,
                ]}
            >
                <>
                    <Datagrid bulkActionButtons={false} rowClick="show">
                        <NumberField source="id" label="ID" sortable={false} />
                        <TextField source="title" width={100} />
                        <TextField source="group" />
                        <FunctionField
                            label="Type"
                            render={(r: any) => serviceTypes.filter((item) => item.id === r.type)[0].name}
                        />
                        <FunctionField
                            label="Price Type"
                            width={100}
                            render={(r: any) => pricingTypes.filter((item) => item.id === r.priceType)[0].name}
                        />
                        <StatusField source="status" statusType={StatusType.entity} label="Status" />
                        <BooleanField source="termsRelated" label="Terms related" width={100} />
                        <TextField source="capacity" label="Capacity (Plt)" width={100} />
                        <FunctionField
                            label="Icon"
                            width={100}
                            render={(r: any) => (r.icon ? <img src={r.icon} height={24} alt="icon" /> : '(not set)')}
                        />
                        <TextField source="unity.name" label="Unity" width={100} />
                        <BooleanField source="required" width={100} />
                        <BooleanField source="isQr" label="Quotation request" width={100} />
                        <DateField source="updatedAt" showTime />
                    </Datagrid>
                </>
            </List>
        </Grid>
    )
}
