import React from 'react'
import {
    Datagrid,
    List,
    NumberField,
    TextField,
    TextInput,
    BooleanField,
    useRecordContext,
    Button,
    DeleteWithConfirmButton,
} from 'react-admin'
import { StatusType } from '@/admin/model/status'
import { StatusField } from '@/admin/resources/order/order-status'
import { showEditor } from './service-unity-editor'

export const ServiceUnityList = () => {
    const EditButton = () => {
        const item = useRecordContext()
        return (
            <Button
                type="button"
                onClick={(e: any) => {
                    e.stopPropagation()
                    showEditor('service-unity', item.id)
                }}
                label="Edit"
            />
        )
    }

    return (
        <List perPage={25} filters={[<TextInput key="name" label="Name" source="name_ilike" alwaysOn />]}>
            <>
                <Datagrid bulkActionButtons={false} rowClick={() => false}>
                    <NumberField source="id" label="#" sortable={false} width={50} />
                    <TextField source="name" label="Name" />
                    <BooleanField source="daysRelated" label="Days related" />
                    <StatusField source="code" statusType={StatusType.services} />
                    <BooleanField source="isActive" label="Is active" />
                    <EditButton />
                    <DeleteWithConfirmButton
                        title="Delete setting type"
                        confirmContent="Are you sure?"
                        redirect={'/admin/app/service-unity'}
                    />
                </Datagrid>
            </>
        </List>
    )
}
