import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { TextInput, SelectInput, required, NumberInput, BooleanInput } from 'react-admin'
import { statusDescriptions, StatusType } from '@/admin/model/status'
import consts from '@/admin/common/consts'
import { Grid } from '@mui/material'

export const showEditor = (resource: string, recordId?: number | string) => {
    const timeZonesArray = Object.entries(consts.timeZones).map(([key, value]) => ({
        id: key,
        ...value,
    }))

    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={6}>
                <TextInput source="entity" label="Entity" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="status"
                    label="Status"
                    source="status"
                    choices={statusDescriptions[StatusType.entity].map((item: any) => ({
                        id: item.value,
                        name: item.title,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    key="timezoneCode"
                    label="Timezone Code"
                    source="timezoneCode"
                    choices={timeZonesArray.map((item: any) => ({
                        id: item.id,
                        name: item.description,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput label="Discount %" source="discount" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="area" label="Area(pallets)" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="refrigeratorArea" label="Refrigerator Area(pallets)" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="areaSqFeet" label="Area(sq.feet)" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="refrigeratorAreaSqFeet" label="Refrigerator Area(sq.feet)" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="gatesCount" label="Unloading docks" />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberInput source="minOrderPrice" />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="autoBooking" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput multiline source="comment" />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: 'Edit Warehouse',
            resource: resource,
            recordId,
            children: form,
            model: (data) => {
                return {
                    entity: data.entity,
                    status: data.status,
                    timezoneCode: data.timezoneCode,
                    discount: data.discount,
                    area: data.area,
                    refrigeratorArea: data.refrigeratorArea,
                    areaSqFeet: data.areaSqFeet,
                    refrigeratorAreaSqFeet: data.refrigeratorAreaSqFeet,
                    gatesCount: data.gatesCount,
                    minOrderPrice: data.minOrderPrice,
                    autoBooking: data.autoBooking,
                    comment: data.comment,
                }
            },
        },
    })
}
