import * as React from 'react'
import { showEditorModal } from '@/admin/parts/Modals/EditorModal'
import { SelectInput, TextInput, required, PasswordInput } from 'react-admin'
import { Grid } from '@mui/material'
import { roleChoices } from './list'
export const showEditor = (resource: string, record: any, onSuccess?: () => void) => {
    const form = (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} md={12}>
                <TextInput source="email" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <PasswordInput source="password" validate={required()} />
            </Grid>
            <Grid item xs={12} md={12}>
                <SelectInput key="role" source="role" choices={roleChoices} validate={required()} />
            </Grid>
        </Grid>
    )

    return showEditorModal({
        props: {
            title: record ? 'Edit Admin' : 'Create Admin',
            resource: resource,
            recordId: record?.id,
            children: form,
            onSuccess,
            model: (data) => ({
                email: data.email,
                role: data.role,
                passwordHash: data.password,
            }),
        },
    })
}
