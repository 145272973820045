import React from 'react'
import { FC } from 'react'
import cn from 'classnames'
import './StatusDot.scss'

interface IStatusDotProps {
    type: 'success' | 'warning' | 'error' | 'info'
}

export const StatusDot: FC<IStatusDotProps> = ({ type }) => {
    return <span className={cn('admin-status-dot', `status-dot__${type}`)} />
}
