import { Button, Confirm, useNotify, useRecordContext, useRefresh } from 'react-admin'
import React, { useState } from 'react'
import { HttpV2 } from '@shared/apiv2/api'

export const TosPublishButton = (props: any) => {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()
    const record = useRecordContext(props)
    return (
        <>
            <Button
                variant="outlined"
                label="Publish"
                onClick={() => setConfirmOpen(true)}
                fullWidth
                sx={{ mr: 2, ml: 2 }}
            />

            <Confirm
                isOpen={confirmOpen}
                loading={confirmLoading}
                title="Publish new version of TOS"
                content="Are you sure to publish new version of TOS? That will deactivate all the users until they have accepted new terms of service."
                onConfirm={async () => {
                    setConfirmLoading(true)
                    try {
                        await HttpV2.put(`/admin/terms-of-service/${record.id}/publish`)
                        await refresh()
                        notify('All users will be notified about new TOS version on next login')
                    } catch (e: any) {
                        notify(e.message, { type: 'error' })
                    }
                    setConfirmLoading(false)
                    setConfirmOpen(false)
                }}
                onClose={() => {
                    setConfirmOpen(false)
                }}
            />
        </>
    )
}
